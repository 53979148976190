import { observer } from "mobx-react-lite";
import React, { useEffect } from "react"
import {Row, Col, Input, Button, Typography, Upload, Space, Checkbox, Select} from "antd";
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import recipeStore from "../../store/recipeStore";
import config from "../../config.json";
import uploadStore from "../../store/uploadStore";
import SEO from "@americanexpress/react-seo";
import LoadCart from "../LoadCart";
import VisualEditor from "../VisualEditor";
import searchStore from "../../store/searchStore";
import categoryStore from "../../store/categoryStore";
import authorStore from "../../store/authorStore";
import articleStore from '../../store/articleStore';
import {useParams} from 'react-router';

const Recipe = observer((props) => {

    const { recipeID } = useParams()
    
    useEffect(() => {
        if(recipeID && recipeID > 0){
            recipeStore.getRecipe(recipeID);
        }
        authorStore.getListAuthor();
        categoryStore.setType("recipe");
        categoryStore.getAllCategory();
    }, []);

    const propsUpload = {
        name: 'file',
        action: config.apiUrl + '/upload/upload',
        headers: {
            "Type-Device": config.typeDevice
        },
        data: {
            path: uploadStore.file.type + "/recipe/" + uploadStore.getPathDate(),
            type: uploadStore.file.type
        },
        onChange(info) {
            let photo = uploadStore.onChangeFile(info);
            if(typeof(photo) === "object") {
                recipeStore.setPhoto(photo);
            }

        },
    };

    const propsVideoUpload = {
        name: 'file',
        action: config.apiUrl + '/upload/upload',
        headers: {
            "Type-Device": config.typeDevice
        },
        data: {
            path: "video/recipe/" + uploadStore.getPathDate(),
            type: "video"
        },
        onChange(info) {
            let video = uploadStore.onChangeFile(info);
            if(typeof(video) === "object") {
                recipeStore.setVideo(video);
            }
        },
    };

    const editIngredients = (e) => {
        recipeStore.onChange("ingredients", e)
    }

    const editCooking = (e) => {
        recipeStore.onChange("cooking", e)
    }

    const element = (
        <form onSubmit={e => recipeStore.sendForm(e)}>
            <Row className="recipes administration" justify="space-around" align="middle">
                <Col span={16} className="admHeading">
                    <Typography.Title>
                        {recipeID && recipeID > 0 ? "Редактировать рецепт" : "Добавить рецепт"}
                    </Typography.Title>
                </Col>
                <Col span={8} align="right">
                    <Space>
                        <Button type="dashed" href="/recipes">К списку</Button>
                        <Button type="primary" htmlType="submit">Сохранить</Button>
                    </Space>
                </Col>
                <Col span={24}>
                    <Row gutter={[15, 30]}>
                        <Col span={8}>
                            <Checkbox
                                onChange={e => recipeStore.itemDay(e)}
                                checked={recipeStore.recipe.viewmain}
                            >
                                Рецепт дня
                            </Checkbox>
                        </Col>
                        <Col span={8}>
                            <Checkbox
                                onChange={e => recipeStore.onChange("ru", e.target.checked === true ? 1 : 0)}
                                checked={recipeStore.recipe.ru}
                            >
                                Показывать на РУ
                            </Checkbox>
                        </Col>
                        <Col span={8}>
                            <Checkbox
                                onChange={e => recipeStore.onChange("en", e.target.checked === true ? 1 : 0)}
                                checked={recipeStore.recipe.en}
                            >
                                Показывать на EN
                            </Checkbox>
                        </Col>
                        <Col xs={12}>
                            <Typography.Title level={5}>Название</Typography.Title>
                            <Input
                                onChange={e => recipeStore.onChange("name", e.target.value)}
                                value={recipeStore.recipe.name}
                                required
                            />
                        </Col>
                        <Col xs={12}>
                            <Typography.Title level={5}>Автор</Typography.Title>
                            <Select
                                name="author"
                                showSearch
                                style={{ width: 250 }}
                                placeholder="Искать"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                                onChange={
                                    e => recipeStore.onChange("author", e)
                                }
                                onSearch={e => searchStore.getAuthor(e)}
                                value={recipeStore.recipe.author}
                            >
                                {authorStore.list.map((author) => (
                                    <Select.Option value={author.id}>{author.name}</Select.Option>
                                ))}
                            </Select>
                        </Col>
                        <Col xs={24} lg={24}>
                            <Typography.Title level={5}>Категория</Typography.Title>
                            <Select
                                name="categoryid"
                                showSearch
                                style={{ width: 250 }}
                                placeholder="Искать"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                                onChange={
                                    e => recipeStore.onChange("categoryid", e)
                                }
                                onSearch={e => searchStore.getCategoryRecipe(e)}
                                value={recipeStore.recipe.categoryid}
                            >
                                {categoryStore.list.map((cat) => (
                                    <Select.Option value={cat.id}>{cat.name}</Select.Option>
                                ))}
                            </Select>
                        </Col>
                        <Col xs={24}>
                            <Typography.Title level={5}>Фото</Typography.Title>
                            {recipeStore.recipe.photo.length > 0 && recipeStore.recipe.photo.map(item =>
                                <div className="forImage">
                                    <Button
                                        className="delete"
                                        danger
                                        type="dashed"
                                        shape="circle"
                                        icon={<DeleteOutlined />}
                                        onClick={() => recipeStore.deleteImage(item.url)}
                                    />
                                    <img src={item.url} width={100 + "%"} alt=""/>
                                </div>
                            )}
                            <Upload
                                {...propsUpload}
                                listType="picture-card"
                                className="photo-uploader"
                                maxCount={1}
                            >
                                <div>
                                    <PlusOutlined />
                                    <div style={{ marginTop: 8 }}>Загрузить</div>
                                </div>
                            </Upload>
                        </Col>
                        <Col xs={24}>
                            <Typography.Title level={5}>Видео</Typography.Title>
                            {recipeStore.recipe.video !== "" &&
                            <div className="forImage">
                                <Button
                                    danger
                                    type="dashed"
                                    shape="circle"
                                    className="delete"
                                    icon={<DeleteOutlined />}
                                    onClick={() => recipeStore.deleteVideo(recipeStore.recipe.video)}
                                />
                                <video className="video" controls>
                                    <source src={recipeStore.recipe.video} />
                                </video>
                            </div>
                            }
                            <Upload
                                {...propsVideoUpload}
                                listType="picture-card"
                                className="photo-uploader"
                                maxCount={1}
                            >
                                <div>
                                    <PlusOutlined />
                                    <div style={{ marginTop: 8 }}>Загрузить</div>
                                </div>
                            </Upload>
                        </Col>
                        <Col span={24}>
                            <Typography.Title level={5}>Ингредиенты</Typography.Title>
                            <VisualEditor
                                value={recipeStore.recipe.ingredients}
                                updateData={editIngredients}
                            />
                        </Col>
                        <Col span={24}>
                            <Typography.Title level={5}>Способ приготовления</Typography.Title>
                            <VisualEditor
                                value={recipeStore.recipe.cooking}
                                updateData={editCooking}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </form>
    );

    return(
        <>
            <SEO
                title={recipeID && recipeID > 0 ? "Редактировать рецепт" : "Добавить рецепт"}
            />
            {recipeID && recipeID > 0 && recipeStore.recipe.id === 0 ? <LoadCart /> : element}
        </>
    )
});

export default Recipe;