import './App.less';
import { redirect, RouterProvider } from 'react-router-dom'
import Routes from "./Routes";
import HeaderPage from "./components/Header";
import {Row, Col} from 'antd';
import Middleware from "./store/middleware";
import PageNotFound from "./components/PageNotFound";

function App() {

    Middleware.isAdmin();

    return (
    <>
        {Middleware.admin === 1 && <HeaderPage />}
        <Row justify="space-around">
            <Col xs={24} lg={18}>
                {<RouterProvider router={Routes} /> || <PageNotFound />}
            </Col>
        </Row>

    </>
    );
}

export default App;