import { observer } from "mobx-react-lite";
import React, { useEffect } from "react"
import { Row, Col, Input, Button, Typography, Space } from "antd";
import { MailOutlined, LockOutlined, UserOutlined } from '@ant-design/icons';
import adminStore from "../../store/adminStore";
import SEO from "@americanexpress/react-seo";
import LoadCart from "../LoadCart";
import {useParams} from 'react-router';

const Admin = observer((props) => {

    const { adminID } = useParams()

    useEffect(() => {
        if(adminID && adminID > 0){
            adminStore.getAdmin(adminID);
        }
    }, []);

    const element = (
        <form onSubmit={e => adminStore.sendForm(e)}>
            <Row className="products administration" justify="space-around" align="middle">
                <Col span={16} className="admHeading">
                    <Typography.Title>
                        {adminID && adminID > 0 ? "Редактировать администратора" : "Добавить администратора"}
                    </Typography.Title>
                </Col>
                <Col span={8} align="right">
                    <Space>
                        <Button type="dashed" href="/users/administrators">К списку</Button>
                        <Button type="primary" htmlType="submit">Сохранить</Button>
                    </Space>
                </Col>
                <Col span={24}>
                    <Row>
                        <Col xs={24} sm={16} md={11} lg={8} xl={6}>
                            <Space direction="vertical" size="middle">
                                <Input
                                    onChange={e => adminStore.onChange("name", e.target.value)}
                                    value={adminStore.admin.name}
                                    placeholder="Имя"
                                    addonBefore={<UserOutlined />}
                                    required
                                />
                                <Input
                                    onChange={e => adminStore.onChange("email", e.target.value)}
                                    value={adminStore.admin.email}
                                    type="email"
                                    placeholder="E-mail"
                                    addonBefore={<MailOutlined />}
                                    required
                                />
                                <Input.Password
                                    onChange={e => adminStore.onChange("password", e.target.value)}
                                    placeholder="Пароль"
                                    addonBefore={<LockOutlined />}
                                    required={adminID && adminID > 0 ? "" : "required"}
                                />
                                <Input.Password
                                    onChange={e => adminStore.onChange("confirmPassword", e.target.value)}
                                    placeholder="Подтверждение пароля"
                                    addonBefore={<LockOutlined />}
                                    required={adminID && adminID > 0 ? "" : "required"}
                                />
                            </Space>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </form>
    );

    return(
        <>
            <SEO
                title={adminID && adminID > 0 ? "Редактировать администратора" : "Добавить администратора"}
            />
            {adminID && adminID > 0 && adminStore.admin.id === 0 ? <LoadCart /> : element}
        </>
    )
});

export default Admin;