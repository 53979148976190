import { makeAutoObservable } from "mobx"
import axios from "./axios"
import { message } from "antd"
import config from "../config.json";
import uploadStore from "./uploadStore";

class recipeStore {

    recipe = {
        id: 0,
        name: "",
        photo: [],
        ingredients: [],
        cooking: [],
        viewmain: false,
        video: "",
        author: null,
        categoryid: null,
        ru: null,
        en: null
    };

    list = [];

    count = -1;
    now = 1;
    limit = config.limit;

    constructor() {
        makeAutoObservable(this);
    }

    onChange(name, value) {
        this.recipe[name] = value;
    }

    getAllRecipe() {
        axios.get("/recipe/recipe?limit=" + this.limit + "&page=" + this.now)
            .then(success => {
                this.list = success.data.items;
                this.count = Number(success.data.pagination[1]);
                this.now = Number(success.data.pagination[0]);
            })
            .catch(error => console.log(error));
    }

    deleteRecipe(recipeID) {
        axios.delete("/recipe/recipe/" + recipeID)
            .then(success => {
                this.getAllRecipe();
            })
            .catch(error => console.log(error));
    }

    saveItem() {
        let facts = new FormData();
        facts.append("name", this.recipe.name);
        facts.append("ingredients", JSON.stringify(this.recipe.ingredients));
        facts.append("cooking", JSON.stringify(this.recipe.cooking));
        facts.append("author", this.recipe.author);
        facts.append("categoryid", this.recipe.categoryid);

        facts.append("photo", this.recipe.photo.length > 0 ? JSON.stringify(this.recipe.photo) : "");

        facts.append("viewmain", this.recipe.viewmain === false ? 0 : 1);
        facts.append("ru", this.recipe.ru);
        facts.append("en", this.recipe.en);


        if(this.recipe.video !== "") {
            facts.append("video", this.recipe.video);
        } else {
            facts.append("video", "");
        }

        let url
        if(this.recipe.id > 0) {
            url = "/recipe/recipe/" + this.recipe.id;
        } else {
            url = "/recipe/recipe"
        }
        axios.post(url, facts)
            .then(success => {
                console.log(success)
                if(this.recipe.id > 0) {
                    message.success('Успешно');
                } else {
                    document.location.href = "/recipes";
                }
            })
            .catch(error => console.log(error.response.data.message));
    }

    sendForm(e) {
        e.preventDefault();
        this.saveItem();
    }

    getRecipe(recipeID) {
        axios.get("/recipe/recipe/" + recipeID)
            .then(response => {
                console.log(response)
                this.recipe = {
                    cooking: Array.isArray(response.data.cooking) ? response.data.cooking : [],
                    cooking2: response.data.cooking2,
                    id: response.data.id,
                    ingredients: Array.isArray(response.data.ingredients) ? response.data.ingredients : [],
                    ingredients2: response.data.ingredients2,
                    name: response.data.name,
                    author: response.data.author.id > 0 ? Number(response.data.author.id) : null,
                    categoryid: Number(response.data.categoryid) > 0 ? Number(response.data.categoryid) : null,
                    photo: response.data.photo !== "" && response.data.photo !== null ? response.data.photo : [],
                    position: response.data.position,
                    viewmain: Number(response.data.viewmain) === 1 ? true : false,
                    video: response.data.video,
                    ru: response.data.ru,
                    en: response.data.en,
                };
            })
            .catch(response => console.log(response));
    }

    setPhoto(photo) {
        this.recipe.photo = [...this.recipe.photo, photo];
    }

    setNow(page) {
        this.now = page;
        this.getAllRecipe()
    }

    itemDay(e) {
        this.recipe.viewmain = e.target.checked;
    }

    deleteImage(photo) {
        uploadStore.deleteFile(photo)
        try {
            this.recipe.photo = this.recipe.photo.filter(el => el.url !== photo);
        } catch {
            message.error("Ошибка удаления файла")
        }
        this.saveItem();
    }

    deleteVideo() {
        uploadStore.deleteFile(this.recipe.video)
        this.recipe.video = "";
    }

    setVideo(video) {
        this.recipe.video = video.url;
    }
}

export default new recipeStore();