import { makeAutoObservable } from "mobx"
import axios from "./axios"
import { message } from "antd"
import config from "../config.json";
import uploadStore from "./uploadStore";

class faqStore {

    faq = {
        id: 0,
        question: "",
        text: "",
        ru: null,
        en: null
    };

    list = [];

    count = -1;
    now = 1;
    limit = config.limit;

    constructor() {
        makeAutoObservable(this);
    }

    onChange(name, value) {
        this.faq[name] = value;
    }

    getAllFaq() {
        axios.get("/article/faq?limit=" + this.limit + "&page=" + this.now)
            .then(success => {
                this.list = success.data.items;
                this.count = Number(success.data.pagination[1]);
                this.now = Number(success.data.pagination[0]);
            })
            .catch(error => console.log(error));
    }

    deleteFaq(faqID) {
        axios.delete("/article/faq/" + faqID)
            .then(success => {
                this.getAllFaq();
            })
            .catch(error => console.log(error));
    }

    sendForm(e) {
        e.preventDefault();

        let facts = new FormData();
        facts.append("question", this.faq.question);
        facts.append("text", this.faq.text);
        facts.append("ru", this.faq.ru);
        facts.append("en", this.faq.en);

        let url
        if(this.faq.id > 0) {
            url = "/article/faq/" + this.faq.id;
        } else {
            url = "/article/faq"
        }
        axios.post(url, facts)
            .then(success => {
                console.log(success)
                if(this.faq.id > 0) {
                    message.success('Успешно');
                } else {
                    document.location.href = "/faq";
                }
            })
            .catch(error => console.log(error.response.data.message));
    }

    getFaq(faqID) {
        axios.get("/article/faq/" + faqID)
            .then(response => {
                this.faq = {
                    id: response.data.id,
                    question: response.data.question,
                    text: response.data.text,
                    ru: response.data.ru,
                    en: response.data.en,
                };
            })
            .catch(response => console.log(response));
    }

    setNow(page) {
        this.now = page;
        this.getAllFaq()
    }
}

export default new faqStore();