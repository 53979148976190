import { Editor } from "primereact/editor";

const TestEditor = () => {
    return(
        <>
            <br/>
            <Editor style={{ height: 500 }} />
        </>
    )
}

export default TestEditor