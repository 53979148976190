import { observer } from "mobx-react-lite";
import adminStore from "../../store/adminStore";
import { useEffect } from "react"
import { Table, Space, Col, Row, Typography, Button, Tooltip, Popconfirm, Pagination } from "antd";
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import SEO from "@americanexpress/react-seo";
import LoadList from "../LoadList";

const AdminList = observer(() => {

    useEffect(() => {
        adminStore.getListAdmin();
    }, [])

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            width: '40px'
        },
        {
            title: 'Логин',
            dataIndex: 'login',
        },
        {
            title: 'Имя',
            dataIndex: 'firstname',
        },
        {
            title: 'Действия',
            dataIndex: 'tableActions',
            width: '80px',
            render: (text, row) => (
                <>
                    <Space>
                        <Tooltip placement="bottom" title="Редактировать">
                            <Button href={'/users/administrators/'+row.id} type="dashed" shape="circle" icon={<EditOutlined />} />
                        </Tooltip>
                        <Tooltip placement="bottom" title="Удалить">
                            <Popconfirm
                                placement="left"
                                title="Вы уверены?"
                                onConfirm={() => adminStore.deleteAdmin(row.id)}
                                okText="Да"
                                cancelText="Нет"
                                icon={<ExclamationCircleOutlined style={{ color: 'red' }} />}
                            >
                                <Button danger shape="circle" type="dashed" icon={<DeleteOutlined />} />
                            </Popconfirm>
                        </Tooltip>
                    </Space>
                </>
            )
        }
    ];

    const list = (
        <Row className="products administration" justify="space-around" align="middle">
            <Col span={20} className="admHeading">
                <Typography.Title>Администраторы</Typography.Title>
            </Col>
            <Col span={4} align="right">
                <Button type="primary" href={'/users/administrators/0'}>Добавить</Button>
            </Col>
            <Col span={24}>
                <Table columns={columns} dataSource={adminStore.list} size="small" pagination={false}/>
                {adminStore.count > 0 &&
                <>
                    <br/>
                    <Pagination
                        onChange={e => adminStore.setNow(e)}
                        current={adminStore.now}
                        total={adminStore.count}
                        defaultPageSize={adminStore.limit}
                        showSizeChanger={false}
                    />
                </>
                }
            </Col>
        </Row>
    );

    return(
        <>
            <SEO
                title="Администраторы"
            />
            {adminStore.count > -1 ? list : <LoadList />}
        </>
    )
});

export default AdminList;