import { makeAutoObservable } from "mobx"
import axios from "./axios"
import { message } from "antd"
import config from "../config.json";
import uploadStore from "./uploadStore";

class CatsStore {

    categoryType = "";

    category = {
        id: 0,
        name: "",
        photo: {},
        viewvariant: null,
        ru: null,
        en: null
    };

    list = [];

    count = -1;
    now = 1;
    limit = config.limit;

    constructor() {
        makeAutoObservable(this);
    }

    onChange(name, value) {
        this.category[name] = value;
    }

    getAllCategory() {
        let url;
        url = "/" + this.categoryType + "/category/?limit=" + this.limit + "&page=" + this.now;
        axios.get(url)
            .then(success => {
                console.log(success);
                this.list = success.data.items;
                this.count = Number(success.data.pagination[1]);
                this.now = Number(success.data.pagination[0]);
            })
            .catch(error => console.log(error));
    }

    sendForm(e) {
        e.preventDefault();

        let facts = new FormData();
        facts.append("name", this.category.name);

        facts.append("photo", this.category.photo.url ? JSON.stringify(this.category.photo) : "");
        facts.append("ru", this.category.ru);
        facts.append("en", this.category.en);
        facts.append("viewvariant", this.category.viewvariant);

        let url
        if(this.category.id > 0) {
            url = "/" + this.categoryType + "/category/" + this.category.id;
        } else {
            url = "/" + this.categoryType + "/category/";
        }

        axios.post(url, facts)
            .then(success => {
                if(this.category.id > 0) {
                    message.success('Успешно');
                } else {
                    document.location.href = "/category/" + this.categoryType;
                }
            })
            .catch(error => console.log(error.response));
    }

    deleteCategory(catID) {
        let url;
        url = "/" + this.categoryType + "/category/";
        axios.delete(url + catID)
            .then(success => {
                this.list = this.list.filter(el => el.id !== catID);
            })
            .catch(error => console.log(error));
    }

    getCategory(catID) {
        let url;
        url = "/" + this.categoryType + "/category/";
        axios.get(url + catID)
            .then(response => {
                console.log(response)
                this.category = {
                    ...this.category,
                    id: response.data.id,
                    name: response.data.name,
                    photo: response.data.photo !== "" && response.data.photo !== null ? response.data.photo : {},
                    viewvariant: response.data.viewvariant,
                    ru: response.data.ru,
                    en: response.data.en,
                }
            })
            .catch(text => message.error(text.response));
    }

    setPhoto(photo) {
        this.category.photo = photo;
    }

    setNow(page) {
        this.now = page;
        this.getAllCategory()
    }

    setType(type) {
        this.categoryType = type;
    }

    deleteImage() {
        uploadStore.deleteFile(this.category.photo.url)
        this.category.photo = {};
    }
}

export default new CatsStore();