import { observer } from "mobx-react-lite";
import React, { useEffect } from "react"
import {Row, Col, Input, Button, Typography, Upload, Space, Checkbox} from "antd";
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import authorStore from "../../store/authorStore";
import uploadStore from "../../store/uploadStore";
import config from "../../config.json";
import SEO from "@americanexpress/react-seo";
import LoadCart from "../LoadCart";
import VisualEditor from "../VisualEditor";
import articleStore from '../../store/articleStore';
import {useParams} from 'react-router';

const Author = observer((props) => {

    const { authorID } = useParams()
    
    useEffect(() => {
        if(authorID && authorID > 0){
            authorStore.getAuthor(authorID);
        }
    }, []);

    const propsUpload = {
        name: 'file',
        action: config.apiUrl + '/upload/upload',
        headers: {
            "Type-Device": config.typeDevice
        },
        data: {
            path: uploadStore.file.type + "/" + uploadStore.getPathDate(),
            type: uploadStore.file.type
        },
        onChange(info) {
            let photo = uploadStore.onChangeFile(info);
            if(typeof(photo) === "object") {
                authorStore.setPhoto(photo);
            }
        },
    };

    const editDescription = (e) => {
        authorStore.onChange("description", e)
    }

    const editDescriptionTarget = (e) => {
        authorStore.onChange("descriptiontarget", e)
    }

    const element = (
        <form onSubmit={e => authorStore.sendForm(e)}>
            <Row className="author administration" justify="space-around" align="middle">
                <Col span={16} className="admHeading">
                    <Typography.Title>
                        {authorID && authorID > 0 ? "Редактировать автора" : "Добавить автора"}
                    </Typography.Title>
                </Col>
                <Col span={8} align="right">
                    <Space>
                        <Button type="dashed" href="/authors">К списку</Button>
                        <Button type="primary" htmlType="submit">Сохранить</Button>
                    </Space>
                </Col>
                <Col span={24}>
                    <Row gutter={[15, 30]}>
                        <Col xs={24} lg={5}>
                            <Typography.Title level={5}>Имя</Typography.Title>
                            <Input
                                onChange={e => authorStore.onChange("name", e.target.value)}
                                value={authorStore.author.name}
                                required
                            />
                        </Col>
                        <Col span={6}>
                            <Checkbox
                                onChange={e => authorStore.onChange("ru", e.target.checked === true ? 1 : 0)}
                                checked={authorStore.author.ru}
                            >
                                Показывать на РУ
                            </Checkbox>
                        </Col>
                        <Col span={6}>
                            <Checkbox
                                onChange={e => authorStore.onChange("en", e.target.checked === true ? 1 : 0)}
                                checked={authorStore.author.en}
                            >
                                Показывать на EN
                            </Checkbox>
                        </Col>
                        <Col xs={24} lg={19} />
                        <Col xs={24} lg={12}>
                            <Typography.Title level={5}>Фотография</Typography.Title>
                            {authorStore.author.photo.url &&
                            <div className="forImage">
                                <Button
                                    className="delete"
                                    danger
                                    type="dashed"
                                    shape="circle"
                                    icon={<DeleteOutlined />}
                                    onClick={() => authorStore.deleteImage()}
                                />
                                <img src={authorStore.author.photo.url} width={100 + "%"} alt=""/>
                            </div>
                            }
                            <Upload
                                {...propsUpload}
                                listType="picture-card"
                                className="photo-uploader"
                                maxCount={1}
                            >
                                <div>
                                    <PlusOutlined />
                                    <div style={{ marginTop: 8 }}>Загрузить</div>
                                </div>
                            </Upload>
                        </Col>
                        <Col xs={24} lg={12} className="inputsList">
                            <Typography.Title level={5}>Список</Typography.Title>
                            {authorStore.author.list.map(item =>
                                <div className="box">
                                    <div className="box-input">
                                        <Input
                                            name="listItem"
                                            value={authorStore.activeList === item ? authorStore.listItemNow : item}
                                            onFocus={() => authorStore.setActive(item)}
                                            onBlur={() => authorStore.saveForEditList()}
                                            onChange={e => authorStore.onEditList(e.target.value)}
                                            required
                                        />
                                        <Button
                                            onClick={() => authorStore.onDeleteList(item)}
                                            type="primary"
                                            danger
                                        >
                                            Удалить
                                        </Button>
                                    </div>
                                </div>
                            )}
                            <div className="box">
                                <div className="box-input">
                                    <Input
                                        name="listItem"
                                        value={authorStore.listItemNow}
                                        onChange={e => authorStore.onEditList(e.target.value)}
                                    />
                                    <Button
                                        onClick={() => authorStore.onAddList(authorStore.listItemNow)}
                                        type="primary"
                                    >
                                        Добавить
                                    </Button>
                                </div>
                            </div>
                        </Col>
                        <Col xs={24} lg={14} />
                        <Col span={24}>
                            <Typography.Title level={5}>Описание</Typography.Title>
                            <VisualEditor
                                value={authorStore.author.description}
                                updateData={editDescription}
                            />
                        </Col>
                        <Col span={24}>
                            <Typography.Title level={5}>Цель</Typography.Title>
                            <VisualEditor
                                value={authorStore.author.descriptiontarget}
                                updateData={editDescriptionTarget}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </form>
    );

    return(
        <>
            <SEO
                title={authorID && authorID > 0 ? "Редактировать автора" : "Добавить автора"}
            />
            {authorID && authorID > 0 && authorStore.author.id === 0 ? <LoadCart /> : element}
        </>
    )
});

export default Author;