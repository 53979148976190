import { observer } from "mobx-react-lite";
import React, { useEffect } from "react"
import {Row, Col, Input, Button, Typography, Upload, Space, Checkbox} from "antd";
import { DeleteOutlined, PauseCircleOutlined, PlayCircleOutlined, PlusOutlined } from '@ant-design/icons';
import musicStore from "../../store/musicStore";
import config from "../../config.json";
import uploadStore from "../../store/uploadStore";
import SEO from "@americanexpress/react-seo";
import LoadCart from "../LoadCart";
import articleStore from '../../store/articleStore';
import {useParams} from 'react-router';

const Music = observer((props) => {

    const { musicID } = useParams()
    
    useEffect(() => {
        if(musicID && musicID > 0){
            musicStore.getMusic(musicID);
        }
    }, []);

    const propsUpload = {
        name: 'file',
        action: config.apiUrl + '/upload/upload',
        headers: {
            "Type-Device": config.typeDevice
        },
        data: {
            path: uploadStore.file.type + "/music/" + uploadStore.getPathDate(),
            type: uploadStore.file.type
        },
        onChange(info) {
            let photo = uploadStore.onChangeFile(info);
            if(typeof(photo) === "object") {
                musicStore.setPhoto(photo);
            }

        },
    };

    const propsUploadPhoto = {
        name: 'file',
        action: config.apiUrl + '/upload/upload',
        headers: {
            "Type-Device": config.typeDevice
        },
        data: {
            path: uploadStore.file.type + "/music/" + uploadStore.getPathDate(),
            type: uploadStore.file.type
        },
        onChange(info) {
            let photo = uploadStore.onChangeFile(info);
            if(typeof(photo) === "object") {
                musicStore.setPhoto2(photo);
            }

        },
    };

    const propsUploadPhoto3 = {
        name: 'file',
        action: config.apiUrl + '/upload/upload',
        headers: {
            "Type-Device": config.typeDevice
        },
        data: {
            path: uploadStore.file.type + "/music/" + uploadStore.getPathDate(),
            type: uploadStore.file.type
        },
        onChange(info) {
            console.log(info)
            let photo = uploadStore.onChangeFile(info);
            if(typeof(photo) === "object") {
                musicStore.setPhoto3(photo);
            }

        },
    };

    const propsAudioUpload = {
        name: 'file',
        action: config.apiUrl + '/upload/upload',
        headers: {
            "Type-Device": config.typeDevice
        },
        data: {
            path: "music/practice/music/" + uploadStore.getPathDate(),
            type: "music"
        },
        onChange(info) {
            console.log(info);
            let audio = uploadStore.onChangeFile(info);
            if(typeof(audio) === "object") {
                console.log(audio)
                musicStore.setAudio(audio);
            }
        },
    };

    const element = (
        <form onSubmit={e => musicStore.sendForm(e)}>
            <Row className="musics administration" justify="space-around" align="middle">
                <Col span={16} className="admHeading">
                    <Typography.Title>
                        {musicID && musicID > 0 ? "Редактировать трек" : "Добавить трек"}
                    </Typography.Title>
                </Col>
                <Col span={8} align="right">
                    <Space>
                        <Button type="dashed" href="/music">К списку</Button>
                        <Button htmlType="submit" type="primary">Сохранить</Button>
                    </Space>
                </Col>
                <Col span={24}>
                    <Row gutter={[15, 30]}>
                        <Col span={12}>
                            <Checkbox
                                onChange={e => musicStore.onChange("ru", e.target.checked === true ? 1 : 0)}
                                checked={musicStore.music.ru}
                            >
                                Показывать на РУ
                            </Checkbox>
                        </Col>
                        <Col span={12}>
                            <Checkbox
                                onChange={e => musicStore.onChange("en", e.target.checked === true ? 1 : 0)}
                                checked={musicStore.music.en}
                            >
                                Показывать на EN
                            </Checkbox>
                        </Col>
                        <Col xs={24}>
                            <Typography.Title level={5}>Название</Typography.Title>
                            <Input
                                onChange={e => musicStore.onChange("name", e.target.value)}
                                value={musicStore.music.name}
                                required
                            />
                        </Col>
                        <Col xs={24}>
                            <Typography.Title level={5}>Обложка для списка</Typography.Title>
                            {musicStore.music.photo !== null && musicStore.music.photo.url &&
                                <div className="forImage">
                                    <Button
                                        className="delete"
                                        danger
                                        type="dashed"
                                        shape="circle"
                                        icon={<DeleteOutlined />}
                                        onClick={() => musicStore.deleteImage()}
                                    />
                                    <img src={musicStore.music.photo.url} width={100 + "%"} alt=""/>
                                </div>
                            }
                            <Upload
                                {...propsUpload}
                                listType="picture-card"
                                className="photo-uploader"
                                maxCount={1}
                            >
                                <div>
                                    <PlusOutlined />
                                    <div style={{ marginTop: 8 }}>Загрузить</div>
                                </div>
                            </Upload>
                        </Col>
                        <Col xs={24}>
                            <Typography.Title level={5}>Обложка для плеера</Typography.Title>
                            {musicStore.music.photo2 !== null && musicStore.music.photo2.url &&
                            <div className="forImage">
                                <Button
                                    className="delete"
                                    danger
                                    type="dashed"
                                    shape="circle"
                                    icon={<DeleteOutlined />}
                                    onClick={() => musicStore.deleteImage2()}
                                />
                                <img src={musicStore.music.photo2.url} width={100 + "%"} alt=""/>
                            </div>
                            }
                            <Upload
                                {...propsUploadPhoto}
                                listType="picture-card"
                                className="photo-uploader"
                                maxCount={1}
                            >
                                <div>
                                    <PlusOutlined />
                                    <div style={{ marginTop: 8 }}>Загрузить</div>
                                </div>
                            </Upload>
                        </Col>
                        <Col xs={24}>
                            <Typography.Title level={5}>Аффирмация</Typography.Title>
                            {musicStore.music.photo3 !== null && musicStore.music.photo3.url &&
                            <div className="forImage">
                                <Button
                                    className="delete"
                                    danger
                                    type="dashed"
                                    shape="circle"
                                    icon={<DeleteOutlined />}
                                    onClick={() => musicStore.deleteImage3()}
                                />
                                <img src={musicStore.music.photo3.url} width={100 + "%"} alt=""/>
                            </div>
                            }
                            <Upload
                                {...propsUploadPhoto3}
                                listType="picture-card"
                                className="photo-uploader"
                                maxCount={1}
                            >
                                <div>
                                    <PlusOutlined />
                                    <div style={{ marginTop: 8 }}>Загрузить</div>
                                </div>
                            </Upload>
                        </Col>
                        <Col xs={24}>
                            <Typography.Title level={5}>Трек</Typography.Title>
                            {musicStore.music.url !== "" &&
                                <Space>
                                    <Button
                                        shape="circle"
                                        onClick={() => musicStore.playMusic(musicStore.music.url)}
                                        type="dashed"
                                        icon={musicStore.status ? <PauseCircleOutlined /> : <PlayCircleOutlined />}
                                    />
                                    <Button
                                        danger
                                        type="dashed"
                                        shape="circle"
                                        icon={<DeleteOutlined />}
                                        onClick={() => musicStore.deleteMusic(musicStore.music.url)}
                                    />
                                </Space>
                            }
                            <Upload
                                {...propsAudioUpload}
                                listType="picture-card"
                                className="photo-uploader"
                                maxCount={1}
                            >
                                <div>
                                    <PlusOutlined />
                                    <div style={{ marginTop: 8 }}>Загрузить</div>
                                </div>
                            </Upload>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </form>
    );

    return(
        <>

            <SEO
                title={musicID && musicID > 0 ? "Редактировать трек" : "Добавить трек"}
            />
            {musicID && musicID > 0 && musicStore.music.id === 0 ? <LoadCart /> : element}
        </>
    )
});

export default Music;