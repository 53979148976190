import {Row, Col, Button, Space, Select, Typography, InputNumber, Modal, Upload, Tooltip, Input, Dropdown, Menu} from "antd"
import ContentEditable from 'react-contenteditable'
import { BoldOutlined, ItalicOutlined, UnderlineOutlined, AlignLeftOutlined, AlignCenterOutlined, AlignRightOutlined, UploadOutlined, DeleteOutlined, OrderedListOutlined, UnorderedListOutlined, VerticalAlignTopOutlined, VerticalAlignBottomOutlined, FontSizeOutlined, FileImageOutlined, LinkOutlined, MenuOutlined } from '@ant-design/icons';
import React, { useState, useEffect } from "react";
import config from "../config.json";
import uploadStore from "../store/uploadStore";
import sanitizeHtml from "sanitize-html";

const VisualEditor = (props) => {

    const [active, setActive] = useState({
        id: -1,
        type: "",
        value: ""
    });

    const [hiddenBlockContent, setHiddenBlockContent] = useState(0);

    const [classText, setClassText] = useState("new-block");

    const [hidden, setHidden] = useState(false);
    const [hiddenTitle, setHiddenTitle] = useState({
        id: 0,
        text: ""
    });

    const [panel, setPanel] = useState({
        display: false,
        x: null,
        y: null
    });

    const [list, setList] = useState([]);

    const [link, setLink] = useState({
        id: 0,
        type: "link",
        typeLink: "inner",
        name: "",
        value: "",
        typeElement: "article",
        idElement: null
    });

    const [image, setImage] = useState({
        id: 0,
        type: "image",
        value: {}
    });

    const [modalLink, setModalLink] = useState(false)
    const [modalImage, setModalImage] = useState(false)



    useEffect(() => {
        setList(props.value);
    }, [props])

    const uploader = {
        name: 'file',
        action: config.apiUrl + '/upload/upload',
        headers: {
            "Type-Device": config.typeDevice
        },
        data: {
            path: uploadStore.file.type + "/editor/" + uploadStore.getPathDate(),
            type: uploadStore.file.type
        },
        onChange(info) {
            // console.log(info)
            let photo = uploadStore.onChangeFile(info);
            console.log(photo)
            if(typeof(photo) === "object") {
                saveImage(photo)
                setImage({ ...image, value: photo })
            }

        },
    };


    const menu = (
        <Menu>
            <Menu.Item><button onClick={() => editText("fontSize", 5)}>Заголовок</button></Menu.Item>
            <Menu.Item><button onClick={() => editText("fontSize", 4)}>Подзаголовок</button></Menu.Item>
            <Menu.Item><button onClick={() => editText("fontSize", 2)}>Текст</button></Menu.Item>
        </Menu>
    );

    const sanitizeConf = {
        allowedTags: ["address", "article", "aside", "footer", "header", "h1", "h2", "h3", "h4",
            "h5", "h6", "hgroup", "main", "nav", "section", "blockquote", "dd",
            "dl", "dt", "figcaption", "figure", "hr", "li", "main", "ol", "p", "pre",
            "ul", "abbr", "b", "bdi", "bdo", "br", "cite", "code", "data", "dfn",
            "em", "i", "kbd", "mark", "q", "rb", "rp", "rt", "rtc", "ruby", "s", "samp",
            "small", "span", "strong", "sub", "sup", "time", "u", "var", "wbr", "caption",
            "col", "colgroup", "table", "tbody", "td", "tfoot", "th", "thead", "tr"],
        selfClosing: [ 'img', 'br', 'hr', 'area', 'base', 'basefont', 'input', 'link', 'meta' ]
    };

    const elements =
        <Space>
            <Dropdown overlay={menu} placement="bottomLeft">
                <Button size="small">Размер заголовка</Button>
            </Dropdown>

            <Button icon={<BoldOutlined />} size="small" onClick={() => editText("bold")} />
            <Button icon={<ItalicOutlined />} size="small" onClick={() => editText("italic")} />
            <Button icon={<UnderlineOutlined />} size="small" onClick={() => editText("underline")} />

            <Button icon={<AlignLeftOutlined />} size="small" onClick={() => editText("justifyLeft")} />
            <Button icon={<AlignCenterOutlined />} size="small" onClick={() => editText("justifyCenter")} />
            <Button icon={<AlignRightOutlined />} size="small" onClick={() => editText("justifyRight")} />
            <Button icon={<MenuOutlined />} size="small" onClick={() => editText("justifyFull")} />

            <Button icon={<OrderedListOutlined />} size="small" onClick={() => editText("insertOrderedList")} />
            <Button icon={<UnorderedListOutlined />} size="small" onClick={() => editText("insertUnorderedList")} />
        </Space>

    const editText = (type, element="") => {
        onBlur();
        if(element > 0) {
            document.execCommand(type, true, element);
        } else {
            document.execCommand(type);
        }

    }

    const onChangeTextByID = (e, id) => {
        let itemValue = list.findIndex(el => el.id === id);
        let text = sanitizeHtml(e, sanitizeConf);
        console.log(text)
        list[itemValue].value = text;
    }

    const setValueLink = (name, value, id=0) => {
        console.log(name);
        console.log(value);
        if(id > 0) {
            let itemValue = list.findIndex(el => el.id === id);
            list[itemValue][name] = value;
        } else {
            console.log("Редактирование активного")
            active[name] = value;
            // setActive({ ...active, [name]: value });
        }
    }

    const onChangeText = (e) => {
        setActive({ ...active, value: e });
    }

    const addTextIn = (id) => {
        let itemValue = list.findIndex(el => el.id === id);
        list.splice(itemValue + 1, 0, { id: id + 0.1, type: "text", value: "123123123" })
        try {
            console.log("try");
            setList(list);
            props.updateData(list)
        } catch {

        }

    }

    const setNewActive = (type, id=0) => {
        if(active.id > -1) {
            saveItem();
        }


        if(list.length > 0) {
            let i = 1;
            let array = []

            if(id > 0) {
                let itemValue = list.findIndex(el => el.id === id);
                list.splice(itemValue + 1, 0, { id: id + 0.1, type: "text", value: "" })

                list.map(item => {
                    item.id = i;
                    array = [...array, item];
                    i = i + 1;
                });
            } else {
                list.map(item => {
                    item.id = i;
                    array = [...array, item];
                    i = i + 1;
                });

                let obj = {
                    id: i,
                    type: type,
                    value: ""
                }

                array = [...array, obj]
            }

            setList(array);
            props.updateData(array)
        } else {
            setList([{
                id: 1,
                type: type,
                value: ""
            }]);
            props.updateData([{
                id: 1,
                type: type,
                value: ""
            }])
        }
    }

    const saveItem = () => {
        console.log(active);
        if(list.length > 0) {
            let i = 1;
            let array = []
            list.map(item => {
                item.id = i;
                array = [...array, item];
                i = i + 1;
            });
            setActive({ ...active, id: i });
            array = [...array, active]
            setList(array);
            props.updateData(array)
        } else {
            setActive({ ...active, id: 1 });
            setList([active]);
            props.updateData([active])
        }
        clearActive();
    }

    const clearActive = () => {
        setActive({
            id: -1,
            type: "",
            value: ""
        });
    }

    const openLink = (id=0) => {
        console.log("linkid " + id)
        if(id > 0) {
            let item = list.find(el => el.id === id);
            setLink(item);
        }
        setModalLink(true)
    }

    const openImage = () => {
        setModalImage(true)
    }

    const clearLink = () => {
        setLink({
            id: 0,
            type: "link",
            typeLink: "inner",
            name: "",
            value: "",
            typeElement: "article",
            idElement: null
        });
        setModalLink(false)
    }

    const startHidden = () => {
        if(hiddenTitle.id > 0) {
            let i = 1;
            let array = []
            let itemValue = list.findIndex(el => el.id === hiddenTitle.id);
            list.splice(itemValue + 1, 0, { id: hiddenTitle.id + 0.1, type: "hidden", value: hiddenTitle.text })

            list.map(item => {
                item.id = i;
                array = [...array, item];
                i = i + 1;
            });
        } else {
            if(list.length > 0) {
                let i = 1;
                let array = []
                list.map(item => {
                    item.id = i;
                    array = [...array, item];
                    i = i + 1;
                });
                hiddenTitle.id = i;
                array = [...array, {
                    id: i,
                    type: "hidden",
                    value: hiddenTitle.text
                }]
                setList(array);
                props.updateData(array)
            } else {
                setList([{
                    id: 1,
                    type: "hidden",
                    value: hiddenTitle.text
                }])
            }
        }
        closeModal("hidden")
    }

    const saveLink = () => {
        if(link.id > 0) {
            let itemValue = list.findIndex(el => el.id === link.id);
            list[itemValue] = link;
        } else {
            if(list.length > 0) {
                let i = 1;
                let array = []
                list.map(item => {
                    item.id = i;
                    array = [...array, item];
                    i = i + 1;
                });
                link.id = i;
                array = [...array, link]
                setList(array);
                props.updateData(array)
            } else {
                link.id = 1;
                setList([link]);
                props.updateData([link])
            }
        }
        clearLink();
    }

    const clearImage = () => {
        setImage({
            id: 0,
            type: "image",
            value: {}
        })
        setModalImage(false)
    }

    const editPosition = (id, type) => {
        let elementIndex = list.findIndex(el => el.id === id);
        let element = list.find(el => el.id === id);
        let error = 0;
        let oldElement;
        if(type === "top") {
            if(list[elementIndex - 1]) {
                oldElement = list[elementIndex - 1];
                list[elementIndex - 1] = element;
            } else {
                error = error + 1;
            }
        } else if(type === "bottom") {
            if(list[elementIndex + 1]) {
                oldElement = list[elementIndex + 1];
                list[elementIndex + 1] = element;
            } else {
                error = error + 1;
            }
        }

        if(error <= 0) {
            list[elementIndex] = oldElement;

            let array = [];
            let i = 1;
            list.map(item => {
                item.id = i;
                array = [...array, item];
                i = i + 1;
            });

            setList(array);
            props.updateData(array)
        }

    }

    const addImageIn = (id) => {
        setImage({ ...image, id: id })
        setModalImage(true)
    };

    const addLinkIn = (id) => {
        let elementIndex = list.findIndex(el => el.id === id);
        list.splice(elementIndex + 1, 0, { id: id + 0.1, type: "link", value: "" })
        let i = 1;
        let array = []
        list.map(item => {
            item.id = i;
            array = [...array, item];
            i = i + 1;
        });
        setList(array);
        props.updateData(array)
        // setModalLink(true)
    };

    const saveImage = (object) => {

        if(image.id > 0) {
            let elementIndex = list.findIndex(el => el.id === image.id);
            list.splice(elementIndex + 1, 0, { id: image.id + 0.1, type: "image", value: object })
            let i = 1;
            let array = []
            list.map(item => {
                item.id = i;
                array = [...array, item];
                i = i + 1;
            });
            setList(array);
            props.updateData(array)
        } else {
            if(list.length > 0) {
                let i = 1;
                let array = []
                list.map(item => {
                    item.id = i;
                    array = [...array, item];
                    i = i + 1;
                });

                array = [...array, { id: i, type: "image", value: object }]
                setList(array);
                props.updateData(array)
            } else {
                setList([{ id: 1, type: "image", value: object }]);
                props.updateData([{ id: 1, type: "image", value: object }])
            }
        }
        clearImage();
    }

    const deleteBlock = (id) => {
        let element = list.find(el => el.id === id);

        if(element.type === "image") {
            uploadStore.deleteFile(element.value.url)
        }
        setList(list.filter(el => el.id !== id));
        // console.log(list.filter(el => el.id !== id))
        props.updateData(list.filter(el => el.id !== id));
    }

    const closeModal = (type) => {
        if(type === "image") {
            clearImage()
            setModalImage(false)
        } else if(type === "hidden") {
            setHiddenTitle({
                id: 0,
                text: ""
            })
            setHidden(false)
        } else {
            clearLink()
            setModalLink(false)
        }
    }

    const hiddenBlock = (id=0) => {
        if(id > 0) {
            console.log("id hidden = " + id)
            setHiddenTitle({ ...hiddenTitle, id: id })
        }
        setHidden(true)
    }

    const endHiddenBloсk = (id=0) => {
        if(id > 0) {
            let elementIndex = list.findIndex(el => el.id === id);
            list.splice(elementIndex + 1, 0, { id: id + 0.1, type: "endHidden" })
            let i = 1;
            let array = []
            list.map(item => {
                item.id = i;
                array = [...array, item];
                i = i + 1;
            });
            setList(array);
            props.updateData(array)
        } else {
            if(list.length > 0) {
                let i = 1;
                let array = []
                list.map(item => {
                    item.id = i;
                    // array = [...array, item];
                    array.push(item)
                    i = i + 1;
                });

                array = [...array, { id: i, type: "endHidden" }]
                setList(array);
            } else {
                setList([{ id: 1, type: "endHidden" }]);
                props.updateData([{ id: 1, type: "endHidden" }])
            }
        }
    }

    let LinkElement = (props) => {
        return(
            <div className="link">
                <Space>
                    <Button type="primary">{props.item.name}</Button>
                    <Button onClick={() => openLink(props.item.id)} type="link">Изменить ссылку</Button>
                </Space>
                <Tooltip title="Удалить ссылку">
                    <Button onClick={() => deleteBlock(props.item.id)} icon={<DeleteOutlined />} className="delete" />
                </Tooltip>

                <Tooltip title="Выше">
                    <Button onClick={() => editPosition(props.item.id, "top")} icon={<VerticalAlignTopOutlined />} className="top" />
                </Tooltip>
                <Tooltip title="Ниже">
                    <Button onClick={() => editPosition(props.item.id, "bottom")} icon={<VerticalAlignBottomOutlined />} className="bottom" />
                </Tooltip>
            </div>
        )
    };

    const ImageElement = (props) => {
        return(
            <div className="image">
                <img src={props.item.value.url} alt=""/>
                <Tooltip title="Удалить изображение">
                    <Button onClick={() => deleteBlock(props.item.id)} icon={<DeleteOutlined />} className="delete" />
                </Tooltip>
                <Tooltip title="Выше">
                    <Button onClick={() => editPosition(props.item.id, "top")} icon={<VerticalAlignTopOutlined />} className="top" />
                </Tooltip>
                <Tooltip title="Ниже">
                    <Button onClick={() => editPosition(props.item.id, "bottom")} icon={<VerticalAlignBottomOutlined />} className="bottom" />
                </Tooltip>
            </div>
        )
    }

    const setTooltip = (e) => {
        if(e.bubbles === true) {
            if(e.target.offsetParent.offsetTop !== panel.y || panel.display === false) {
                console.log(e);
                console.log(e.target.offsetParent.offsetTop);
                setPanel({
                    display: true,
                    x: e.nativeEvent.clientX,
                    y: e.target.offsetParent.offsetTop
                });
            }

        }
    }

    const onBlur = () => {
        // setPanel({ ...panel, display: false });
    }

    const ListData = (props) => {
        let listItems = props.item
        let contentHidden = 0

        const list = listItems.map(item => {

            if(item.type === "hidden") {
                contentHidden = 1
            } else if(item.type === "endHidden") {
                contentHidden = 0
            }

            return(
                <>

                    {item.type === "link" &&
                        <div className={contentHidden > 0 ? "hidden-data" : ""}>
                            <LinkElement item={item} onChange={setValueLink} />
                        </div>
                    }
                    {item.type === "image" &&
                        <div className={contentHidden > 0 ? "hidden-data" : ""}><ImageElement item={item} /></div>
                    }
                    {item.type === "hidden" &&
                        <>
                            <div className="activeText">
                                <p>Начало скрытого блока: {item.value}</p>
                                <Tooltip title="Удалить начало скрытого блока">
                                    <Button onClick={() => deleteBlock(item.id)} icon={<DeleteOutlined />} className="delete" />
                                </Tooltip>

                                <Tooltip title="Выше">
                                    <Button onClick={() => editPosition(item.id, "top")} icon={<VerticalAlignTopOutlined />} className="top" />
                                </Tooltip>
                                <Tooltip title="Ниже">
                                    <Button onClick={() => editPosition(item.id, "bottom")} icon={<VerticalAlignBottomOutlined />} className="bottom" />
                                </Tooltip>
                            </div>
                        </>
                    }
                    {item.type === "text" &&
                    <div className={contentHidden > 0 ? "hidden-data" : "activeText"}>
                        <ContentEditable
                            className={classText}
                            html={sanitizeHtml(item.value, sanitizeConf)}
                            disabled={false}
                            onChange={e => onChangeTextByID(sanitizeHtml(e.target.value, sanitizeConf), item.id)}
                            onSelect={(e) => setTooltip(e, item.id)}
                        />
                        <Tooltip title={"Удалить текстовый блок"}>
                            <Button onClick={() => deleteBlock(item.id)} icon={<DeleteOutlined />} className="delete" />
                        </Tooltip>
                        <Tooltip title="Выше">
                            <Button onClick={() => editPosition(item.id, "top")} icon={<VerticalAlignTopOutlined />} className="top" />
                        </Tooltip>
                        <Tooltip title="Ниже">
                            <Button onClick={() => editPosition(item.id, "bottom")} icon={<VerticalAlignBottomOutlined />} className="bottom" />
                        </Tooltip>
                    </div>
                    }
                    {item.type === "endHidden" &&
                        <>
                            <div className="activeText">
                                <p>Конец скрытого блока</p>
                                <Tooltip title="Удалить конец скрытого блока">
                                    <Button onClick={() => deleteBlock(item.id)} icon={<DeleteOutlined />} className="delete" />
                                </Tooltip>

                                <Tooltip title="Выше">
                                    <Button onClick={() => editPosition(item.id, "top")} icon={<VerticalAlignTopOutlined />} className="top" />
                                </Tooltip>
                                <Tooltip title="Ниже">
                                    <Button onClick={() => editPosition(item.id, "bottom")} icon={<VerticalAlignBottomOutlined />} className="bottom" />
                                </Tooltip>
                            </div>
                        </>
                    }

                    <Space>
                        <Tooltip title="Добавить текст">
                            <Button onClick={() => setNewActive("text", item.id)} icon={<FontSizeOutlined />} size="small" />
                        </Tooltip>
                        <Tooltip title="Добавить изображение">
                            <Button onClick={() => addImageIn(item.id)} icon={<FileImageOutlined />} size="small" />
                        </Tooltip>
                        <Tooltip title="Добавить ссылку">
                            <Button onClick={() => addLinkIn(item.id)} icon={<LinkOutlined />} size="small" />
                        </Tooltip>
                        <Tooltip title="Начало скрытого блока">
                            <Button onClick={() => hiddenBlock(item.id)} size="small">Начало скрытого блока</Button>
                        </Tooltip>
                        <Tooltip title="Конец скрытого блока">
                            <Button onClick={() => endHiddenBloсk(item.id)} size="small" >Конец скрытого блока</Button>
                        </Tooltip>
                    </Space>
                </>
            )
        })

        console.log(list)

        return list
    }

    return(
        <>
            {panel.display === true && <div className="buttons" style={{ top: panel.y - 10, left: 0 }}>{elements}</div>}

            <div className="visual">
                <Row>
                    <Col span={20}>
                        <ListData item={list} />

                        {active.id > -1 && active.id < 1 && active.type === "link" && <LinkElement item={active} onChange={setValueLink} />}
                        {active.id > -1 && active.id < 1 && active.type === "image" && <ImageElement item={image} />}
                        <div
                            className="new"
                        >
                            <Space>
                                <Button onClick={() => setNewActive("text")}>Добавить текстовый блок</Button>
                                <Button onClick={openImage}>Добавить изображение</Button>
                                <Button onClick={openLink}>Добавить ссылку</Button>
                                <Button onClick={hiddenBlock}>Добавить начало скрытого блока</Button>
                                <Button onClick={endHiddenBloсk}>Завершить скрытый блок</Button>
                            </Space>
                        </div>
                    </Col>
                </Row>

                <Modal title="Редактирование ссылки" visible={modalLink} onOk={saveLink} onCancel={() => closeModal("link")}>
                    <Typography.Title level={5}>Название ссылки</Typography.Title>
                    <Input
                        type="text"
                        placeholder="Название ссылки"
                        value={link.name}
                        onChange={e => setLink({ ...link, name: e.target.value })}
                    />
                    <br/><br/>
                    <Typography.Title level={5}>Тип ссылки</Typography.Title>
                    <Select
                        value={link.typeLink}
                        onChange={e => setLink({ ...link, typeLink: e })}
                        style={{ width: 100 + "%" }}
                    >
                        <Select.Option value={"external"}>Внешняя</Select.Option>
                        <Select.Option value={"inner"}>Внутренняя</Select.Option>
                    </Select>
                    <br/><br/>
                    {link.typeLink === "external" ?
                        <>
                            <Typography.Title level={5}>Внешняя ссылка</Typography.Title>
                            <Input
                                type="text"
                                value={link.value}
                                onChange={e => setLink({  ...link, value: e.target.value })}
                                placeholder="https://..."
                            />
                        </>
                        :
                        <>
                            {/*<Typography.Title level={5}>Тип перехода</Typography.Title>*/}
                            {/*<Select*/}
                            {/*    value={link.typeElement}*/}
                            {/*    style={{ width: 250 }}*/}
                            {/*    onChange={e => setLink({  ...link, typeElement: e })}*/}
                            {/*>*/}
                            {/*    <Select.Option value={"lesson"}>Урок</Select.Option>*/}
                            {/*    <Select.Option value={"article"}>Статья</Select.Option>*/}
                            {/*    <Select.Option value={"product"}>Товар</Select.Option>*/}
                            {/*</Select>*/}
                            <Typography.Title level={5}>Укажите ID статьи</Typography.Title>
                            <InputNumber
                                onChange={e => setLink({  ...link, idElement: e })}
                                value={link.idElement}
                                placeholder="ID статьи"
                                min={0}
                                style={{ width: 100 + "%" }}
                            />
                        </>
                    }
                </Modal>
                <Modal title="Загрузка изображения" visible={modalImage} footer={null} onCancel={() => closeModal("image")}>
                    <Upload {...uploader}>
                        <Button icon={<UploadOutlined />}>Загрузить изображение</Button>
                    </Upload>
                </Modal>
                <Modal title="Заголовок скрытого блока" visible={hidden} onCancel={() => closeModal("hidden")} onOk={startHidden}>
                    <Input value={hiddenTitle.text} onChange={e => setHiddenTitle({...hiddenTitle, text: e.target.value})} />
                </Modal>
            </div>
        </>

    )
}

export default VisualEditor;