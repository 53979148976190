import { makeAutoObservable, makeObservable } from "mobx"
import store from "store";

class Middleware {

    admin = null;

    constructor() {
        makeAutoObservable(this);
    }

    isAdmin() {
        if(store.get("user") && store.get("user").id > 0 && store.get("user").token !== "") {
            this.admin = 1;
            if(window.location.pathname === "/signin"){
                document.location.href = "/administrators";
            }
        } else {
            if(window.location.pathname !== "/signin"){
                document.location.href = "/signin";
            }
        }
    }

}

export default new Middleware();