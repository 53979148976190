import { observer } from "mobx-react-lite";
import React, { useEffect } from "react"
import { Row, Col, Input, Button, Typography, Upload, Select, Space, Checkbox } from "antd";
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import config from "../../config.json";
import uploadStore from "../../store/uploadStore";
import categoryStore from "../../store/categoryStore";
import productStore from "../../store/productStore";
import SEO from "@americanexpress/react-seo";
import LoadCart from "../LoadCart";
import searchStore from "../../store/searchStore";
import VisualEditor from "../VisualEditor";
import articleStore from '../../store/articleStore';
import {useParams} from 'react-router';
const { Option } = Select;

const Product = observer((props) => {

    const { productID } = useParams()
    
    useEffect(() => {
        if(productID && productID > 0){
            productStore.getProduct(productID);
        }
        categoryStore.setType("product")
        categoryStore.getAllCategory();
    }, []);

    const propsUpload = {
        name: 'file',
        action: config.apiUrl + '/upload/upload',
        headers: {
            "Type-Device": config.typeDevice
        },
        data: {
            path: uploadStore.file.type + "/product/" + uploadStore.getPathDate(),
            type: uploadStore.file.type
        },
        onChange(info) {
            let photo = uploadStore.onChangeFile(info);
            if(typeof(photo) === "object") {
                productStore.setPhoto(photo);
            }
        },
    };

    const editText = (e) => {
        productStore.onChange("description", e)
    }

    const element = (
        <form onSubmit={e => productStore.sendForm(e)}>
            <Row className="products administration" justify="space-around" align="middle">
                <Col span={16} className="admHeading">
                    <Typography.Title>
                        {productID && productID > 0 ? "Редактировать товар" : "Добавить товар"}
                    </Typography.Title>
                </Col>
                <Col span={8} align="right">
                    <Space>
                        <Button type="dashed" href="/shop/products">К списку</Button>
                        <Button type="primary" htmlType="submit">Сохранить</Button>
                    </Space>
                </Col>
                <Col span={24}>
                    <Row gutter={[15, 30]}>
                        <Col span={24}>
                            <Checkbox
                                onChange={e => productStore.itemDay(e)}
                                checked={productStore.product.viewmain}
                            >
                                Товар дня
                            </Checkbox>
                        </Col>
                        <Col span={6}>
                            <Checkbox
                                onChange={e => productStore.onChange("ru", e.target.checked === true ? 1 : 0)}
                                checked={productStore.product.ru}
                            >
                                Показывать на РУ
                            </Checkbox>
                        </Col>
                        <Col span={6}>
                            <Checkbox
                                onChange={e => productStore.onChange("en", e.target.checked === true ? 1 : 0)}
                                checked={productStore.product.en}
                            >
                                Показывать на EN
                            </Checkbox>
                        </Col>
                        <Col xs={24}>
                            <Typography.Title level={5}>Название</Typography.Title>
                            <Input
                                onChange={e => productStore.onChange("name", e.target.value)}
                                value={productStore.product.name}
                                required
                            />
                        </Col>
                        <Col xs={24} lg={8}>
                            <Typography.Title level={5}>Категория</Typography.Title>
                            <Select
                                name="categoryid"
                                showSearch
                                style={{ width: 250 }}
                                placeholder="Искать"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                                onChange={
                                    e => productStore.onChange("categoryid", e)
                                }
                                onSearch={e => searchStore.getCategoryProduct(e)}
                                value={productStore.product.categoryid}
                            >
                                {searchStore.list.map((cat) => (
                                    <Option value={cat.id}>{cat.name}</Option>
                                ))}
                            </Select>
                        </Col>
                        <Col xs={24} lg={4}>
                            <Typography.Title level={5}>Цена</Typography.Title>
                            <Input
                                onChange={e => productStore.onChange("price", e.target.value)}
                                value={productStore.product.price}
                                required
                            />
                        </Col>
                        <Col xs={24} lg={4}>
                            <Typography.Title level={5}>Цена до</Typography.Title>
                            <Input
                                onChange={e => productStore.onChange("priceto", e.target.value)}
                                value={productStore.product.priceto}
                                required
                            />
                        </Col>
                        <Col xs={24} lg={8}>
                            <Typography.Title level={5}>Ссылка на магазин</Typography.Title>
                            <Input
                                onChange={e => productStore.onChange("shopurl", e.target.value)}
                                value={productStore.product.shopurl}
                                required
                            />
                        </Col>
                        <Col xs={24} lg={24}>
                            <Typography.Title level={5}>Описание</Typography.Title>
                            <VisualEditor
                                value={productStore.product.description}
                                updateData={editText}
                            />
                        </Col>
                        <Col xs={24}>
                            <Typography.Title level={5}>Фото</Typography.Title>
                            {productStore.product.photo.length > 0 && productStore.product.photo.map((img) => (
                                <>
                                    <img src={img.url} width={100 + "%"} alt=""/>
                                    <Button
                                        type="primary"
                                        shape="circle"
                                        icon={<DeleteOutlined />}
                                        size="small"
                                        onClick={() => productStore.removePhoto(img.url)} />
                                </>
                            ))}
                            <Upload
                                {...propsUpload}
                                listType="picture-card"
                                className="photo-uploader"
                                multiple
                                showUploadList={false}
                            >
                                <div>
                                    <PlusOutlined />
                                    <div style={{ marginTop: 8 }}>Загрузить</div>
                                </div>
                            </Upload>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </form>
    );

    return(
        <>
            <SEO
                title={productID && productID > 0 ? "Редактировать товар" : "Добавить товар"}
            />
            {productID && productID > 0 && productStore.product.id === 0 ? <LoadCart /> : element}
        </>
    )
});

export default Product;