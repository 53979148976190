import { makeAutoObservable, makeObservable } from "mobx"
import { message } from "antd"
import axios from "./axios";
import articleStore from "./articleStore";

class UploadStore {

    file = {
        type: "image",
        path: ""
    };

    img = {
        url: "",
        width: "0",
        height: "0"
    };

    audio = {
        url: ""
    };

    video = {
        url: ""
    }

    constructor() {
        makeAutoObservable(this);
    }

    onChangeFile(info) {
        console.log(info)
        if (info.file.status === 'done') {
            return {
                url: info.file.response.data.url,
                width: info.file.response.data.width,
                height: info.file.response.data.height
            };
        } else if (info.file.status === 'error') {
            message.error(`Загрузка файла провалилась.`);
            return false;
        }
    }

    getPathDate() {
        let today = new Date();
        let year = today.getFullYear();
        year = year + ("0" + (today.getMonth() + 1)).slice(-2);
        return year;
    }

    setType(type) {
        this.file.type = type;
    }

    imageInfo(img) {
        this.img = img;
    }

    deleteFile(url) {
        let facts = new FormData();
        facts.append("path", url);
        axios.post("/upload/delete/", facts)
            .then(success => {
                // console.log(success);
            })
            .catch(error => message.error("Ошибка удаления файла"));
    }

}

export default new UploadStore();