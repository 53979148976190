import { makeAutoObservable } from "mobx"
import axios from "./axios"
import { message } from "antd"
import config from "../config.json";
import uploadStore from "./uploadStore";

class musicStore {

    music = {
        id: 0,
        name: "",
        photo: null,
        photo2: null,
        photo3: null,
        url: "",
        ru: null,
        en: null
    };

    list = [];

    count = -1;
    now = 1;
    limit = config.limit;

    status = false;

    constructor() {
        makeAutoObservable(this);
    }

    onChange(name, value) {
        this.music[name] = value;
    }

    getAllMusic() {
        axios.get("/music/music?limit=" + this.limit + "&page=" + this.now)
            .then(success => {
                this.list = success.data.items;
                this.count = Number(success.data.pagination[1]);
                this.now = Number(success.data.pagination[0]);
            })
            .catch(error => console.log(error));
    }

    deleteMusicAudio(musicID) {
        axios.delete("/music/music/" + musicID)
            .then(success => {
                this.getAllMusic();
            })
            .catch(error => console.log(error));
    }

    sendForm(e) {
        e.preventDefault();


        let facts = new FormData();
        facts.append("name", this.music.name);
        facts.append("photo", this.music.photo !== null ? JSON.stringify(this.music.photo) : "");
        facts.append("photo2", this.music.photo2 !== null ? JSON.stringify(this.music.photo2) : "");
        facts.append("photo3", this.music.photo3 !== null ? JSON.stringify(this.music.photo3) : "");
        facts.append("url", this.music.url !== "" ? this.music.url : "");
        facts.append("ru", this.music.ru);
        facts.append("en", this.music.en);

        let url
        if(this.music.id > 0) {
            url = "/music/music/" + this.music.id;
        } else {
            url = "/music/music"
        }
        axios.post(url, facts)
            .then(success => {
                if(this.music.id > 0) {
                    message.success('Успешно');
                } else {
                    document.location.href = "/music";
                }
            })
            .catch(error => console.log(error.response.data.message));
    }

    getMusic(musicID) {
        axios.get("/music/music/" + musicID)
            .then(response => {
                console.log(response);
                this.music = {
                    id: response.data.id,
                    name: response.data.name,
                    photo: response.data.photo !== "" && response.data.photo !== null ? response.data.photo : {},
                    photo2: response.data.photo2 && response.data.photo2 !== "" && response.data.photo2 !== null ? response.data.photo2 : {},
                    photo3: response.data.photo3 && response.data.photo3 !== "" && response.data.photo3 !== null ? response.data.photo3 : {},
                    url: response.data.url,
                    ru: response.data.ru,
                    en: response.data.en,
                };

            })
            .catch(response => console.log(response));
    }

    setPhoto(photo) {
        this.music.photo = photo;
    }

    setPhoto2(photo) {
        this.music.photo2 = photo;
    }

    setPhoto3(photo) {
        this.music.photo3 = photo;
    }

    setAudio(audio) {
        this.music.url = audio.url;
    }

    setNow(page) {
        this.now = page;
        this.getAllMusic()
    }

    playMusic(url) {
        if(this.status) {
            this.status.pause();
            this.status = false;
        } else {
            this.status = new Audio(url);
            this.status.play();
        }
    }

    deleteImage() {
        uploadStore.deleteFile(this.music.photo.url)
        this.music.photo = {};
    }

    deleteImage2() {
        uploadStore.deleteFile(this.music.photo2.url)
        this.music.photo2 = {};
    }

    deleteImage3() {
        uploadStore.deleteFile(this.music.photo3.url)
        this.music.photo3 = {};
    }

    deleteMusic() {
        uploadStore.deleteFile(this.music.url)
        this.music.url = "";
    }
}

export default new musicStore();