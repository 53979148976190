import { Skeleton, Col, Row } from 'antd';

const LoadList = () => {
    return(
        <>
            <Row className="practices administration" justify="space-around" align="middle">
                <Col span={20} className="admHeading">
                    <Skeleton.Input style={{ width: 200 }} active />
                </Col>
                <Col span={4} align="right">
                    <Skeleton.Button active />
                </Col>
                <Col span={24}>
                    <Skeleton active />

                    <Skeleton active />

                    <Skeleton active />
                </Col>
            </Row>
        </>
    )
};

export default LoadList;