import { makeAutoObservable } from "mobx"
import axios from "./axios"
import { message } from "antd"
import config from "../config.json";
import uploadStore from "./uploadStore";
import searchStore from "./searchStore";

class LessonStore {

    lesson = {
        id: 0,
        name: "",
        description: "",
        text: [],
        recommendations: [],
        photo: {},
        video: "",
        audio: "",
        textaudio: "",
        recommendationsaudio: "",
        basic: 'Основное',
        free: false,
        themeoftheday: '',
        ru: null,
        en: null
    };

    list = [];

    count = -1;
    now = 1;
    limit = config.limit;

    constructor() {
        makeAutoObservable(this);
    }

    setLimit(limit) {
        this.limit = limit
        this.now = 1
        this.getAllLesson();
    }

    onChange(name, value) {
        this.lesson[name] = value;
    }

    getAllLesson() {
        axios.get("/practice/lesson?limit=" + this.limit + "&page=" + this.now)
            .then(success => {
                console.log(success);
                this.list = success.data.items;
                this.count = Number(success.data.pagination[1]);
                this.now = Number(success.data.pagination[0]);
            })
            .catch(error => console.log(error));
    }

    deleteLesson(lessonID) {
        axios.delete("/practice/lesson/" + lessonID)
            .then(success => {
                this.getAllLesson();
            })
            .catch(error => console.log(error));
    }

    sendForm(e) {
        e.preventDefault();

        let facts = new FormData();
        facts.append("name", this.lesson.name);
        facts.append("description", this.lesson.description);
        facts.append("text", JSON.stringify(this.lesson.text));
        facts.append("practiceid", this.lesson.practiceid);
        facts.append("recommendations", JSON.stringify(this.lesson.recommendations));
        facts.append("free", this.lesson.free);
        facts.append('themeoftheday', this.lesson.themeoftheday)
        // facts.append("ru", this.lesson.ru);
        // facts.append("en", this.lesson.en);

        facts.append("photo", this.lesson.photo.url ? JSON.stringify(this.lesson.photo) : "");

        if(this.lesson.video !== "") {
            facts.append("video", this.lesson.video);
        } else {
            facts.append("video", "");
        }

        if(this.lesson.audio !== "") {
            facts.append("audio", this.lesson.audio);
        } else {
            facts.append("audio", "");
        }

        if(this.lesson.textaudio !== "") {
            facts.append("textaudio", this.lesson.textaudio);
        } else {
            facts.append("textaudio", "");
        }

        if(this.lesson.recommendationsaudio !== "") {
            facts.append("recommendationsaudio", this.lesson.recommendationsaudio);
        } else {
            facts.append("recommendationsaudio", "");
        }

        if(typeof(this.lesson.practiceid) === "undefined") {
            return message.error('Не выбрана практика.');
        }

        if(this.lesson.basic !== "") {
            facts.append("basic", this.lesson.basic);
        } else {
            facts.append("basic", "Основное");
        }

        let url
        if(this.lesson.id > 0) {
            url = "/practice/lesson/" + this.lesson.id;
        } else {
            url = "/practice/lesson"
        }
        axios.post(url, facts)
            .then(success => {
                if(this.lesson.id > 0) {
                    message.success('Успешно');
                } else {
                    document.location.href = "/practices/lessons";
                }
            })
            .catch(error => console.log(error.response.data.message));
    }

    getLesson(lessonID) {
        axios.get("/practice/lesson/" + lessonID)
            .then(response => {
                console.log(response)
                this.lesson = {
                    audio: response.data.audio,
                    description: response.data.description,
                    exercise: response.data.exercise,
                    id: response.data.id,
                    name: response.data.name,
                    photo: response.data.photo !== "" && response.data.photo !== null ? response.data.photo : {},
                    position: response.data.position,
                    practiceid: response.data.practiceid,
                    text: Array.isArray(response.data.text) ? response.data.text : [],
                    text2: response.data.text2,
                    video: response.data.video,
                    recommendations: Array.isArray(response.data.recommendations) ? response.data.recommendations : [],
                    recommendations2: response.data.recommendations2,
                    recommendationsaudio: response.data.recommendationsaudio,
                    textaudio: response.data.textaudio,
                    basic: response.data.basic,
                    free: response.data.free,
                    themeoftheday: response.data.themeoftheday,
                    ru: response.data.ru,
                    en: response.data.en,
                };
                searchStore.getByID("practice", this.lesson.practiceid, "list");
            })
            .catch(response => console.log(response));
    }

    setPhoto(photo) {
        this.lesson.photo = photo;
    }

    setVideo(video) {
        this.lesson.video = video.url;
    }

    setAudio(audio) {
        this.lesson.audio = audio.url;
    }

    setTextAudio(audio) {
        this.lesson.textaudio = audio.url;
    }

    setRecommendationsAudio(audio) {
        this.lesson.recommendationsaudio = audio.url;
    }

    setNow(page) {
        this.now = page;
        this.getAllLesson()
    }

    deleteImage() {
        uploadStore.deleteFile(this.lesson.photo.url)
        this.lesson.photo = {};
    }

    deleteMusic() {
        uploadStore.deleteFile(this.lesson.audio)
        this.lesson.audio = "";
    }

    deleteMusicText() {
        uploadStore.deleteFile(this.lesson.textaudio)
        this.lesson.textaudio = "";
    }

    deleteMusicRec() {
        uploadStore.deleteFile(this.lesson.recommendationsaudio)
        this.lesson.recommendationsaudio = "";
    }

    deleteVideo() {
        uploadStore.deleteFile(this.lesson.video)
        this.lesson.video = "";
    }

    setAudioReccomendations(url) {
        this.lesson.recommendationsaudio = url.url
    }

    setAudioText(url) {
        this.lesson.textaudio = url.url
    }
}

export default new LessonStore();