import { observer } from "mobx-react-lite";
import React, { useEffect } from "react"
import {Row, Col, Input, Button, Typography, Upload, Checkbox, Select} from "antd";
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import config from "../../config.json";
import uploadStore from "../../store/uploadStore";
import catsStore from "../../store/categoryStore";
import SEO from "@americanexpress/react-seo";
import LoadCart from "../LoadCart";
import articleStore from '../../store/articleStore';
import practiceStore from '../../store/practiceStore';
import {useParams} from 'react-router';

const Category = observer((props) => {

    const { catType, catID } = useParams()
    
    useEffect(() => {
        catsStore.setType(catType);
        if(catID && catID > 0){
            catsStore.getCategory(catID);
        }
    }, []);

    const propsUpload = {
        name: 'file',
        action: config.apiUrl + '/upload/upload',
        headers: {
            "Type-Device": config.typeDevice
        },
        data: {
            path: uploadStore.file.type + "/category/" + uploadStore.getPathDate(),
            type: uploadStore.file.type
        },
        onChange(info) {
            let photo = uploadStore.onChangeFile(info);
            if(typeof(photo) === "object") {
                catsStore.setPhoto(photo);
            }

        },
    };

    const element = (
        <form onSubmit={e => catsStore.sendForm(e)}>
            <Row className="articles administration" justify="space-around" align="middle">
                <Col span={20} className="admHeading">
                    <Typography.Title>
                        {catID && catID > 0 ? "Редактировать категорию" : "Добавить категорию"}
                    </Typography.Title>
                </Col>
                <Col span={4} align="right">
                    <Button htmlType="submit" type="primary">Сохранить</Button>
                </Col>
                <Col span={24}>
                    <Row gutter={[15, 30]}>
                        <Col xs={24}>
                            <Typography.Title level={5}>Название</Typography.Title>
                            <Input
                                onChange={e => catsStore.onChange("name", e.target.value)}
                                value={catsStore.category.name}
                                required
                            />
                        </Col>
                        <Col span={6}>
                            <Checkbox
                                onChange={e => catsStore.onChange("ru", e.target.checked === true ? 1 : 0)}
                                checked={catsStore.category.ru}
                            >
                                Показывать на РУ
                            </Checkbox>
                        </Col>
                        <Col span={6}>
                            <Checkbox
                                onChange={e => catsStore.onChange("en", e.target.checked === true ? 1 : 0)}
                                checked={catsStore.category.en}
                            >
                                Показывать на EN
                            </Checkbox>
                        </Col>
                        <Col span={6}>
                            <Typography.Title level={5}>Вариант отображения</Typography.Title>
                            <Select
                                style={{ width: `100%` }}
                                value={catsStore.category.viewvariant}
                                onChange={e => catsStore.onChange("viewvariant", e)}
                            >
                                <Select.Option value={1}>Первый</Select.Option>
                                <Select.Option value={2}>Второй</Select.Option>
                                <Select.Option value={3}>Третий</Select.Option>
                            </Select>
                        </Col>
                        <Col xs={24}>
                            <Typography.Title level={5}>Фото</Typography.Title>
                            {catsStore.category.photo !== null && catsStore.category.photo.url &&
                            <div className="forImage">
                                <Button
                                    className="delete"
                                    danger
                                    type="dashed"
                                    shape="circle"
                                    icon={<DeleteOutlined />}
                                    onClick={() => catsStore.deleteImage()}
                                />
                                <img src={catsStore.category.photo.url} width={100 + "%"} alt=""/>
                            </div>
                            }
                            <Upload
                                {...propsUpload}
                                listType="picture-card"
                                className="photo-uploader"
                                maxCount={1}
                            >
                                <div>
                                    <PlusOutlined />
                                    <div style={{ marginTop: 8 }}>Загрузить</div>
                                </div>
                            </Upload>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </form>
    );

    return(
        <>
            <SEO
                title={catID && catID > 0 ? "Редактировать категорию" : "Добавить категорию"}
            />
            {catID && catID > 0 && catsStore.category.id === 0 ? <LoadCart /> : element}
        </>
    )
});

export default Category;