import {observer} from "mobx-react-lite";
import SEO from "@americanexpress/react-seo";
import {Col, Row, Typography, Switch} from "antd";
import _setting from "./_setting";
import { useEffect } from "react"

const Setting = observer(() => {

    useEffect(() => {
        _setting.getSetting()
    }, [])

    return(
        <>
            <SEO
                title="Настройки"
            />

            <Row className="recipes administration" justify="space-around" align="middle">
                <Col span={20} className="admHeading">
                    <Typography.Title>Настройки</Typography.Title>
                </Col>
                <Col span={4} align="right">

                </Col>
                <Col span={24}>
                    <Switch
                        checked={Number(_setting.settings.find(el => el.keytype === "activation_code").value) > 0 ? true : false}
                        onChange={e => _setting.onChange(e)}
                    />
                </Col>
            </Row>
        </>
    )
})

export default Setting