import { Controller } from "../../controller"
import axios from '../../store/axios';
import { makeObservable, observable } from "mobx"
import practiceStore from '../../store/practiceStore';
import api from '../../store/requests/api';

class UserController extends Controller {

    now = 1
    list = []
    user = {}

    listPay = []
    pay_offset = 0
    pay_count = 0
    pay_now = 1

    modal = false

    filter = ''

    activeSubscriptionToggle = false

    constructor() {
        super()
        makeObservable(this, {
            now: observable,
            list: observable,
            user: observable,
            filter: observable,
            listPay: observable,
            pay_offset: observable,
            pay_count: observable,
            pay_now: observable,
            modal: observable,
            activeSubscriptionToggle: observable,
        })
    }

    unActiveSubscription(userId) {
        api.post(`/user/unsubscription/${userId}`)
            .then(result => {
                console.log(result)
                this.getUser(userId)
            })
    }

    activeSubscriptionToUser(timestamp, userId) {

        const data = new FormData()
        data.append('time', timestamp)

        api.post(`/user/subscription/${userId}`, data)
            .then(result => {
                console.log(result)
                this.getUser(userId)
                this.activeSubscriptionToggle = false
            })

    }

    activeSubscription() {
        this.activeSubscriptionToggle = true
    }

    getUsers() {
        let url = `/user/userlist/?limit=${this.limit}&page=${this.now}`

        if(this.filter !== '')
            url = `${url}&filter[email]=${this.filter}`

        axios.get(url)
            .then(success => {
                console.log(success)
                this.list = success.data.items
                this.setCount(Number(success.data.pagination[1]))
                this.now = Number(success.data.pagination[0]);
            })
            .catch(error => {
                console.log(error)
            })
    }

    getUser(userID) {
        api.get(`/user/${userID}`)
            .then(response => {
                console.log(response)
                this.user = response.data
            })
            .catch(error => {
                console.log(error)
            })
    }

    searchUsers(value) {
        this.filter = value
        this.offset = 0
        this.getUsers()
    }

    setNow(page) {
        this.now = page
        this.getUsers()
    }

    setNowPay(page, userID) {
        this.pay_now = page
        this.allPayByUser(userID)
    }

    allPayByUser(userId) {
        axios.get(`/user/userpay?userid=${userId}&limit=${this.limit}&page=${this.pay_now}`)
            .then(response => {
                console.log(response)
                this.listPay = response.data.items
                this.pay_count = Number(response.data.pagination[1])
                this.pay_now = Number(response.data.pagination[0])
            })
            .catch(error => {
                console.log(error)
            })
    }

    addItem() {
        practiceStore.getAllPractice()
        this.modal = true
    }
    addPracticeToUser(practiceId, userId) {
        const data = new FormData()
        data.append('transactionid', 'add to admin')
        data.append('practiceid', practiceId)
        data.append('userid', userId)

        axios.post(`/practice/practicepay`, data)
            .then(result => {
                console.log(result)
                this.allPayByUser(userId)
                this.closeModal()
            })
            .catch(error => console.log(error))
    }

    deletePay(practiceId, userId) {
        console.log(practiceId)
        axios.delete(`/user/userpay/${practiceId}`)
            .then(result => {
                console.log(result)
                this.allPayByUser(userId)
            })
            .catch(error => console.log(error))
    }

    closeModal() {
        this.modal = false
    }

}

export default new UserController()