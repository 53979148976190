import { observer } from "mobx-react-lite";
import React, { useEffect } from "react"
import {
    Row,
    Col,
    Button,
    Typography,
    Avatar,
    Image,
    Space,
    Tag,
    Table,
    Pagination,
    Modal,
    Tooltip,
    DatePicker
} from "antd"
import SEO from "@americanexpress/react-seo";
import LoadCart from "../LoadCart";

import user_controller from './user.controller'
import {DeleteOutlined, EditOutlined, SelectOutlined} from '@ant-design/icons';
import practiceStore from '../../store/practiceStore';
import moment from 'moment';
import {useParams} from 'react-router';

const User = observer((props) => {

    const { userID } = useParams()
    
    useEffect(() => {
        if(userID && userID > 0){
            user_controller.getUser(userID)
            user_controller.allPayByUser(userID)
        }
    }, []);

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id'
        },
        {
            title: 'Устройство',
            dataIndex: 'type'
        },
        {
            title: 'Практика',
            dataIndex: 'practice',
            render: (el) => <a href={`/practices/practice/${el.id}`} target="_blank">{el.name}</a>
        },
        {
            title: "Действия",
            dataIndex: "acts",
            width: "80px",
            render: (text, row) => (
                <>
                    <Tooltip placement="bottom" title="Удалить покупку">
                        <Button
                            type="dashed"
                            shape="circle"
                            icon={<DeleteOutlined />}
                            onClick={() => user_controller.deletePay(row.id, userID)}
                        />
                    </Tooltip>
                </>
            )
        }
    ]

    const columns_practices = [
        {
            title: "ID",
            dataIndex: "id",
            width: "40px",
            align: "center",
        },
        {
            title: "Название",
            dataIndex: "name",
        },
        {
            title: "Действия",
            dataIndex: "acts",
            width: "80px",
            render: (text, row) => (
                <>
                    <Space>
                        <Tooltip placement="bottom" title="Выбрать">
                            <Button
                                type="dashed"
                                shape="circle"
                                icon={<SelectOutlined />}
                                onClick={() => user_controller.addPracticeToUser(row.id, userID)}
                            />
                        </Tooltip>
                    </Space>
                </>
            )
        }
    ]

    const element = (
        <>
            <Row className="administration user" justify="space-around" align="middle">
                <Col span={16} className="admHeading">
                    <Typography.Title>Просмотр пользователя</Typography.Title>
                </Col>
                <Col span={8} align="right">
                    <Button type="dashed" href="/users">К списку</Button>
                </Col>
            </Row>
            <Row className="administration user" gutter={[20, 20]}>
                <Col span={24}>
                    <Space>
                        <div>
                            {user_controller.user.photo &&
                                <Avatar size={64}
                                        src={<Image src={user_controller.user.photo.url} style={{width: 64}}/>}/>
                            }
                        </div>
                        <div>
                            <p>
                                <strong>Логин/E-mail:</strong> {user_controller.user.login}<br />
                                <strong>ФИО:</strong> {user_controller.user.firstname} {user_controller.user.lastname} {user_controller.user.middlename}<br />
                                <strong>Телефон:</strong> {user_controller.user.phone}<br />
                                <strong>Проведено на практиках:</strong> <Tag color="success">{user_controller.user.countmin} минут</Tag></p>
                        </div>
                    </Space>
                </Col>
                <Col span={24}>
                    <Typography.Title level={5}>Подписка пользователя</Typography.Title>
                    {user_controller.user.subscription ?
                        <>
                            {user_controller.user.subscription.status
                                ? <>
                                    <b>Подпика <Tag color="success">активна до {moment(user_controller.user.subscription.expires_in * 1000).format('LLL')}</Tag></b>
                                    <div>
                                        <Button size="small" danger onClick={() => user_controller.unActiveSubscription(userID)}>Приостановить подписку</Button>
                                    </div>
                                </>
                                : <>
                                    <b>Подпика <Tag color="error">истекла {moment(user_controller.user.subscription.expires_in * 1000).format('LLL')}</Tag></b>
                                </>
                            }

                            <div>
                                <Button size="small" type="primary" onClick={() => user_controller.activeSubscription()}>Продлить</Button>
                            </div>
                        </>
                        :
                        <>
                            <Button size="small" type="primary" onClick={() => user_controller.activeSubscription()}>Активировать подписку</Button>
                        </>
                    }

                    {user_controller.activeSubscriptionToggle &&
                        <Row>
                            <Col span={24}>
                                <label>Активация подписки до:</label>
                                <DatePicker onChange={(e, text) => user_controller.activeSubscriptionToUser(moment(e).format('x'), userID)} />
                            </Col>
                        </Row>
                    }
                </Col>
                <Col span={24}>
                    <Typography.Title level={5}>Список покупок пользователя</Typography.Title>

                    <Button
                        onClick={() => user_controller.addItem()}
                    >
                        Добавить практику
                    </Button>

                    <Table columns={columns} dataSource={user_controller.listPay} size="small" pagination={false}/>
                    {user_controller.pay_count > 0 &&
                        <>
                            <br/>
                            <Pagination
                                onChange={e => user_controller.setNowPay(e, userID)}
                                current={user_controller.pay_now}
                                total={user_controller.pay_count}
                                defaultPageSize={user_controller.limit}
                                showSizeChanger={false}
                            />
                        </>
                    }

                    <Modal
                        title="Добавление практики"
                        open={user_controller.modal}
                        onOk={() => user_controller.closeModal()}
                        onCancel={() => user_controller.closeModal()}
                    >
                        {practiceStore.count > -1 &&
                            <>
                                <Table columns={columns_practices} dataSource={practiceStore.list} size="small" pagination={false}/>
                                {practiceStore.count > 0 &&
                                    <>
                                        <br/>
                                        <Pagination
                                            onChange={e => practiceStore.setNow(e)}
                                            current={practiceStore.now}
                                            total={practiceStore.count}
                                            defaultPageSize={practiceStore.limit}
                                        />
                                    </>
                                }
                            </>
                        }
                    </Modal>
                </Col>
            </Row>
        </>
    );

    return(
        <>
            <SEO
                title="Просмотр пользователя"
                description="Просмотр пользователя"
            />
            {userID && userID > 0 && user_controller.user.id === 0 ? <LoadCart /> : element}
        </>
    )
});

export default User;