import { Skeleton, Space, Col, Row } from 'antd';

const LoadCart = () => {
    return(
        <>
            <Row className="practices administration" justify="space-around" align="middle">
                <Col span={20} className="admHeading">
                    <Skeleton.Input style={{ width: 200 }} active />
                </Col>
                <Col span={4} align="right">
                    <Space>
                        <Skeleton.Button active />
                        <Skeleton.Button active />
                    </Space>
                </Col>
                <Col span={24}>
                    <Space>
                        <Skeleton.Input active style={{ width: 250 }} />
                        <Skeleton.Input active style={{ width: 250 }}  />
                        <Skeleton.Input active style={{ width: 250 }}  />
                        <Skeleton.Input active style={{ width: 250 }}  />
                    </Space>
                    <br/><br/>
                    <Space>
                        <Skeleton.Input active style={{ width: 250 }} />
                        <Skeleton.Input active style={{ width: 250 }}  />
                        <Skeleton.Input active style={{ width: 250 }}  />
                        <Skeleton.Input active style={{ width: 250 }}  />
                    </Space>
                    <br/><br/>
                    <Space>
                        <Skeleton.Input active style={{ width: 250 }} />
                        <Skeleton.Input active style={{ width: 250 }}  />
                        <Skeleton.Input active style={{ width: 250 }}  />
                        <Skeleton.Input active style={{ width: 250 }}  />
                    </Space>

                </Col>
            </Row>
        </>
    )
};

export default LoadCart;