import { makeAutoObservable } from "mobx"
import axios from "./axios"
import { message } from "antd"
import config from "../config.json";
import uploadStore from "./uploadStore";

class AuthorStore {

    author = {
        id: 0,
        name: "",
        list: [],
        photo: {},
        description: [],
        descriptiontarget: [],
        ru: null,
        en: null
    };
    activeList = "";
    listItemNow = "";
    list = [];

    count = -1;
    now = 1;
    limit = config.limit;

    constructor() {
        makeAutoObservable(this);
    }

    onChange(name, value) {
        this.author[name] = value;
    }

    inputsList(z) {
        this.author.list = z;
    }

    getListAuthor() {
        axios.get("author/author?limit=" + this.limit + "&page=" + this.now)
            .then(success => {
                console.log(success)
                this.list = success.data.items;
                this.count = Number(success.data.pagination[1]);
                this.now = Number(success.data.pagination[0]);
            })
            .catch(text => {
                console.log(text)
                message.error(text.response);
            });
    }

    sendForm(e) {
        e.preventDefault();

        let facts = new FormData();
        facts.append("name", this.author.name);
        facts.append("description", JSON.stringify(this.author.description));
        facts.append("descriptiontarget", JSON.stringify(this.author.descriptiontarget));
        facts.append("list", JSON.stringify(this.author.list));

        facts.append("photo", this.author.photo.url ? JSON.stringify(this.author.photo) : "");
        facts.append("ru", this.author.ru);
        facts.append("en", this.author.en);

        let url
        if(this.author.id > 0) {
            url = "/author/author/" + this.author.id;
        } else {
            url = "/author/author"
        }
        axios.post(url, facts)
            .then(success => {
                console.log(success)
                if(this.author.id > 0) {
                    message.success('Успешно');
                } else {
                    document.location.href = "/authors";
                }
            })
            .catch(error => {
                console.log(error.response.data.message)
            });
    }

    getAuthor(authorID) {
        axios.get("/author/author/" + authorID)
            .then(response => {
                console.log(response)
                let list = [];
                if(response.data.list !== "" && response.data.list !== null) {
                    list = JSON.parse(response.data.list);
                }
                this.author = {
                    ...this.author,
                    id: response.data.id,
                    name: response.data.name,
                    description: Array.isArray(response.data.description) ? response.data.description : [],
                    descriptiontarget: Array.isArray(response.data.descriptiontarget) ? response.data.descriptiontarget : [],
                    description2: response.data.description2,
                    descriptiontarget2: response.data.descriptiontarget2,
                    list: list,
                    photo: response.data.photo !== "" && response.data.photo !== null ? response.data.photo : {},
                    ru: response.data.ru,
                    en: response.data.en,
                }
            })
            .catch(response => console.log(response));
    }

    deleteAuthor(id) {
        axios.delete("/author/author/" + id)
            .then(response => {
                message.success('Успешно удалено');
                this.getListAuthor();
            })
            .catch(error => {
                message.error(error.response.data.message);
            });
    }

    onAddList(value) {
        this.author.list = [...this.author.list, value];
        this.clearActive();
    }

    onDeleteList(value) {
        this.author.list = this.author.list.filter(el => el !== value);
        this.clearActive();
    }

    setActive(value) {
        this.activeList = value;
        this.listItemNow = value;
    }

    onEditList(value) {
        this.listItemNow = value;
    }

    clearActive() {
        this.activeList = "";
        this.listItemNow = "";
    }

    saveForEditList() {
        let array = [];
        this.author.list.map(item => {
            if(item === this.activeList) {
                item = this.listItemNow;
            }
            array = [...array, item];
        });
        this.author.list = array;
        this.clearActive();
    }

    setPhoto(photo) {
        this.author.photo = photo;
    }

    setNow(page) {
        this.now = page;
        this.getListAuthor()
    }

    deleteImage() {
        uploadStore.deleteFile(this.author.photo.url)
        this.author.photo = {};
    }
}

export default new AuthorStore();