import {makeObservable, observable} from "mobx";

export class Controller {

    limit = 10
    offset = 0
    count = 0

    constructor() {
        makeObservable(this, {
            limit: observable,
            offset: observable,
            count: observable,
        })
    }


    setLimit(limit) {
        this.limit = limit
    }

    setOffset(offset) {
        this.offset = offset
    }

    setCount(count) {
        this.count = count
    }

}