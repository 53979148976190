import { observer } from "mobx-react-lite";
import React, { useEffect } from "react"
import {Row, Col, Input, Button, Typography, Upload, Select, Space, Checkbox} from "antd";
import { DeleteOutlined, PauseCircleOutlined, PlayCircleOutlined, PlusOutlined } from '@ant-design/icons';
import lessonStore from "../../store/lessonStore";
import config from "../../config.json";
import uploadStore from "../../store/uploadStore";
import practiceStore from "../../store/practiceStore";
import SEO from "@americanexpress/react-seo";
import musicStore from "../../store/musicStore";
import LoadCart from "../LoadCart";
import searchStore from "../../store/searchStore";
import VisualEditor from "../VisualEditor";
import { Editor } from 'primereact/editor';
import articleStore from '../../store/articleStore';
import {useParams} from 'react-router';
const { Option } = Select;

const Lesson = observer((props) => {

    const { lessonID } = useParams()
    
    useEffect(() => {
        if(lessonID && lessonID > 0){
            lessonStore.getLesson(lessonID);
        }
        practiceStore.getAllPractice();
    }, []);

    const propsImageUpload = {
        name: 'file',
        action: config.apiUrl + '/upload/upload',
        headers: {
            "Type-Device": config.typeDevice
        },
        data: {
            path: "image/practice/lesson/" + uploadStore.getPathDate(),
            type: "image"
        },
        onChange(info) {
            let photo = uploadStore.onChangeFile(info);
            if(typeof(photo) === "object") {
                lessonStore.setPhoto(photo);
            }

        },
    };

    const propsVideoUpload = {
        name: 'file',
        action: config.apiUrl + '/upload/upload',
        headers: {
            "Type-Device": config.typeDevice
        },
        data: {
            path: "video/practice/lesson/" + uploadStore.getPathDate(),
            type: "video"
        },
        onChange(info) {
            console.log(info);
            let video = uploadStore.onChangeFile(info);
            if(typeof(video) === "object") {
                lessonStore.setVideo(video);
            }
        },
    };

    const propsAudioUpload = {
        name: 'file',
        action: config.apiUrl + '/upload/upload',
        headers: {
            "Type-Device": config.typeDevice
        },
        data: {
            path: "music/practice/lesson/" + uploadStore.getPathDate(),
            type: "music"
        },
        onChange(info) {
            let audio = uploadStore.onChangeFile(info);
            if(typeof(audio) === "object") {
                lessonStore.setAudio(audio);
            }
        },
    };

    const propsAudioUploadRecommendations = {
        name: 'file',
        action: config.apiUrl + '/upload/upload',
        headers: {
            "Type-Device": config.typeDevice
        },
        data: {
            path: "music/practice/lesson/" + uploadStore.getPathDate(),
            type: "music"
        },
        onChange(info) {
            let audio = uploadStore.onChangeFile(info);
            if(typeof(audio) === "object") {
                lessonStore.setAudioReccomendations(audio);
            }
        }
    }

    const propsAudioUploadText = {
        name: 'file',
        action: config.apiUrl + '/upload/upload',
        headers: {
            "Type-Device": config.typeDevice
        },
        data: {
            path: "music/practice/lesson/" + uploadStore.getPathDate(),
            type: "music"
        },
        onChange(info) {
            let audio = uploadStore.onChangeFile(info);
            if(typeof(audio) === "object") {
                lessonStore.setAudioText(audio);
            }
        }
    }

    const editText = (e) => {
        lessonStore.onChange("text", e)
    }

    const editRecommendation = (e) => {
        lessonStore.onChange("recommendations", e)
    }

    const header = (
        <span className="ql-formats">
        <button className="ql-bold" aria-label="Bold"></button>
    </span>
    );

    const element = (
        <form onSubmit={e => lessonStore.sendForm(e)}>
            <Row className="lessons administration" justify="space-around" align="middle">
                <Col span={16} className="admHeading">
                    <Typography.Title>
                        {lessonID && lessonID > 0 ? "Редактировать урок" : "Добавить урок"}
                    </Typography.Title>
                </Col>
                <Col span={8} align="right">
                    <Space>
                        <Button type="dashed" href="/practices/lessons">К списку</Button>
                        <Button htmlType="submit" type="primary">Сохранить</Button>
                    </Space>
                </Col>
                <Col span={24}>
                    <Row gutter={[15, 30]}>
                        <Col span={6}>
                            <Checkbox
                                onChange={e => lessonStore.onChange("free", e.target.checked === true ? 1 : 0)}
                                checked={lessonStore.lesson.free}
                            >
                                Бесплатный
                            </Checkbox>
                        </Col>
                        <Col xs={24}>
                            <Typography.Title level={5}>Название</Typography.Title>
                            <Input
                                onChange={e => lessonStore.onChange("name", e.target.value)}
                                value={lessonStore.lesson.name}
                                required
                            />
                        </Col>
                        {/*<Col span={6}>*/}
                        {/*    <Checkbox*/}
                        {/*        onChange={e => lessonStore.onChange("ru", e.target.checked === true ? 1 : 0)}*/}
                        {/*        checked={lessonStore.lesson.ru}*/}
                        {/*    >*/}
                        {/*        Показывать на РУ*/}
                        {/*    </Checkbox>*/}
                        {/*</Col>*/}
                        {/*<Col span={6}>*/}
                        {/*    <Checkbox*/}
                        {/*        onChange={e => lessonStore.onChange("en", e.target.checked === true ? 1 : 0)}*/}
                        {/*        checked={lessonStore.lesson.en}*/}
                        {/*    >*/}
                        {/*        Показывать на EN*/}
                        {/*    </Checkbox>*/}
                        {/*</Col>*/}
                        <Col xs={24}>
                            <Typography.Title level={5}>Наименование пункта "ОСНОВНОЕ"</Typography.Title>
                            <Input
                                onChange={e => lessonStore.onChange("basic", e.target.value)}
                                value={lessonStore.lesson.basic}
                            />
                        </Col>
                        <Col xs={24} lg={8}>
                            <Typography.Title level={5}>Практика</Typography.Title>
                            <Select
                                name="practiceid"
                                showSearch
                                style={{ width: 250 }}
                                placeholder="Искать"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                                onChange={
                                    e => lessonStore.onChange("practiceid", e)
                                }
                                onSearch={e => searchStore.getPracticle(e)}
                                value={lessonStore.lesson.practiceid}
                            >
                                {searchStore.list.map((practice) => (
                                    <Option value={practice.id}>{practice.name}</Option>
                                ))}
                            </Select>
                        </Col>
                        <Col xs={24}>
                            <Typography.Title level={5}>Фото</Typography.Title>
                            {lessonStore.lesson.photo.url &&
                            <div className="forImage">
                                <Button
                                    className="delete"
                                    danger
                                    type="dashed"
                                    shape="circle"
                                    icon={<DeleteOutlined />}
                                    onClick={() => lessonStore.deleteImage()}
                                />
                                <img src={lessonStore.lesson.photo.url} width={100 + "%"} alt=""/>
                            </div>
                            }
                            <Upload
                                {...propsImageUpload}
                                listType="picture-card"
                                className="photo-uploader"
                                maxCount={1}
                            >
                                <div>
                                    <PlusOutlined />
                                    <div style={{ marginTop: 8 }}>Загрузить</div>
                                </div>
                            </Upload>
                        </Col>
                        <Col span={24}>
                            <Typography.Title level={5}>Заголовок "Тема дня"</Typography.Title>
                            <Input
                                onChange={e => lessonStore.onChange("themeoftheday", e.target.value)}
                                value={lessonStore.lesson.themeoftheday}
                            />
                        </Col>
                        <Col xs={24} lg={12}>
                            <Typography.Title level={5}>Описание</Typography.Title>
                            <Editor
                                style={{height:'320px'}}
                                value={lessonStore.lesson.description}
                                onTextChange={(e) => lessonStore.onChange("description", e.htmlValue)}
                                headerTemplate={header}
                                required
                            />
                        </Col>
                        <Col span={24}>
                            <Typography.Title level={5}>Основное</Typography.Title>
                            <VisualEditor
                                value={lessonStore.lesson.text}
                                updateData={editText}
                            />
                        </Col>
                        <Col xs={24}>
                            <Typography.Title level={5}>Аудио для основного</Typography.Title>
                            {lessonStore.lesson.textaudio !== "" &&
                            <Space>
                                <Button
                                    shape="circle"
                                    onClick={() => musicStore.playMusic(lessonStore.lesson.textaudio)}
                                    type="dashed"
                                    icon={musicStore.status ? <PauseCircleOutlined /> : <PlayCircleOutlined />}
                                />
                                <Button
                                    danger
                                    type="dashed"
                                    shape="circle"
                                    icon={<DeleteOutlined />}
                                    onClick={() => lessonStore.deleteMusicText()}
                                />
                            </Space>
                            }
                            <Upload
                                {...propsAudioUploadText}
                                listType="picture-card"
                                className="photo-uploader"
                                maxCount={1}
                            >
                                <div>
                                    <PlusOutlined />
                                    <div style={{ marginTop: 8 }}>Загрузить</div>
                                </div>
                            </Upload>
                        </Col>
                        <Col xs={24} lg={24}>
                            <Typography.Title level={5}>Рекомендации</Typography.Title>
                            <VisualEditor
                                value={lessonStore.lesson.recommendations}
                                updateData={editRecommendation}
                            />
                        </Col>
                        <Col xs={24}>
                            <Typography.Title level={5}>Аудио для рекомендаций</Typography.Title>
                            {lessonStore.lesson.recommendationsaudio !== "" &&
                            <Space>
                                <Button
                                    shape="circle"
                                    onClick={() => musicStore.playMusic(lessonStore.lesson.recommendationsaudio)}
                                    type="dashed"
                                    icon={musicStore.status ? <PauseCircleOutlined /> : <PlayCircleOutlined />}
                                />
                                <Button
                                    danger
                                    type="dashed"
                                    shape="circle"
                                    icon={<DeleteOutlined />}
                                    onClick={() => lessonStore.deleteMusicRec()}
                                />
                            </Space>
                            }
                            <Upload
                                {...propsAudioUploadRecommendations}
                                listType="picture-card"
                                className="photo-uploader"
                                maxCount={1}
                            >
                                <div>
                                    <PlusOutlined />
                                    <div style={{ marginTop: 8 }}>Загрузить</div>
                                </div>
                            </Upload>
                        </Col>
                        <Col xs={24}>
                            <Typography.Title level={5}>Видео</Typography.Title>
                            {lessonStore.lesson.video !== "" &&
                                <div className="forImage">
                                    <Button
                                        danger
                                        type="dashed"
                                        shape="circle"
                                        className="delete"
                                        icon={<DeleteOutlined />}
                                        onClick={() => lessonStore.deleteVideo(lessonStore.lesson.video)}
                                    />
                                    <video className="video" controls>
                                        <source src={lessonStore.lesson.video} />
                                    </video>
                                </div>
                            }
                            <Upload
                                {...propsVideoUpload}
                                listType="picture-card"
                                className="photo-uploader"
                                maxCount={1}
                            >
                                <div>
                                    <PlusOutlined />
                                    <div style={{ marginTop: 8 }}>Загрузить</div>
                                </div>
                            </Upload>
                        </Col>
                        <Col xs={24}>
                            <Typography.Title level={5}>Аудио</Typography.Title>
                            {lessonStore.lesson.audio !== "" &&
                                <Space>
                                    <Button
                                        shape="circle"
                                        onClick={() => musicStore.playMusic(lessonStore.lesson.audio)}
                                        type="dashed"
                                        icon={musicStore.status ? <PauseCircleOutlined /> : <PlayCircleOutlined />}
                                    />
                                    <Button
                                        danger
                                        type="dashed"
                                        shape="circle"
                                        icon={<DeleteOutlined />}
                                        onClick={() => lessonStore.deleteMusic(musicStore.music.url)}
                                    />
                                </Space>
                            }
                            <Upload
                                {...propsAudioUpload}
                                listType="picture-card"
                                className="photo-uploader"
                                maxCount={1}
                            >
                                <div>
                                    <PlusOutlined />
                                    <div style={{ marginTop: 8 }}>Загрузить</div>
                                </div>
                            </Upload>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </form>
    );

    return(
        <>
            <SEO
                title={lessonID && lessonID > 0 ? "Редактировать урок" : "Добавить урок"}
            />
            {lessonID && lessonID > 0 && lessonStore.lesson.id === 0 ? <LoadCart /> : element}
        </>
    )
});

export default Lesson;