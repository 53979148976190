import axios from "axios";
import config from "../config.json";
import langController from './lang';

export default axios.create({
    baseURL: config.apiUrl,
    headers: {
        "Type-Device": config.typeDevice,
        "Language": langController.lang
    },
});