import { observer } from "mobx-react-lite";
import React, { useEffect } from "react"
import {Row, Col, Input, Button, Typography, Space, Upload, Checkbox} from "antd";
import faqStore from "../../store/faqStore";
import SEO from "@americanexpress/react-seo";
import LoadCart from "../LoadCart";
import articleStore from '../../store/articleStore';
import {useParams} from 'react-router';

const Faq = observer((props) => {

    const { faqID } = useParams()
    
    useEffect(() => {
        if(faqID && faqID > 0){
            faqStore.getFaq(faqID);
        }
    }, []);

    const element = (
        <form onSubmit={e => faqStore.sendForm(e)}>
            <Row className="faq administration" justify="space-around" align="middle">
                <Col span={16} className="admHeading">
                    <Typography.Title>
                        {faqID && faqID > 0 ? "Редактировать вопрос" : "Добавить вопрос"}
                    </Typography.Title>
                </Col>
                <Col span={8} align="right">
                    <Space>
                        <Button type="dashed" href="/faq">К списку</Button>
                        <Button htmlType="submit" type="primary">Сохранить</Button>
                    </Space>
                </Col>
                <Col span={24}>
                    <Row gutter={[15, 30]}>
                        <Col span={6}>
                            <Checkbox
                                onChange={e => faqStore.onChange("ru", e.target.checked === true ? 1 : 0)}
                                checked={faqStore.faq.ru}
                            >
                                Показывать на РУ
                            </Checkbox>
                        </Col>
                        <Col span={6}>
                            <Checkbox
                                onChange={e => faqStore.onChange("en", e.target.checked === true ? 1 : 0)}
                                checked={faqStore.faq.en}
                            >
                                Показывать на EN
                            </Checkbox>
                        </Col>
                        <Col xs={24}>
                            <Typography.Title level={5}>Вопрос</Typography.Title>
                            <Input
                                onChange={e => faqStore.onChange("question", e.target.value)}
                                value={faqStore.faq.question}
                                required
                            />
                        </Col>
                        <Col xs={24} lg={24}>
                            <Typography.Title level={5}>Ответ</Typography.Title>
                            <Input.TextArea
                                onChange={e => faqStore.onChange("text", e.target.value)}
                                value={faqStore.faq.text}
                                autoSize={{minRows: 3, maxRows: 20}}
                                required
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </form>
    );

    return(
        <>
            <SEO
                title={faqID && faqID > 0 ? "Редактировать вопрос" : "Добавить вопрос"}
                description={faqID && faqID > 0 ? "Редактировать вопрос" : "Добавить вопрос"}
            />
            {faqID && faqID > 0 && faqStore.faq.id === 0 ? <LoadCart /> : element}
        </>
    )
});

export default Faq;