import { observer } from "mobx-react-lite";
// import userStore from "../../store/userStore";
import { useEffect } from "react"
import {Table, Col, Row, Typography, Pagination, Input} from "antd";
import SEO from '@americanexpress/react-seo';
import LoadList from "../LoadList";

import user_controller from './user.controller'

const UserList = observer(() => {

    useEffect(() => {
        user_controller.getUsers()
    }, [])

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            width: '40px'
        },
        {
            title: 'Логин',
            dataIndex: 'login',
            render: (text, row) => (
                <>
                    <a href={"/users/" + row.id}>{text}</a>
                </>
            )
        },
        {
            title: 'Имя',
            dataIndex: 'firstname',
        }
    ];

    const list = (
        <Row className="products administration" justify="space-around" align="middle">
            <Col span={24} className="admHeading">
                <Typography.Title>Пользователи</Typography.Title>
            </Col>
            <Col span={24}>
                <Input
                    value={user_controller.filter}
                    onChange={(e) => user_controller.searchUsers(e.target.value)}
                    placeholder="Поиск пользователей по email"
                />
                <br/><br/>
            </Col>
            <Col span={24}>
                <Table columns={columns} dataSource={user_controller.list} size="small" pagination={false}/>
                {user_controller.count > 0 &&
                <>
                    <br/>
                    <Pagination
                        onChange={e => user_controller.setNow(e)}
                        current={user_controller.now}
                        total={user_controller.count}
                        defaultPageSize={user_controller.limit}
                        showSizeChanger={false}
                    />
                </>
                }
            </Col>
        </Row>
    );

    return(
        <>
            <SEO
                title="Список пользователей"
                description="Список пользователей приложения"
            />
            {user_controller.count > -1 ? list : <LoadList />}
        </>
    )
});

export default UserList;