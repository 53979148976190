import { makeAutoObservable } from "mobx"
import axios from "./axios"
import config from "../config.json";

class searchStore {

    list = []
    list_two = []

    constructor() {
        makeAutoObservable(this);
    }

    getAuthor(name) {
        if(name !== "") {
            axios.get("/author/author?filter[name]=" + name)
                .then(success => {
                    this.list = success.data.items;
                })
                .catch(text => console.log(text.response));
        } else {
            this.list = [];
        }
    }

    getCategoryPracticle(name) {
        console.log(name)
        if(name !== "") {
            axios.get("/practice/category?filter[name]=" + name)
                .then(success => {
                    console.log(success);
                    this.list_two = success.data.items;
                })
                .catch(text => console.log(text.response));
        } else {
            this.list_two = [];
        }
    }

    getCategoryArticle(name) {
        if(name !== "") {
            axios.get("/article/category?filter[name]=" + name)
                .then(success => {
                    console.log(success);
                    this.list_two = success.data.items;
                })
                .catch(text => console.log(text.response));
        } else {
            this.list_two = [];
        }
    }

    getCategoryProduct(name) {
        if(name !== "") {
            axios.get("/product/category?filter[name]=" + name)
                .then(success => {
                    console.log(success);
                    this.list = success.data.items;
                })
                .catch(text => console.log(text.response));
        } else {
            this.list = [];
        }
    }

    getCategoryRecipe(name) {
        if(name !== "") {
            axios.get("/recipe/category?filter[name]=" + name)
                .then(success => {
                    console.log(success);
                    this.list = success.data.items;
                })
                .catch(text => console.log(text.response));
        } else {
            this.list = [];
        }
    }

    getPracticle(name) {
        if(name !== "") {
            axios.get("/practice/practice?filter[name]=" + name)
                .then(success => {
                    console.log(success);
                    this.list = success.data.items;
                })
                .catch(text => console.log(text.response));
        } else {
            this.list = [];
        }
    }

    getLessons(name) {
        if(name !== "") {
            axios.get("/practice/lesson?filter[name]=" + name)
                .then(success => {
                    console.log(success);
                    this.list = success.data.items;
                })
                .catch(text => console.log(text.response));
        } else {
            this.list = [];
        }
    }

    getByID(type, id, list, categoryType) {
        console.log("getByID");
        if(id > 0) {
            console.log("getByID " + id);
            if(type === "author") {
                axios.get("/author/author/" + id).then(success => this[list] = [success.data]);
            } else if(type === "category") {
                axios.get("/" + categoryType + "/category/" + id).then(success => this[list] = [success.data]);
            } else if(type === "practice") {
                axios.get("/practice/practice/" + id).then(success => this[list] = [success.data]);
            } else if(type === "lesson") {
                axios.get("/practice/lesson/" + id).then(success => this[list] = [success.data]);
            }
        }
    }

}

export default new searchStore();