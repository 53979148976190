import SignIn from "./components/auth/SignIn";
import AdminList from "./components/user/AdminList";
import Admin from "./components/user/Admin";
import AuthorList from "./components/author/AuthorList";
import Author from "./components/author/Author";
import ArticlesList from "./components/articles/ArticlesList";
import Article from "./components/articles/Article";
import CategoryList from "./components/category/CategoryList";
import Category from "./components/category/Category";
import User from "./components/user/User";
import UserList from "./components/user/UserList";
import PracticeList from "./components/practices/PracticeList";
import Practice from "./components/practices/Practice";
import LessonList from "./components/practices/LessonList";
import Lesson from "./components/practices/Lesson";
import ExerciseList from "./components/practices/ExerciseList";
import Exercise from "./components/practices/Exercise";
import RecipeList from "./components/recipes/RecipeList";
import Recipe from "./components/recipes/Recipe";
import Product from "./components/shop/Product";
import ProductList from "./components/shop/ProductList";
import Event from "./components/event/Event";
import EventList from "./components/event/EventList";
import Music from "./components/music/Music";
import MusicList from "./components/music/MusicList";
import Faq from "./components/articles/Faq";
import FaqList from "./components/articles/FaqList";
import SendPush from "./components/push/SendPush";
import VisualEditor from "./components/VisualEditor";
import TestEditor from "./components/TestEditor";
import Setting from "./components/setting/Setting";
import {CardDay, CardDayList} from './components/card_day/CardDay';
import {PushesList} from './components/push/PushesList';
import {Purchases, PurchasesSubscriptions} from './components/purchases/Purchases';

import { createBrowserRouter } from 'react-router-dom'

const RoutesList = createBrowserRouter([
    { path: '/', element: <PracticeList /> },
    { path: '/signin', element: <SignIn /> },
    { path: '/users/administrators', element: <AdminList /> },
    { path: '/users/administrators/:adminID', element: <Admin /> },
    { path: '/articles', element: <ArticlesList /> },
    { path: '/articles/:articleID', element: <Article /> },
    { path: '/category/:catType', element: <CategoryList /> },
    { path: '/category/:catType/:catID', element: <Category /> },
    { path: '/authors', element: <AuthorList /> },
    { path: '/authors/:authorID', element: <Author /> },
    { path: '/users', element: <UserList /> },
    { path: '/users/:userID', element: <User /> },
    { path: '/practices', element: <PracticeList /> },
    { path: '/practices/practice/:practiceID', element: <Practice /> },
    { path: '/practices/lessons', element: <LessonList /> },
    { path: '/practices/lesson/:lessonID', element: <Lesson /> },
    { path: '/practices/exercises', element: <ExerciseList /> },
    { path: '/practices/exercise/:exerciseID', element: <Exercise /> },
    { path: '/recipes', element: <RecipeList /> },
    { path: '/recipes/:recipeID', element: <Recipe/> },
    { path: '/shop/products', element: <ProductList /> },
    { path: '/shop/product/:productID', element: <Product /> },
    { path: '/events', element: <EventList /> },
    { path: '/events/:eventID', element: <Event /> },
    { path: '/music', element: <MusicList /> },
    { path: '/music/:musicID', element: <Music /> },
    { path: '/faq', element: <FaqList /> },
    { path: '/faq/:faqID', element: <Faq /> },
    { path: '/pushes', element: <PushesList /> },
    { path: '/pushes/send', element: <SendPush /> },
    { path: '/editor', element: <TestEditor /> },
    { path: '/setting', element: <Setting /> },
    { path: '/cards', element: <CardDayList /> },
    { path: '/cards/:cardId', element: <CardDay /> },
    { path: '/purchases', element: <Purchases /> },
    { path: '/purchases/subscriptions', element: <PurchasesSubscriptions /> },
])

// const Routes = {
//     "/signin": () => <SignIn/>,
//     "/users/administrators": () => <AdminList/>,
//     "/users/administrators/:adminID": ({adminID}) => <Admin adminID={adminID}/>,
//     "/articles": () => <ArticlesList/>,
//     "/articles/:articleID": ({articleID}) => <Article articleID={articleID}/>,
//     "/category/:catType": ({catType}) => <CategoryList catType={catType}/>,
//     "/category/:catType/:catID": ({catType, catID}) => <Category catType={catType} catID={catID}/>,
//     "/authors": () => <AuthorList/>,
//     "/authors/:authorID": ({authorID}) => <Author authorID={authorID}/>,
//     "/users": () => <UserList/>,
//     "/users/:userID": ({userID}) => <User userID={userID}/>,
//     "/practices": () => <PracticeList/>,
//     "/practices/practice/:practiceID": ({practiceID}) => <Practice practiceID={practiceID}/>,
//     "/practices/lessons": () => <LessonList/>,
//     "/practices/lesson/:lessonID": ({lessonID}) => <Lesson lessonID={lessonID}/>,
//     "/practices/exercises": () => <ExerciseList/>,
//     "/practices/exercise/:exerciseID": ({exerciseID}) => <Exercise exerciseID={exerciseID}/>,
//     "/recipes": () => <RecipeList/>,
//     "/recipes/:recipeID": ({recipeID}) => <Recipe recipeID={recipeID}/>,
//     "/shop/products": () => <ProductList/>,
//     "/shop/product/:productID": ({productID}) => <Product productID={productID}/>,
//     "/events": () => <EventList/>,
//     "/events/:eventID": ({eventID}) => <Event eventID={eventID}/>,
//     "/music": () => <MusicList/>,
//     "/music/:musicID": ({musicID}) => <Music musicID={musicID}/>,
//     "/faq": () => <FaqList/>,
//     "/faq/:faqID": ({faqID}) => <Faq faqID={faqID}/>,
//     "/pushes": () => <PushesList />,
//     "/pushes/send": () => <SendPush />,
//     "/editor": () => <TestEditor />,
//     "/setting": () => <Setting />,
//     "/cards": () => <CardDayList />,
//     "/cards/:cardId": ({ cardId }) => <CardDay cardId={cardId} />,
//     "/purchases": () => <Purchases />,
//     "/purchases/subscriptions": () => <PurchasesSubscriptions />,
// }

export default RoutesList;