import { makeAutoObservable } from "mobx";
import axios from "./axios";
import store from "store";
import {message} from "antd";
import sha1 from "js-sha1";

class AuthStore {

    user = {
        login: "",
        password: ""
    }

    constructor() {
        makeAutoObservable(this);
    }

    onChange(name, value) {
        this.user[name] = value;
    }

    LogIn(e) {
        e.preventDefault();
        let facts = new FormData();
        facts.append("email", this.user.login);
        facts.append("password", sha1(this.user.password));
        axios.post("/admin/user", facts)
            .then(success => {
                console.log(success)
                store.set("user", success.data);
                document.location.href = "/";
            })
            .catch(error => {
                console.log(error)
                message.error("Неправильный логин или пароль.")
            });
    }

}

export default new AuthStore();