import { observer } from "mobx-react-lite";
import React, { useEffect } from "react"
import { Row, Col, Input, Button, Typography, Select, Checkbox, Upload, Popconfirm, Tooltip, Space } from "antd";
import { DeleteOutlined, ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Editor } from 'primereact/editor';
import articleStore from "../../store/articleStore";
import authorStore from "../../store/authorStore";
import config from "../../config.json";
import uploadStore from "../../store/uploadStore";
import categoryStore from "../../store/categoryStore";
import SEO from "@americanexpress/react-seo";
import LoadCart from "../LoadCart";
import searchStore from "../../store/searchStore";
import VisualEditor from "../VisualEditor";
import practiceStore from '../../store/practiceStore';
import {useParams} from 'react-router';
const { Option } = Select;

const Article = observer((props) => {

    const { articleID } = useParams()
    
    useEffect(() => {
        if(articleID && articleID > 0){
            articleStore.getArticle(articleID);
        }
        authorStore.getListAuthor();
        categoryStore.setType("article");
        categoryStore.getAllCategory();


    }, []);

    const propsUpload = {
        name: 'file',
        action: config.apiUrl + '/upload/upload',
        headers: {
            "Type-Device": config.typeDevice
        },
        data: {
            path: uploadStore.file.type + "/article/" + uploadStore.getPathDate(),
            type: uploadStore.file.type
        },
        onChange(info) {
            console.log(info);
            let photo = uploadStore.onChangeFile(info);
            if(typeof(photo) === "object") {
                articleStore.setPhoto(photo);
            }

        },
    };

    const editText = (e) => {
        articleStore.onChange("text", e)
    }

    const ref = React.createRef();

    const element = (
        <form onSubmit={e => articleStore.sendForm(e)}>
            <Row className="articles administration" justify="space-around" align="middle">
                <Col span={16} className="admHeading">
                    <Typography.Title>
                        {articleID && articleID > 0 ? "Редактировать статью" : "Добавить статью"}
                    </Typography.Title>
                </Col>
                <Col span={8} align="right">
                    <Space>
                        <Button type="dashed" href="/articles">К списку</Button>
                        <Button htmlType="submit" type="primary">Сохранить</Button>
                    </Space>
                </Col>
                <Col span={24}>
                    <Row gutter={[15, 30]}>
                        <Col xs={24} lg={6} className="visibility">
                            <Checkbox
                                checked={articleStore.article.view}
                                onChange={(e) => articleStore.onChange("view", Number(e.target.checked)) }
                            >
                                Статья активна
                            </Checkbox>
                        </Col>
                        <Col xs={24} lg={18}>
                            <Checkbox
                                checked={articleStore.article.viewmain}
                                onChange={(e) => articleStore.onChange("viewmain", Number(e.target.checked)) }
                            >
                                Показывать на главной
                            </Checkbox>
                        </Col>
                        <Col span={6}>
                            <Checkbox
                                onChange={e => articleStore.onChange("ru", e.target.checked === true ? 1 : 0)}
                                checked={articleStore.article.ru}
                            >
                                Показывать на РУ
                            </Checkbox>
                        </Col>
                        <Col span={6}>
                            <Checkbox
                                onChange={e => articleStore.onChange("en", e.target.checked === true ? 1 : 0)}
                                checked={articleStore.article.en}
                            >
                                Показывать на EN
                            </Checkbox>
                        </Col>
                        <Col xs={12}>
                            <Typography.Title level={5}>Название</Typography.Title>
                            <Input
                                onChange={e => articleStore.onChange("name", e.target.value)}
                                value={articleStore.article.name}
                                required
                            />
                        </Col>
                        <Col xs={12}>
                            <Typography.Title level={5}>Краткое описание</Typography.Title>
                            <Input
                                onChange={e => articleStore.onChange("subtitle", e.target.value)}
                                value={articleStore.article.subtitle}
                                required
                            />
                        </Col>
                        <Col xs={24} lg={8}>
                            <Typography.Title level={5}>Автор</Typography.Title>
                            <Select
                                name="author"
                                showSearch
                                style={{ width: 250 }}
                                placeholder="Искать"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                                onChange={
                                    e => articleStore.onChange("author", e)
                                }
                                onSearch={e => searchStore.getAuthor(e)}
                                value={articleStore.article.author}
                            >
                                {searchStore.list.map((author) => (
                                    <Option value={author.id}>{author.name}</Option>
                                ))}
                            </Select>
                        </Col>
                        <Col xs={24} lg={8}>
                            <Typography.Title level={5}>Категория</Typography.Title>
                            <Select
                                name="categoryid"
                                showSearch
                                style={{ width: 250 }}
                                placeholder="Искать"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                                onChange={
                                    e => articleStore.onChange("categoryid", e)
                                }
                                onSearch={e => searchStore.getCategoryArticle(e)}
                                value={articleStore.article.categoryid}
                            >
                                {searchStore.list_two.map((cat) => (
                                    <Option value={cat.id}>{cat.name}</Option>
                                ))}
                            </Select>
                        </Col>
                        <Col xs={24} lg={8} />

                        <Col xs={24} lg={8} className="visibility">
                            {typeof(articleStore.article.date) !== "undefined" && (
                                <p>Дата публикации: <strong>{articleStore.getArticleDate(articleStore.article.date)}</strong></p>
                            )}
                        </Col>
                        <Col xs={24}>
                            <Typography.Title level={5}>Фото</Typography.Title>
                            {articleStore.article.photo.url &&
                            <div className="forImage">
                                <img src={articleStore.article.photo.url} width={100 + "%"} alt="" />
                                <Tooltip placement="bottom" title="Удалить изображение">
                                    <Popconfirm
                                        placement="left"
                                        title="Удалить изображение?"
                                        onConfirm={() => articleStore.deleteImage()}
                                        okText="Да"
                                        cancelText="Нет"
                                        icon={<ExclamationCircleOutlined style={{ color: "red" }} />}
                                    >
                                        <Button danger shape="circle" icon={<DeleteOutlined />} className="delete" type="dashed" />
                                    </Popconfirm>
                                </Tooltip>
                            </div>
                            }
                            <Upload
                                {...propsUpload}
                                listType="picture-card"
                                className="photo-uploader"
                                maxCount={1}
                            >
                                <div>
                                    <PlusOutlined />
                                    <div style={{ marginTop: 8 }}>Загрузить</div>
                                </div>
                            </Upload>
                        </Col>
                        <Col xs={24} lg={24}>
                            <Typography.Title level={5}>Текст</Typography.Title>
                            {/*<Editor*/}
                            {/*    style={{height:"400px"}}*/}
                            {/*    value={articleStore.article.text2}*/}
                            {/*/>*/}

                            <VisualEditor
                                value={articleStore.article.text}
                                updateData={editText}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </form>
    );

    return(
        <>
            <SEO
                title={articleID && articleID > 0 ? "Редактировать статью" : "Добавить статью"}
            />
            {articleID && articleID > 0 && articleStore.article.id === 0 ? <LoadCart /> : element}
        </>
    )
});

export default Article;