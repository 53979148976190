import { observer } from "mobx-react-lite"
import {Row, Col, Typography, Table, Pagination, Space, Tooltip, Button, Radio} from 'antd';
import PushController from './push.controller'
import React, {useEffect} from 'react';
import {SelectOutlined, DeleteOutlined} from '@ant-design/icons';
import user_controller from '../user/user.controller';
import moment from 'moment';
import CardController from '../card_day/card.controller';

export const PushesList = observer(() => {

    useEffect(() => {
        PushController.getList()
    }, [])

    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            width: "40px",
            align: "center",
        },
        {
            title: "Заголовок",
            dataIndex: "title",
        },
        {
            title: "Создано",
            dataIndex: "created_at",
            render: (data) => moment(data).format('LLL')
        },
        {
            title: "Получатели",
            dataIndex: "devices",
        },
        {
            title: "Описание",
            dataIndex: "description",
        },
        {
            title: "Ссылка на сущность",
            dataIndex: "element_type",
        },
        {
            title: "ID сущности",
            dataIndex: "element_id",
        },
        {
            title: "Дата/Время отправки",
            dataIndex: "postpone_to",
            render: (data) => moment(data * 1000).format('LLL')
        },
        {
            title: "Отправлено",
            dataIndex: "sent",
            render: (text) => text ? 'Да' : 'Нет'
        },
        {
            title: "Доставлено",
            dataIndex: "count_sent",
        },
        {
            title: "Действия",
            dataIndex: "acts",
            width: "80px",
            render: (text, row) => (
                <>
                    <Space>
                        <Tooltip placement="bottom" title="Удалить">
                            <Button
                                type="dashed"
                                shape="circle"
                                icon={<DeleteOutlined />}
                                onClick={() => PushController.delete(row.id)}
                                danger
                            />
                        </Tooltip>
                    </Space>
                </>
            )
        }
    ]

    return(
        <>
            <Row className="recipes administration" justify="space-around" align="middle">
                <Col span={24} className="admHeading">
                    <Typography.Title>Список push-уведомлений</Typography.Title>
                </Col>
                <Col span={24}>
                    <Space>
                        <Radio.Group size="small" onChange={(e) => PushController.setFilter('sent', e.target.value)} value={PushController.filter.sent}>
                            <Radio.Button value={null}>Все</Radio.Button>
                            <Radio.Button value='true'>Отправлены</Radio.Button>
                            <Radio.Button value='false'>Не отправлены</Radio.Button>
                        </Radio.Group>
                        <Radio.Group size="small" onChange={(e) => PushController.setFilter('devices', e.target.value)} value={PushController.filter.devices}>
                            <Radio.Button value={null}>Все</Radio.Button>
                            <Radio.Button value="ios">IOS</Radio.Button>
                            <Radio.Button value="android">Android</Radio.Button>
                        </Radio.Group>
                    </Space>
                    <br/><br/>
                </Col>
                <Col span={24}>
                    <Table columns={columns} dataSource={PushController.list} size="small" pagination={false}/>
                    <br/>
                    <Pagination
                        onChange={e => PushController.setNow(e)}
                        current={PushController.now}
                        total={PushController.count}
                        defaultPageSize={PushController.limit}
                        showSizeChanger={false}
                    />
                </Col>
            </Row>
        </>
    )
})