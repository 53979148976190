import { observer } from "mobx-react-lite";
import React, { useEffect } from "react"
import { Row, Col, Input, Button, Typography, Upload, Select, Space } from "antd";
import {DeleteOutlined, PauseCircleOutlined, PlayCircleOutlined, PlusOutlined} from '@ant-design/icons';
import exerciseStore from "../../store/exerciseStore";
import config from "../../config.json";
import uploadStore from "../../store/uploadStore";
import lessonStore from "../../store/lessonStore";
import SEO from "@americanexpress/react-seo";
import LoadCart from "../LoadCart";
import searchStore from "../../store/searchStore";
import VisualEditor from "../VisualEditor";
import musicStore from "../../store/musicStore";
import {useParams} from 'react-router';
const { Option } = Select;

const Exercise = observer((props) => {

    const { exerciseID } = useParams()
    
    useEffect(() => {
        if(exerciseID && exerciseID > 0){
            exerciseStore.getExercise(exerciseID);
        }
        lessonStore.getAllLesson();
    }, []);

    const propsUpload = {
        name: 'file',
        action: config.apiUrl + '/upload/upload',
        headers: {
            "Type-Device": config.typeDevice
        },
        data: {
            path: uploadStore.file.type + "/practice/exercise/" + uploadStore.getPathDate(),
            type: uploadStore.file.type
        },
        onChange(info) {
            let photo = uploadStore.onChangeFile(info);
            if(typeof(photo) === "object") {
                exerciseStore.setPhoto(photo);
            }

        },
    };

    const propsAudioUpload = {
        name: 'file',
        action: config.apiUrl + '/upload/upload',
        headers: {
            "Type-Device": config.typeDevice
        },
        data: {
            path: "music/practice/exercise/" + uploadStore.getPathDate(),
            type: "music"
        },
        onChange(info) {
            let audio = uploadStore.onChangeFile(info);
            if(typeof(audio) === "object") {
                exerciseStore.setAudio(audio);
            }
        },
    };

    const propsVideoUpload = {
        name: 'file',
        action: config.apiUrl + '/upload/upload',
        headers: {
            "Type-Device": config.typeDevice
        },
        data: {
            path: "video/practice/exercise/" + uploadStore.getPathDate(),
            type: "video"
        },
        onChange(info) {
            let video = uploadStore.onChangeFile(info);
            if(typeof(video) === "object") {
                exerciseStore.setVideo(video);
            }
        },
    };

    const editText = (e) => {
        exerciseStore.onChange("text", e)
    }

    const element = (
        <form onSubmit={e => exerciseStore.sendForm(e)}>
            <Row className="exercises administration" justify="space-around" align="middle">
                <Col span={16} className="admHeading">
                    <Typography.Title>
                        {exerciseID && exerciseID > 0 ? "Редактировать упражнение" : "Добавить упражнение"}
                    </Typography.Title>
                </Col>
                <Col span={8} align="right">
                    <Space>
                        <Button type="dashed" href="/practices/exercises">К списку</Button>
                        <Button htmlType="submit" type="primary">Сохранить</Button>
                    </Space>
                </Col>
                <Col span={24}>
                    <Row gutter={[15, 30]}>
                        <Col xs={24} lg={24}>
                            <Typography.Title level={5}>Название</Typography.Title>
                            <Input
                                onChange={e => exerciseStore.onChange("name", e.target.value)}
                                value={exerciseStore.exercise.name}
                                required
                            />
                        </Col>
                        <Col xs={24} lg={24}>
                            <Typography.Title level={5}>Комментарий (Не показывается в мобильном приложении)</Typography.Title>
                            <Input
                                onChange={e => exerciseStore.onChange("comment", e.target.value)}
                                value={exerciseStore.exercise.comment}
                                required
                            />
                        </Col>
                        <Col xs={24}>
                            <Typography.Title level={5}>Описание</Typography.Title>
                            <VisualEditor
                                value={exerciseStore.exercise.text}
                                updateData={editText}
                            />
                        </Col>
                        <Col xs={24} lg={8}>
                            <Typography.Title level={5}>Урок</Typography.Title>
                            <Select
                                name="lessonid"
                                showSearch
                                style={{ width: 250 }}
                                placeholder="Искать"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                                onChange={
                                    e => exerciseStore.onChange("lessonid", e)
                                }
                                onSearch={e => searchStore.getLessons(e)}
                                value={exerciseStore.exercise.lessonid}
                            >
                                {searchStore.list.map((lesson) => (
                                    <Option value={lesson.id}>{lesson.name}</Option>
                                ))}
                            </Select>
                        </Col>
                        <Col xs={24}>
                            <Typography.Title level={5}>Фото</Typography.Title>
                            {exerciseStore.exercise.photo.url &&
                            <div className="forImage">
                                <Button
                                    className="delete"
                                    danger
                                    type="dashed"
                                    shape="circle"
                                    icon={<DeleteOutlined />}
                                    onClick={() => exerciseStore.deleteImage()}
                                />
                                <img src={exerciseStore.exercise.photo.url} width={100 + "%"} alt=""/>
                            </div>
                            }
                            <Upload
                                {...propsUpload}
                                listType="picture-card"
                                className="photo-uploader"
                                maxCount={1}
                            >
                                <div>
                                    <PlusOutlined />
                                    <div style={{ marginTop: 8 }}>Загрузить</div>
                                </div>
                            </Upload>
                        </Col>
                        <Col xs={24}>
                            <Typography.Title level={5}>Аудио</Typography.Title>
                            {exerciseStore.exercise.audio !== "" &&
                            <Space>
                                <Button
                                    shape="circle"
                                    onClick={() => musicStore.playMusic(exerciseStore.exercise.audio)}
                                    type="dashed"
                                    icon={musicStore.status ? <PauseCircleOutlined /> : <PlayCircleOutlined />}
                                />
                                <Button
                                    danger
                                    type="dashed"
                                    shape="circle"
                                    icon={<DeleteOutlined />}
                                    onClick={() => exerciseStore.deleteMusic(exerciseStore.exercise.audio)}
                                />
                            </Space>
                            }
                            <Upload
                                {...propsAudioUpload}
                                listType="picture-card"
                                className="photo-uploader"
                                maxCount={1}
                            >
                                <div>
                                    <PlusOutlined />
                                    <div style={{ marginTop: 8 }}>Загрузить</div>
                                </div>
                            </Upload>
                        </Col>
                        <Col xs={24}>
                            <Typography.Title level={5}>Видео</Typography.Title>
                            {exerciseStore.exercise.video !== "" &&
                            <div className="forImage">
                                <Button
                                    danger
                                    type="dashed"
                                    shape="circle"
                                    className="delete"
                                    icon={<DeleteOutlined />}
                                    onClick={() => exerciseStore.deleteVideo(exerciseStore.exercise.video)}
                                />
                                <video className="video" controls>
                                    <source src={exerciseStore.exercise.video} />
                                </video>
                            </div>
                            }
                            <Upload
                                {...propsVideoUpload}
                                listType="picture-card"
                                className="photo-uploader"
                                maxCount={1}
                            >
                                <div>
                                    <PlusOutlined />
                                    <div style={{ marginTop: 8 }}>Загрузить</div>
                                </div>
                            </Upload>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </form>
    );

    return(
        <>
            <SEO
                title={exerciseID && exerciseID > 0 ? "Редактировать упражнение" : "Добавить упражнение"}
            />
            {exerciseID && exerciseID > 0 && exerciseStore.exercise.id === 0 ? <LoadCart /> : element}
        </>
    )
});

export default Exercise;