import { observer } from "mobx-react-lite"
import { Button, Checkbox, Col, Input, InputNumber, Popconfirm, Row, Select, Space, Typography, DatePicker } from "antd"
import moment from 'moment'
import { ExclamationCircleOutlined } from "@ant-design/icons"
import PushController from './push.controller'

const SendPush = observer(() => {

    return(
        <form onSubmit={e => PushController.createNotification(e)}>
            <Row className="recipes administration" justify="space-around" align="middle">
                <Col span={16} className="admHeading">
                    <Typography.Title>Отправка push-уведомления</Typography.Title>
                </Col>
                <Col span={8} align="right">
                    <Button type="primary" htmlType="submit">Отправить</Button>
                </Col>
                <Col span={24}>
                    <Row gutter={[15, 30]}>
                        <Col xs={24}>
                            <Typography.Title level={5}>Отложенная отправка</Typography.Title>
                            <Checkbox onChange={() => PushController.setPostpone()}>Включить отложенную отправку</Checkbox>
                            {PushController.postpone &&
                                <div>
                                    <DatePicker
                                        format="YYYY-MM-DD HH"
                                        onChange={(e, d) => PushController.changeElement('postpone_to', e)}
                                        showTime={{ defaultValue: moment('00:00', 'HH') }}
                                    />
                                </div>
                            }
                        </Col>
                        <Col xs={24}>
                            <Typography.Title level={5}>Заголовок</Typography.Title>
                            <Input
                                onChange={e => PushController.changeElement("title", e.target.value)}
                                value={PushController.element.title}
                                required
                            />
                        </Col>
                        <Col xs={24}>
                            <Typography.Title level={5}>Текст</Typography.Title>
                            <Input
                                onChange={e => PushController.changeElement("description", e.target.value)}
                                value={PushController.element.description}
                                required
                            />
                        </Col>
                        <Col xs={24}>
                            <Typography.Title level={5}>Тип устройства</Typography.Title>
                            <Select
                                style={{ width: 250 }}
                                placeholder="Выберите тип устройства"
                                onChange={
                                    e => PushController.changeElement("devices", e)
                                }
                                value={PushController.element.devices}
                                required
                            >
                                <Select.Option value="all">Всем</Select.Option>
                                <Select.Option value="ios">IOS</Select.Option>
                                <Select.Option value="android">Android</Select.Option>
                            </Select>
                        </Col>
                        <Col xs={24}>
                            <Typography.Title level={5}>Тип перехода</Typography.Title>
                            <Select
                                style={{ width: 250 }}
                                placeholder="Выберите тип перехода"
                                onChange={
                                    e => PushController.changeElement("element_type", e)
                                }
                                value={PushController.element.element_type}
                            >
                                <Select.Option value={"lesson"}>Урок</Select.Option>
                                <Select.Option value={"article"}>Статья</Select.Option>
                                <Select.Option value={"product"}>Товар</Select.Option>
                                <Select.Option value={"recipe"}>Рецепт</Select.Option>
                                <Select.Option value={"event"}>Событие</Select.Option>
                            </Select>
                        </Col>
                        <Col xs={24}>
                            <Typography.Title level={5}>ID элемента</Typography.Title>
                            <InputNumber
                                onChange={e => PushController.changeElement("element_id", e)}
                                value={PushController.element.element_id}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </form>
    )
});

export default SendPush;