import { observer } from "mobx-react-lite"
import { useEffect } from "react"
import { Row, Col, Button, Typography, Table, Space, Popconfirm, Tooltip, Pagination } from "antd";
import { EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import articleStore from "../../store/articleStore";
import SEO from "@americanexpress/react-seo";
import LoadList from "../LoadList";

const ArticlesList = observer(() => {
    useEffect(() => {
        articleStore.getAllArticle();
    }, []);

    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            width: "40px",
            align: "center",
        },
        {
            title: "Дата публикации",
            dataIndex: "date",
            width: "160px",
            align: "center",
            render: (text) => (articleStore.getArticleDate(text))
        },
        {
            title: "Название",
            dataIndex: "name",
        },
        {
            title: "Автор",
            dataIndex: "author",
            render: (text) => (text.name)
        },
        {
            title: "Действия",
            dataIndex: "acts",
            width: "80px",
            render: (text, row) => (
                <>
                    <Space>
                        <Button shape="circle" href={"/articles/" + row.id} type="dashed" icon={<EditOutlined />} />
                        <Tooltip placement="bottom" title="Удалить">
                            <Popconfirm
                                placement="left"
                                title="Вы уверены?"
                                onConfirm={() => articleStore.deleteArticle(row.id)}
                                okText="Да"
                                cancelText="Нет"
                                icon={<ExclamationCircleOutlined style={{ color: "red" }} />}
                            >
                                <Button danger type="dashed" shape="circle" icon={<DeleteOutlined />} />
                            </Popconfirm>
                        </Tooltip>
                    </Space>
                </>
            )
        }
    ];

    const list = (
        <Row className="articles administration" justify="space-around" align="middle">
            <Col span={20} className="admHeading">
                <Typography.Title>Полезные статьи</Typography.Title>
            </Col>
            <Col span={4} align="right">
                <Button type="primary" href={"/articles/0"}>Добавить</Button>
            </Col>
            <Col span={24}>
                <Table columns={columns} dataSource={articleStore.list} size="small" pagination={false}/>
                {articleStore.count > 0 &&
                    <>
                        <br/>
                        <Pagination
                            onChange={e => articleStore.setNow(e)}
                            current={articleStore.now}
                            total={articleStore.count}
                            defaultPageSize={articleStore.limit}
                        />
                    </>
                }

            </Col>
        </Row>
    );

    return(
        <>
            <SEO
                title="Полезные статьи"
            />
            {articleStore.count > -1 ? list : <LoadList />}
        </>
    )
});

export default ArticlesList;