import { Row, Col, Button, Menu, Tooltip, Select, Space } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import logo from "../img/logo.svg"
import adminStore from "../store/adminStore";
import langController from '../store/lang'

const HeaderMenu = () => {
    let url = window.location.href;
    url = url.split('/')
    if(url[3] === "category") {
        url[3] = url[4];
    } else if(url[4] && url[4] === "administrators") {
        url[3] = url[4];
    } else if(url[4] && (url[4] === "lessons" || url[4] === "exercises")) {
        url[3] = url[4];
    }
    console.log(url);

    return(
        <>
            <Menu mode="horizontal" selectedKeys={[url[3]]}>
                <Menu.SubMenu key="article" title="Статьи">
                    <Menu.Item key="article"><a href="/category/article">Категории статей</a></Menu.Item>
                    <Menu.Item key="articles"><a href="/articles">Статьи</a></Menu.Item>
                </Menu.SubMenu>
                <Menu.SubMenu key="users" title="Пользователи">
                    <Menu.Item key="users"><a href="/users">Пользователи</a></Menu.Item>
                    <Menu.Item key="administrators"><a href="/users/administrators">Администраторы</a></Menu.Item>
                </Menu.SubMenu>
                <Menu.SubMenu key="practice" title="Практики">
                    <Menu.Item key="practices"><a href="/practices">Практики</a></Menu.Item>
                    <Menu.Item key="practice"><a href="/category/practice">Категории практик</a></Menu.Item>
                    <Menu.Item key="lessons"><a href="/practices/lessons">Уроки</a></Menu.Item>
                    <Menu.Item key="exercises"><a href="/practices/exercises">Упражнения</a></Menu.Item>
                </Menu.SubMenu>
                <Menu.SubMenu key="shop" title="Магазин">
                    <Menu.Item key="shop"><a href="/shop/products">Товары</a></Menu.Item>
                    <Menu.Item key="product"><a href="/category/product">Категории товаров</a></Menu.Item>
                </Menu.SubMenu>
                <Menu.SubMenu key="recipe" title="Рецепты">
                    <Menu.Item key="recipe"><a href="/category/recipe">Категории рецептов</a></Menu.Item>
                    <Menu.Item key="recipes"><a href="/recipes">Рецепты</a></Menu.Item>
                </Menu.SubMenu>
                <Menu.Item key="cards"><a href="/cards">Карты дня</a></Menu.Item>
                <Menu.Item key="authors"><a href="/authors">Авторы</a></Menu.Item>
                <Menu.Item key="events"><a href="/events">События</a></Menu.Item>
                <Menu.Item key="music"><a href="/music">Музыка</a></Menu.Item>
                <Menu.Item key="faq"><a href="/faq">FAQ</a></Menu.Item>
                <Menu.SubMenu key="pushes" title="Push - уведомления">
                    <Menu.Item key="pushes"><a href="/pushes">Список push - уведомлений</a></Menu.Item>
                    <Menu.Item key="pushes_send"><a href="/pushes/send">Создание push - уведомления</a></Menu.Item>
                </Menu.SubMenu>
                <Menu.SubMenu key="purchases" title="Выплаты">
                    <Menu.Item key="purchases"><a href="/purchases">Выплаты по покупкам</a></Menu.Item>
                    <Menu.Item key="purchases_subscription"><a href="/purchases/subscriptions">Выплаты по подпискам</a></Menu.Item>
                </Menu.SubMenu>
                <Menu.Item key="editor"><a href="/editor">Редактор</a></Menu.Item>
                <Menu.Item key="setting"><a href="/setting">Настройки</a></Menu.Item>
            </Menu>
        </>
    )
}

const HeaderPage = () => {
    return(
        <>
            <Row justify="space-around" align="middle" className="header">
                <Col span={18}>
                    <Row justify="space-around" align="middle">
                        <Col span={12} align="left" className="logo">
                            <a href="/"><img src={logo} alt="" /></a>
                        </Col>
                        <Col span={12} align="right">
                            <Space>
                                <Select value={langController.lang} style={{ width: 120 }} onChange={(e) => langController.onChangeLang(e)}>
                                    <Select.Option value="ru">Русский</Select.Option>
                                    <Select.Option value="en">Английский</Select.Option>
                                </Select>

                                <Tooltip title="Выйти">
                                    <Button type="dashed" shape="circle" onClick={adminStore.logout} icon={<LogoutOutlined />} />
                                </Tooltip>
                            </Space>
                        </Col>
                    </Row>
                </Col>
                <Col span={18}>
                    <HeaderMenu />
                </Col>
            </Row>
        </>
    )
}

export default HeaderPage;