import { makeAutoObservable } from "mobx"
import axios from "./axios"
import { message } from "antd"
import config from "../config.json"
import uploadStore from "./uploadStore";
import searchStore from "./searchStore";

class PracticeStore {

    practice = {
        id: 0,
        practiceid: 0,
        price_apple: 0,
        price_google: 0,
        photo: [],
        apple: "",
        android: "",
        level: "",
        text: [],
        viewmain: 0,
        typepay: null,
        ru: null,
        en: null
    };

    list = [];

    count = -1;
    now = 1;
    limit = config.limit;

    constructor() {
        makeAutoObservable(this);
    }

    setLimit(limit) {
        this.limit = limit
        this.now = 1
        this.getAllPractice();
    }

    onChange(name, value) {
        console.log(value)
        this.practice[name] = value;
    }

    getAllPractice() {
        axios.get("/practice/practice?limit=" + this.limit + "&page=" + this.now)
            .then(success => {
                console.log(success)
                this.list = success.data.items;
                this.count = Number(success.data.pagination[1]);
                this.now = Number(success.data.pagination[0]);
            })
            .catch(error => console.log(error));
    }

    deletePractice(practiceID) {
        axios.delete("/practice/practice/" + practiceID)
            .then(success => {
                this.getAllPractice();
            })
            .catch(error => console.log(error));
    }

    saveItem() {
        let facts = new FormData();
        facts.append("name", this.practice.name);
        facts.append("author", this.practice.author);
        facts.append("description", this.practice.description);
        facts.append("text", JSON.stringify(this.practice.text));
        facts.append("practiceid", this.practice.practiceid);
        facts.append("price_apple", this.practice.price_apple);
        facts.append("price_google", this.practice.price_google);
        facts.append("categoryid", this.practice.categoryid);
        facts.append("apple", this.practice.apple);
        facts.append("android", this.practice.android);
        facts.append("level", this.practice.level);

        facts.append("viewmain", this.practice.viewmain);
        facts.append("typepay", this.practice.typepay);

        facts.append("ru", this.practice.ru);
        facts.append("en", this.practice.en);

        if(typeof(this.practice.author) === "undefined") {
            return message.error('Не выбран автор.');
        }

        if(typeof(this.practice.categoryid) === "undefined") {
            return message.error('Не выбрана категория.');
        }

        facts.append("photo", this.practice.photo.length > 0 ? JSON.stringify(this.practice.photo) : "");

        let url
        if(this.practice.id > 0) {
            url = "/practice/practice/" + this.practice.id;
        } else {
            url = "/practice/practice"
        }

        axios.post(url, facts)
            .then(success => {
                if(this.practice.id > 0) {
                    message.success('Успешно');
                } else {
                    document.location.href = "/practices";
                }
            })
            .catch(error => console.log(error.response.data.message));
    }

    sendForm(e) {
        e.preventDefault();
        this.saveItem();
    }

    getPractice(practiceID) {
        axios.get("/practice/practice/" + practiceID)
            .then(response => {
                this.practice = {
                    ...this.practice,
                    id: response.data.id,
                    name: response.data.name,
                    level: response.data.level,
                    description: response.data.description,
                    text: Array.isArray(response.data.text) ? response.data.text : [],
                    text2: response.data.text2,
                    author: response.data.author.id,
                    categoryid: response.data.categoryid,
                    price_google: response.data.price_google,
                    price_apple: response.data.price_apple,
                    practiceid: response.data.practiceid,
                    photo: response.data.photo !== "" && response.data.photo !== null ? response.data.photo : [],
                    apple: response.data.apple,
                    android: response.data.android,
                    viewmain: response.data.viewmain,
                    typepay: response.data.typepay,
                    free: response.data.free,
                    ru: response.data.ru,
                    en: response.data.en
                }
                searchStore.getByID("author", this.practice.author, "list");
                searchStore.getByID("category", this.practice.categoryid, "list_two", "practice");
            })
            .catch(text => {
                message.error(text.response);
            });
    }

    setPhoto(photo) {
        this.practice.photo = [...this.practice.photo, photo];
    }

    setNow(page) {
        this.now = page;
        this.getAllPractice()
    }

    deleteImage(photo) {
        uploadStore.deleteFile(photo)
        try {
            this.practice.photo = this.practice.photo.filter(el => el.url !== photo);
        } catch {
            message.error("Ошибка удаления файла")
        }
        this.saveItem();
    }
}

export default new PracticeStore();