import { makeAutoObservable } from "mobx"
import axios from "./axios"
import { message } from "antd"
import uploadStore from "./uploadStore";
import config from "../config.json";
import searchStore from "./searchStore";

class ArticleStore {

    article = {
        id: 0,
        name: "",
        view: 1,
        photo: {},
        text: [],
        subtitle: "",
        ru: null,
        en: null
    };

    list = [];

    count = -1;
    now = 1;
    limit = config.limit;

    constructor() {
        makeAutoObservable(this);
    }

    onChange(name, value) {
        this.article[name] = value
    }

    getAllArticle() {
        axios.get("/article/article?limit=" + this.limit + "&page=" + this.now)
            .then(success => {
                this.list = success.data.items;
                this.count = Number(success.data.pagination[1]);
                this.now = Number(success.data.pagination[0]);
            })
            .catch(error => console.log(error));
    }

    deleteArticle(articleID) {
        axios.delete("/article/article/" + articleID)
            .then(success => {
                this.getAllArticle();
            })
            .catch(error => console.log(error));
    }

    saveArticle() {
        let facts = new FormData();
        facts.append("name", this.article.name);
        facts.append("text", JSON.stringify(this.article.text));
        facts.append("author", this.article.author);
        facts.append("view", this.article.view);
        facts.append("viewmain", this.article.viewmain);
        facts.append("categoryid", this.article.categoryid);
        facts.append("subtitle", this.article.subtitle);
        facts.append("ru", this.article.ru);
        facts.append("en", this.article.en);

        if(typeof(this.article.author) === "undefined") {
            return message.error('Не выбран автор.');
        }

        if(this.article.text === null) {
            return message.error("Введите текст статьи.");
        }

        facts.append("photo", this.article.photo.url ? JSON.stringify(this.article.photo) : "");

        if(typeof(this.article.categoryid) === "undefined") {
            return message.error("Не выбрана категория.");
        }

        let url
        if(this.article.id > 0) {
            url = "/article/article/" + this.article.id;
        } else {
            url = "/article/article"
        }
        axios.post(url, facts)
            .then(success => {
                console.log(success)
                if(this.article.id > 0) {
                    message.success('Успешно');
                } else {
                    document.location.href = "/articles";
                }
            })
            .catch(error => console.log(error.response.data.message));
    }

    sendForm(e) {
        e.preventDefault();
        this.saveArticle()
    }

    getArticle(articleID) {
        axios.get("/article/article/" + articleID)
            .then(response => {
                console.log(response.data);
                this.article = {
                    ...this.article,
                    id: response.data.id,
                    name: response.data.name,
                    text: Array.isArray(response.data.text) ? response.data.text : [],
                    text2: response.data.text2,
                    author: response.data.author.id,
                    categoryid: response.data.categoryid,
                    view: response.data.view,
                    viewmain: response.data.viewmain,
                    date: response.data.date,
                    subtitle: response.data.subtitle,
                    photo: response.data.photo !== "" && response.data.photo !== null ? response.data.photo : {},
                    ru: response.data.ru,
                    en: response.data.en,
                }
                searchStore.getByID("author", this.article.author, "list");
                searchStore.getByID("category", this.article.categoryid, "list_two", "article");
            })
            .catch(response => console.log(response));
    }

    getArticleDate(date) {
        date = new Date(date * 1000);
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        return day + "." + month + "." + year;
    }

    setPhoto(photo) {
        this.article.photo = photo;
    }

    deleteImage(url) {
        let facts = new FormData();
        facts.append("path", url);
        axios.post("/upload/delete/", facts)
            .then(success => {
                message.success("Изображение удалено");
                this.article.photo = null;
                this.saveArticle()
                this.getArticle(this.article.id);
            })
            .catch(error => message.error("Ошибка удаления файла"));
    }

    setNow(page) {
        this.now = page;
        this.getAllArticle()
    }

    deleteImage() {
        uploadStore.deleteFile(this.article.photo.url)
        this.article.photo = {};
    }
}

export default new ArticleStore();