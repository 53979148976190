import { observer } from "mobx-react-lite"
import { useEffect } from "react"
import { Row, Col, Button, Typography, Table, Space, Popconfirm, Tooltip, Pagination } from "antd";
import { EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import catsStore from "../../store/categoryStore";
import SEO from "@americanexpress/react-seo";
import LoadList from "../LoadList";
import {useParams} from 'react-router';

const CategoryList = observer((props) => {

    const { catType } = useParams()
    
    useEffect(() => {
        console.log(catType);
        catsStore.setType(catType);
        catsStore.getAllCategory();
    }, []);

    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            width: "40px",
            align: "center",
        },
        {
            title: "Название",
            dataIndex: "name",
        },
        {
            title: "Действия",
            dataIndex: "acts",
            width: "80px",
            render: (text, row) => (
                <>
                    <Space>
                        <Button shape="circle" type="dashed" href={"/category/" + catType + "/" + row.id} icon={<EditOutlined />} />
                        <Tooltip placement="bottom" title="Удалить">
                            <Popconfirm
                                placement="left"
                                title="Вы уверены?"
                                onConfirm={() => catsStore.deleteCategory(row.id)}
                                okText="Да"
                                cancelText="Нет"
                                icon={<ExclamationCircleOutlined style={{ color: "red" }} />}
                            >
                                <Button danger type="dashed" shape="circle" icon={<DeleteOutlined />} />
                            </Popconfirm>
                        </Tooltip>
                    </Space>
                </>
            )
        }
    ];

    const list = (
        <>
            <Row className="articles administration" justify="space-around" align="middle">
                <Col span={20} className="admHeading">
                    <Typography.Title>Категории
                        {catType === "articles" ? " статей" : ""}
                        {catType === "practice" ? " практик" : ""}
                        {catType === "product" ? " товаров" : ""}
                    </Typography.Title>
                </Col>
                <Col span={4} align="right">
                    <Button type="primary" href={"/category/" + catType + "/0"}>Добавить</Button>
                </Col>
                <Col span={24}>
                    <Table columns={columns} dataSource={catsStore.list} size="small" pagination={false}/>
                    {catsStore.count > 0 &&
                    <>
                        <br/>
                        <Pagination
                            onChange={e => catsStore.setNow(e)}
                            current={catsStore.now}
                            total={catsStore.count}
                            defaultPageSize={catsStore.limit}
                            showSizeChanger={false}
                        />
                    </>
                    }
                </Col>
            </Row>
        </>
    );


    return(
        <>
            <SEO
                title="Категории"
            />
            {catsStore.count > -1 ? list : <LoadList />}
        </>
    )
});

export default CategoryList;