import { observer } from "mobx-react-lite";
import { Row, Col, Input, Button } from "antd";
import { LoginOutlined, LockOutlined, MailOutlined } from '@ant-design/icons';
import logo from "../../img/logo.svg";
import auth from "../../store/auth";
import SEO from "@americanexpress/react-seo";

const SignIn = observer(() => {

    return(
        <>
            <SEO
                title="Авторизация - Yoga108 Admin Panel"
            />
            <Row justify="center" align="middle" className="auth">
                <Col xs={24} sm={14} md={11} lg={9} xl={6} className="login">
                    <div className="logo">
                        <img src={logo} alt="Yoga 108" />
                    </div>
                    <form onSubmit={(e) => auth.LogIn(e)}>
                        <Input
                            onChange={(e) => auth.onChange("login", e.target.value)}
                            value={auth.user.login}
                            placeholder="Email"
                            allowClear
                            addonBefore={<MailOutlined />}
                            required
                        />
                        <Input.Password
                            onChange={(e) => auth.onChange("password", e.target.value)}
                            value={auth.user.password}
                            placeholder="Пароль"
                            allowClear
                            addonBefore={<LockOutlined />}
                            required
                        />
                        <Button
                            htmlType="submit"
                            type="primary"
                            icon={<LoginOutlined />}
                        >
                            Войти
                        </Button>
                    </form>
                </Col>
            </Row>
        </>
    )
});

export default SignIn;