import { Controller } from '../../controller'
import api from '../../store/requests/api';
import {makeObservable, observable} from 'mobx';

class PurchasesController extends Controller {

    subscriptions = []
    purchases = []

    value = ''
    date = ''
    type = ''

    constructor(props) {
        super(props)
        makeObservable(this, {
            subscriptions: observable,
            purchases: observable,
            value: observable,
            date: observable,
            type: observable
        })
    }

    select(type) {
        this.type = type
    }

    onChangeValue(value) {
        this.value = value
    }

    onChangeDate(value) {
        this.date = value
        if(this.type === 'purchases')
            this.getPurchases()
        else
            this.getSubscriptions()
    }

    setPercent(e, name) {
        e.preventDefault()
        const data = new FormData()
        data.append('name', name)
        data.append('value', this.value)

        api.post('/payments/percent', data)
            .then(result => {
                if(this.type === 'purchases')
                    this.getPurchases()
                else
                    this.getSubscriptions()
            })
            .catch(error => console.log(error.response))
    }

    getSubscriptions() {
        let url = '/payments/subscriptions'
        if(this.date !== '') url = url + `?month=${this.date}`
        api.get(url)
            .then(result => {
                console.log(result.data)
                this.value = result.data.percent
                this.subscriptions = result.data
            })
    }

    getPurchases() {
        let url = '/payments'
        if(this.date !== '') url = url + `?month=${this.date}`
        api.get(url)
            .then(result => {
                console.log(result.data)
                this.value = result.data.percent
                this.purchases = result.data
            })
    }

}

export default new PurchasesController()