import { makeAutoObservable } from "mobx"
import axios from "./axios"
import { message } from "antd"
import sha1 from "js-sha1"
import store from "store"
import config from "../config.json";

class AuthStore {

    admin = {
        id: 0,
        email: "",
        password: "",
        confirmPassword: "",
        name: ""
    };

    list = [];

    count = -1;
    now = 1;
    limit = config.limit;

    constructor() {
        makeAutoObservable(this);
    }

    onChange(name, value) {
        this.admin[name] = value;
    }

    getListAdmin() {
        axios.get("/admin/userlist?limit=" + this.limit + "&page=" + this.now)
            .then(success => {
                this.list = success.data.items;
                this.count = Number(success.data.pagination[1]);
                this.now = Number(success.data.pagination[0]);
            })
            .catch(text => {
                message.error(text);
            });
    }

    sendForm(e) {
        e.preventDefault();

        let facts = new FormData();
        facts.append("email", this.admin.email);
        facts.append("name", this.admin.name);

        if(this.admin.password !== "" || this.admin.confirmPassword !== "") {
            if(this.admin.password !== this.admin.confirmPassword) {
                message.error("Пароли не совпадают");
                return false;
            } else {
                facts.append("password", sha1(this.admin.password));
            }
        }

        let url
        if(this.admin.id > 0) {
            url = "/admin/useredit/" + this.admin.id;
        } else {
            url = "/admin/register"
        }

        axios.post(url, facts)
            .then(success => {
                if(this.admin.id > 0) {
                    message.success('Успешно');
                } else {
                    document.location.href = "/users/administrators";
                }
            })
            .catch(error => console.log(error.response.data.message));
    }

    getAdmin(adminID) {
        axios.get("/admin/userbyid/" + adminID)
            .then(response => {
                this.admin = {
                    ...this.admin,
                    id: response.data.id,
                    email: response.data.email,
                    name: response.data.firstname
                }
            })
            .catch(error => {
                message.error(error.response.data.message);
            });
    }

    deleteAdmin(id) {
        axios.delete("/admin/user/" + id)
            .then(response => {
                message.success('Успешно удалено');
                this.getListAdmin();
            })
            .catch(error => {
                message.error(error.response.data.message);
            });
    }

    logout() {
        store.clearAll();
        document.location.href = "/signin";
    }

    setNow(page) {
        this.now = page;
        this.getListAdmin()
    }
}

export default new AuthStore();