import { makeAutoObservable } from "mobx"
import axios from "./axios"
import { message } from "antd"
import config from "../config.json";
import uploadStore from "./uploadStore";
import searchStore from "./searchStore";

class ProductStore {

    product = {
        id: 0,
        name: "",
        description: [],
        price: null,
        priceto: null,
        photo: [],
        shopurl: "",
        viewmain: false,
        ru: null,
        en: null
    };

    list = [];

    count = -1;
    now = 1;
    limit = config.limit;

    constructor() {
        makeAutoObservable(this);
    }

    onChange(name, value) {
        this.product[name] = value;
    }

    getAllProduct() {
        axios.get("/product/product?limit=" + this.limit + "&page=" + this.now)
            .then(success => {
                this.list = success.data.items;
                this.count = Number(success.data.pagination[1]);
                this.now = Number(success.data.pagination[0]);
            })
            .catch(error => console.log(error));
    }

    deleteProduct(productID) {
        axios.delete("/product/product/" + productID)
            .then(success => {
                this.getAllProduct();
            })
            .catch(error => console.log(error));
    }

    saveProduct() {
        let facts = new FormData();
        facts.append("name", this.product.name);
        facts.append("description", JSON.stringify(this.product.description));
        facts.append("price", this.product.price);
        facts.append("priceto", this.product.priceto);
        facts.append("shopurl", this.product.shopurl);
        facts.append("categoryid", this.product.categoryid);
        facts.append("ru", this.product.ru);
        facts.append("en", this.product.en);

        facts.append("photo", this.product.photo.length > 0 ? JSON.stringify(this.product.photo) : "");

        facts.append("viewmain", this.product.viewmain === false ? 0 : 1);

        if(typeof(this.product.categoryid) === "undefined") {
            return message.error('Не выбрана категория.');
        }

        let url
        if(this.product.id > 0) {
            url = "/product/product/" + this.product.id;
        } else {
            url = "/product/product"
        }
        axios.post(url, facts)
            .then(success => {
                if(this.product.id > 0) {
                    message.success('Успешно');
                } else {
                    document.location.href = "/shop/products";
                }
            })
            .catch(error => console.log(error.response.data.message));
    }

    sendForm(e) {
        e.preventDefault();
        this.saveProduct();
    }

    getProduct(productID) {
        axios.get("/product/product/" + productID)
            .then(response => {
                console.log(response);
                if(Number(response.data.viewmain) === 1) {
                    this.product.viewmain = true;
                } else {
                    this.product.viewmain = false;
                }
                if(response.data.photo !== "" && response.data.photo !== null) {
                    this.product.photo = response.data.photo;
                } else {
                    this.product.photo = [];
                }

                this.product = {
                    ...this.product,
                    categoryid: response.data.categoryid,
                    description: Array.isArray(response.data.description) ? response.data.description : [],
                    description2: response.data.description2,
                    id: response.data.id,
                    name: response.data.name,
                    position: response.data.position,
                    price: response.data.price,
                    priceto: response.data.priceto,
                    shopurl: response.data.shopurl,
                    ru: response.data.ru,
                    en: response.data.en,
                };
                searchStore.getByID("category", this.product.categoryid, "list", "product");
            })
            .catch(response => console.log(response));
    }

    setPhoto(photo) {
        this.product.photo = [...this.product.photo, photo];
    }

    removePhoto(photo) {
        uploadStore.deleteFile(photo)
        try {
            this.product.photo = this.product.photo.filter(el => el.url !== photo);
        } catch {
            message.error("Ошибка удаления файла")
        }
        this.saveProduct();
    }

    setNow(page) {
        this.now = page;
        this.getAllProduct()
    }

    itemDay(e) {
        this.product.viewmain = e.target.checked;
    }
}

export default new ProductStore();