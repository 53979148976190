import { observer} from "mobx-react-lite"
import { useEffect } from "react"
import PurchasesController from './controller'
import {Button, Col, InputNumber, Row, Space, Statistic, Table, DatePicker, Typography} from 'antd'
import CardController from '../card_day/card.controller';
import {DeleteOutlined, EditOutlined, ExclamationCircleOutlined} from '@ant-design/icons';
import SEO from '@americanexpress/react-seo';

export const PurchasesSubscriptions = observer(() => {

    useEffect(() => {
        PurchasesController.select('subscriptions')
        PurchasesController.getSubscriptions()
    }, [])

    const columns = [
        {
            title: 'Автор',
            dataIndex: 'name',
        },
        {
            title: 'Сумма вознаграждения',
            dataIndex: 'sum',
        },
        {
            title: 'Процент вознаграждения',
            dataIndex: 'percent',
        },
        {
            title: 'Кол-во минут в практиках',
            dataIndex: 'min',
        },
    ]

    return(
        <>
            <SEO
                title="Выплаты по подпискам"
            />
            <Row className="articles administration" justify="space-around" align="middle" gutter={[30, 30]}>
                <Col span={24} className="admHeading">
                    <Typography.Title>Выплаты по подпискам</Typography.Title>
                </Col>
                <Col span={12}>
                    <form onSubmit={(e) => PurchasesController.setPercent(e, 'percent_subscription')}>
                        <label>Процент комиссии</label><br/>
                        <Space>
                            <InputNumber onChange={(e) => PurchasesController.onChangeValue(e)} value={PurchasesController.value} />
                            <Button htmlType="submit">Сохранить</Button>
                        </Space>
                    </form>
                </Col>
                <Col span={12}>
                    <label>Выберите месяц для вывода статистики</label><br/>
                    <DatePicker picker="month" onChange={(e, string) => PurchasesController.onChangeDate(string)} />
                </Col>
                <Col span={24}>
                    {PurchasesController.subscriptions.all &&
                        <>
                            <Row gutter={16}>
                                <Col span={6}>
                                    <Statistic title="Всего, $" value={PurchasesController.subscriptions.after.all.sum} />
                                    <Statistic title="Всего, шт" value={PurchasesController.subscriptions.after.all.count} />
                                </Col>
                                <Col span={6}>
                                    <Statistic title="Apple, $" value={PurchasesController.subscriptions.after.apple.sum} />
                                    <Statistic title="Apple, шт" value={PurchasesController.subscriptions.after.apple.count} />
                                    <Statistic title="Apple (с учетом комиссии), $" value={PurchasesController.subscriptions.apple.sum} />
                                </Col>
                                <Col span={6}>
                                    <Statistic title="Google, $" value={PurchasesController.subscriptions.after.google.sum} />
                                    <Statistic title="Google, шт" value={PurchasesController.subscriptions.after.google.count} />
                                    <Statistic title="Google (с учетом комиссии), $" value={PurchasesController.subscriptions.google.sum} />
                                </Col>
                            </Row>
                            <br/><br/>
                            <Row>
                                <Col span={24}>
                                    <Table columns={columns} dataSource={PurchasesController.subscriptions.authors} size="small" pagination={false}/>
                                </Col>
                            </Row>
                        </>
                    }
                </Col>
            </Row>
        </>
    )
})

export const Purchases = observer(() => {

    useEffect(() => {
        PurchasesController.select('purchases')
        PurchasesController.getPurchases()
    }, [])

    const columns = [
        {
            title: 'Автор',
            dataIndex: 'user',
            render: (el) => el.name
        },
        {
            title: 'Сумма вознаграждения',
            dataIndex: 'all',
            render: (el) => el.sum
        },
    ]

    return(
        <>
            <SEO
                title="Выплаты по покупкам"
            />
            <Row className="articles administration" justify="space-around" align="middle" gutter={[30, 30]}>
                <Col span={24} className="admHeading">
                    <Typography.Title>Выплаты по покупкам</Typography.Title>
                </Col>
                <Col span={12}>
                    <form onSubmit={(e) => PurchasesController.setPercent(e, 'percent_purchases')}>
                        <label>Процент комиссии</label><br/>
                        <Space>
                            <InputNumber onChange={(e) => PurchasesController.onChangeValue(e)} value={PurchasesController.value} />
                            <Button htmlType="submit">Сохранить</Button>
                        </Space>
                    </form>
                </Col>
                <Col span={12}>
                    <label>Выберите месяц для вывода статистики</label><br/>
                    <DatePicker picker="month" onChange={(e, string) => PurchasesController.onChangeDate(string)} />
                </Col>
                <Col span={24}>
                    {PurchasesController.purchases.after && PurchasesController.purchases.before.apple &&
                        <>
                            <Row gutter={16}>
                                <Col span={6}>
                                    <Statistic title="Всего, $" value={PurchasesController.purchases.after.all.sum} />
                                    <Statistic title="Всего, шт" value={PurchasesController.purchases.after.all.count} />
                                </Col>
                                <Col span={6}>
                                    <Statistic title="Apple, $" value={PurchasesController.purchases.after.apple.sum} />
                                    <Statistic title="Apple, шт" value={PurchasesController.purchases.after.apple.count} />
                                    <Statistic title="Apple (с учетом комиссии), $" value={PurchasesController.purchases.before.apple.sum} />
                                </Col>
                                <Col span={6}>
                                    <Statistic title="Google, $" value={PurchasesController.purchases.after.google.sum} />
                                    <Statistic title="Google, шт" value={PurchasesController.purchases.after.google.count} />
                                    <Statistic title="Google (с учетом комиссии), $" value={PurchasesController.purchases.before.google.sum} />
                                </Col>
                            </Row>
                            <br/><br/>
                            <Row>
                                <Col span={24}>
                                    <Table columns={columns} dataSource={PurchasesController.purchases.authors} size="small" pagination={false}/>
                                </Col>
                            </Row>
                        </>
                    }
                </Col>
            </Row>
        </>
    )
})