import { makeAutoObservable } from "mobx"
import axios from "./axios"
import { message } from "antd"
import moment from "moment";
import config from "../config.json"
import uploadStore from "./uploadStore";

class eventStore {

    event = {
        id: 0,
        name: "",
        photo: [],
        numberofseats: null,
        viewmain: false,
        description: [],
        url: "",
        ru: null,
        en: null
    };

    list = [];

    count = -1;
    now = 1;
    limit = config.limit;

    constructor() {
        makeAutoObservable(this);
    }

    onChange(name, value) {
        this.event[name] = value;
    }

    onDateChange(dates) {
        this.event.datestart = new Date(dates[0]).getTime() / 1000;
        this.event.dateend = new Date(dates[1]).getTime() / 1000;
    }

    getAllEvent() {
        axios.get("/event/event?limit=" + this.limit + "&page=" + this.now)
            .then(success => {
                this.list = success.data.items;
                this.count = Number(success.data.pagination[1]);
                this.now = Number(success.data.pagination[0]);
            })
            .catch(error => console.log(error));
    }

    deleteEvent(eventID) {
        axios.delete("/event/event/" + eventID)
            .then(success => {
                this.getAllEvent();
            })
            .catch(error => console.log(error));
    }

    saveItem() {
        let facts = new FormData();
        facts.append("name", this.event.name);
        facts.append("description", JSON.stringify(this.event.description));
        facts.append("numberofseats", this.event.numberofseats !== null ? this.event.numberofseats : 0);
        facts.append("datestart", this.event.datestart);
        facts.append("dateend", this.event.dateend);
        facts.append("url", this.event.url);
        facts.append("ru", this.event.ru);
        facts.append("en", this.event.en);

        facts.append("viewmain", this.event.viewmain === false ? 0 : 1);

        facts.append("photo", this.event.photo.length > 0 ? JSON.stringify(this.event.photo) : "");

        if(isNaN(this.event.datestart) || isNaN(this.event.dateend)) {
            return message.error("Не заполнены даты.")
        }

        let url
        if(this.event.id > 0) {
            url = "/event/event/" + this.event.id;
        } else {
            url = "/event/event"
        }

        axios.post(url, facts)
            .then(success => {
                if(this.event.id > 0) {
                    message.success('Успешно');
                } else {
                    document.location.href = "/events";
                }
            })
            .catch(error => console.log(error.response.data.message));
    }

    sendForm(e) {
        e.preventDefault();
        this.saveItem();
    }

    getEvent(eventID) {
        axios.get("/event/event/" + eventID)
            .then(response => {
                this.event = {
                    dateend: response.data.dateend,
                    datestart: response.data.datestart,
                    description: Array.isArray(response.data.description) ? response.data.description : [],
                    description2: response.data.description2,
                    id: response.data.id,
                    name: response.data.name,
                    numberofseats: response.data.numberofseats,
                    photo: response.data.photo !== "" && response.data.photo !== null ? response.data.photo : [],
                    position: response.data.position,
                    viewmain: Number(response.data.viewmain) === 1 ? true : false,
                    url: response.data.url,
                    ru: response.data.ru,
                    en: response.data.en,
                };
            })
            .catch(response => console.log(response));
    }


    setPhoto(photo) {
        this.event.photo = [...this.event.photo, photo];
    }

    eventDate(start, end) {
        if(start && end) {
            const dateFormat = "YYYY-MM-DD";
            start = moment.unix(start).format(dateFormat);
            end = moment.unix(end).format(dateFormat);
            return [moment(start, dateFormat), moment(end, dateFormat)];
        }
    }

    setNow(page) {
        this.now = page;
        this.getAllEvent()
    }

    itemDay(e) {
        this.event.viewmain = e.target.checked;
    }

    deleteImage(photo) {
        uploadStore.deleteFile(photo)
        try {
            this.event.photo = this.event.photo.filter(el => el.url !== photo);
        } catch {
            message.error("Ошибка удаления файла")
        }
        this.saveItem();
    }
}

export default new eventStore();