import { makeObservable, observable } from "mobx"
import { Controller } from '../../controller'
import api from '../../store/requests/api';

class PushController extends Controller {

    filter = {
        sent: null,
        devices: null
    }

    postpone = false

    element = {
        title: null,
        description: null,
        devices: null,
        element_type: null,
        element_id: null,
        postpone_to: null,
        count_sent: 0
    }

    list = []

    constructor() {
        super()
        makeObservable(this, {
            filter: observable,
            list: observable,
            element: observable,
            postpone: observable,
        })
    }

    setPostpone() {
        this.postpone = !this.postpone
    }

    getList() {
        let url = `/push?limit=${this.limit}&offset=${this.offset}`

        if(this.filter.sent) url = `${url}&sent=${this.filter.sent}`
        if(this.filter.devices) url = `${url}&devices=${this.filter.devices}`

        api.get(url)
            .then(result => {
                console.log(result)
                this.list = result.data.items
                this.setCount(result.data.count)
            })
            .catch(error => console.log(error))
    }

    setFilter(name, value, add = true) {
        if(add) this.filter[name] = value
        else this.filter[name] = null
        this.getList()
    }

    send() {
        const data = new FormData()
        data.append('title', this.element.title)
        data.append('description', this.element.description)
        data.append('devices', this.element.devices)

        if(this.element.element_type && this.element.element_id > 0) {
            data.append('element_type', this.element.element_type)
            data.append('element_id', this.element.element_id)
        }

        console.log(`ii ${this.element.postpone_to}`)

        if(this.postpone && this.element.postpone_to) {
            console.log(`add ${this.element.postpone_to}`)
            data.append('postpone_to', this.element.postpone_to)
        }

        api.post('/push', data)
            .then(result => {
                console.log(result)
                window.location.href = '/pushes'
            })
            .catch(error => console.log(error))
    }

    createNotification(e) {
        e.preventDefault()
        this.send()
    }

    changeElement(name, value) {
        if(name === 'postpone_to') value = new Date(value).getTime() / 1000
        console.log(value)
        this.element[name] = value
    }

    delete(pushId) {
        api.delete(`/push/${pushId}`)
            .then(result => this.getList())
    }

    setNow(page) {
        let page_now = page - 1 >= 0 ? page - 1 : 0
        this.offset = page > 1 ? this.limit * page_now : 0;
        this.now = page
        this.getList()
    }

}

export default new PushController()