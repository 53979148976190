import { observer } from "mobx-react-lite";
import React, { useEffect } from "react"
import {
    Row,
    Col,
    Input,
    Button,
    Typography,
    Upload,
    DatePicker,
    ConfigProvider,
    Checkbox,
    Space,
    InputNumber
} from "antd";
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import eventStore from "../../store/eventStore";
import config from "../../config.json";
import uploadStore from "../../store/uploadStore";
import "moment/locale/ru";
import locale from 'antd/lib/locale/ru_RU';
import SEO from "@americanexpress/react-seo";
import LoadCart from "../LoadCart";
import VisualEditor from "../VisualEditor";
import articleStore from '../../store/articleStore';
import {useParams} from 'react-router';
const { RangePicker } = DatePicker;

const Event = observer((props) => {

    const { eventID } = useParams()
    
    useEffect(() => {
        if(eventID && eventID > 0){
            eventStore.getEvent(eventID);
        }
    }, []);

    const propsUpload = {
        name: 'file',
        multiple: true,
        action: config.apiUrl + '/upload/upload',
        headers: {
            "Type-Device": config.typeDevice
        },
        data: {
            path: uploadStore.file.type + "/event/" + uploadStore.getPathDate(),
            type: uploadStore.file.type
        },
        onChange(info) {
            let photo = uploadStore.onChangeFile(info);
            if(typeof(photo) === "object") {
                eventStore.setPhoto(photo);
            }

        },
    };

    const editDescription = (e) => {
        eventStore.onChange("description", e)
    }

    const element = (
        <form onSubmit={e => eventStore.sendForm(e)}>
            <Row className="events administration" justify="space-around" align="middle">
                <Col span={16} className="admHeading">
                    <Typography.Title>
                        {eventID && eventID > 0 ? "Редактировать событие" : "Добавить событие"}
                    </Typography.Title>
                </Col>
                <Col span={8} align="right">
                    <Space>
                        <Button type="dashed" href="/events">К списку</Button>
                        <Button type="primary" htmlType="submit">Сохранить</Button>
                    </Space>
                </Col>
                <Col span={24}>
                    <Row gutter={[15, 30]}>
                        <Col span={8}>
                            <Checkbox
                                onChange={e => eventStore.itemDay(e)}
                                checked={eventStore.event.viewmain}
                            >
                                Событие дня
                            </Checkbox>
                        </Col>
                        <Col span={8}>
                            <Checkbox
                                onChange={e => eventStore.onChange("ru", e.target.checked === true ? 1 : 0)}
                                checked={eventStore.event.ru}
                            >
                                Показывать на РУ
                            </Checkbox>
                        </Col>
                        <Col span={8}>
                            <Checkbox
                                onChange={e => eventStore.onChange("en", e.target.checked === true ? 1 : 0)}
                                checked={eventStore.event.en}
                            >
                                Показывать на EN
                            </Checkbox>
                        </Col>
                        <Col xs={24}>
                            <Typography.Title level={5}>Название</Typography.Title>
                            <Input
                                onChange={e => eventStore.onChange("name", e.target.value)}
                                value={eventStore.event.name}
                                required
                            />
                        </Col>
                        <Col xs={24}>
                            <Typography.Title level={5}>Ссылка на событие</Typography.Title>
                            <Input
                                onChange={e => eventStore.onChange("url", e.target.value)}
                                value={eventStore.event.url}
                            />
                        </Col>
                        <Col xs={24} lg={6}>
                            <Typography.Title level={5}>Количество мест</Typography.Title>
                            <InputNumber
                                onChange={e => eventStore.onChange("numberofseats", e)}
                                value={eventStore.event.numberofseats}
                            />
                        </Col>
                        <Col xs={24} lg={6}>
                            <Typography.Title level={5}>Даты проведения</Typography.Title>
                            <ConfigProvider locale={locale}>
                                <RangePicker
                                    onChange={(e, d) => eventStore.onDateChange(d)}
                                    value={eventStore.eventDate(eventStore.event.datestart, eventStore.event.dateend)}
                                />
                            </ConfigProvider>
                        </Col>
                        <Col xs={24} />
                        <Col xs={24} lg={24}>
                            <Typography.Title level={5}>Описание</Typography.Title>
                            <VisualEditor
                                value={eventStore.event.description}
                                updateData={editDescription}
                            />
                        </Col>
                        <Col xs={24}>
                            <Typography.Title level={5}>Фото</Typography.Title>
                            {eventStore.event.photo.length > 0 && eventStore.event.photo.map(item =>
                                <div className="forImage">
                                    <Button
                                        className="delete"
                                        danger
                                        type="dashed"
                                        shape="circle"
                                        icon={<DeleteOutlined />}
                                        onClick={() => eventStore.deleteImage(item.url)}
                                    />
                                    <img src={item.url} width={100 + "%"} alt=""/>
                                </div>
                            )}
                            <Upload
                                {...propsUpload}
                                listType="picture-card"
                                className="photo-uploader"
                                maxCount={1}
                            >
                                <div>
                                    <PlusOutlined />
                                    <div style={{ marginTop: 8 }}>Загрузить</div>
                                </div>
                            </Upload>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </form>
    );

    return(
        <>
            <SEO
                title={eventID && eventID > 0 ? "Редактировать событие" : "Добавить событие"}
            />
            {eventID && eventID > 0 && eventStore.event.id === 0 ? <LoadCart /> : element}
        </>
    )
});

export default Event;