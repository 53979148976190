import { observer } from "mobx-react-lite"
import { useEffect } from "react"
import { Row, Col, Button, Typography, Table, Space, Popconfirm, Tooltip, Pagination } from "antd";
import { EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import practiceStore from "../../store/practiceStore";
import SEO from "@americanexpress/react-seo";
import LoadList from "../LoadList";

const PracticesList = observer(() => {
    useEffect(() => {
        practiceStore.getAllPractice();
    }, []);

    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            width: "40px",
            align: "center",
        },
        {
            title: "Название",
            dataIndex: "name",
        },
        {
            title: "Действия",
            dataIndex: "acts",
            width: "80px",
            render: (text, row) => (
                <>
                    <Space>
                        <Button shape="circle" type="dashed" href={"/practices/practice/" + row.id} icon={<EditOutlined />} />
                        <Tooltip placement="bottom" title="Удалить">
                            <Popconfirm
                                placement="left"
                                title="Вы уверены?"
                                onConfirm={() => practiceStore.deletePractice(row.id)}
                                okText="Да"
                                cancelText="Нет"
                                icon={<ExclamationCircleOutlined style={{ color: "red" }} />}
                            >
                                <Button danger type="dashed" shape="circle" icon={<DeleteOutlined />} />
                            </Popconfirm>
                        </Tooltip>
                    </Space>
                </>
            )
        }
    ];

    const list = (
        <Row className="practices administration" justify="space-around" align="middle">
            <Col span={20} className="admHeading">
                <Typography.Title>Практики</Typography.Title>
            </Col>
            <Col span={4} align="right">
                <Button type="primary" href={"/practices/practice/0"}>Добавить</Button>
            </Col>
            <Col span={24}>
                <Table columns={columns} dataSource={practiceStore.list} size="small" pagination={false}/>
                {practiceStore.count > 0 &&
                <>
                    <br/>
                    <Pagination
                        onChange={e => practiceStore.setNow(e)}
                        current={practiceStore.now}
                        total={practiceStore.count}
                        defaultPageSize={practiceStore.limit}
                        onShowSizeChange={(e, size) => practiceStore.setLimit(size)}
                        showSizeChanger={true}
                    />
                </>
                }
            </Col>
        </Row>
    );

    return(
        <>
            <SEO
                title="Практики"
            />
            {practiceStore.count > -1 ? list : <LoadList />}
        </>
    )
});

export default PracticesList;