import { observer } from 'mobx-react-lite'
import React, {useEffect, useState} from 'react';
import SEO from '@americanexpress/react-seo';
import LoadList from '../LoadList';
import {
    Button,
    Checkbox,
    Col,
    Input,
    Pagination,
    Popconfirm,
    Row,
    Space,
    Table,
    Tooltip,
    Typography,
    Upload
} from 'antd';
import {DeleteOutlined, EditOutlined, ExclamationCircleOutlined, PlusOutlined} from '@ant-design/icons';
import CardController from './card.controller'
import config from '../../config.json';
import uploadStore from '../../store/uploadStore';
import authorStore from '../../store/authorStore';
import {useParams} from 'react-router';
import cardController from './card.controller';

export const CardDayList = observer(() => {

    useEffect(() => {
        CardController.getList()
    }, [])

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            width: '40px'
        },
        {
            title: 'Название',
            dataIndex: 'name',
        },
        {
            title: 'Действия',
            dataIndex: 'tableActions',
            width: '80px',
            render: (text, row) => (
                <>
                    <Space>
                        <Tooltip placement="bottom" title="Редактировать">
                            <Button href={'/cards/'+ row.id} type="dashed" shape="circle" icon={<EditOutlined />} />
                        </Tooltip>
                        <Tooltip placement="bottom" title="Удалить">
                            <Popconfirm
                                placement="left"
                                title="Вы уверены?"
                                onConfirm={() => CardController.delete(row.id)}
                                okText="Да"
                                cancelText="Нет"
                                icon={<ExclamationCircleOutlined style={{ color: 'red' }} />}
                            >
                                <Button danger type="dashed" shape="circle" icon={<DeleteOutlined />} />
                            </Popconfirm>
                        </Tooltip>
                    </Space>
                </>
            )
        }
    ]

    const list = (
        <Row className="articles administration" justify="space-around" align="middle">
            <Col span={20} className="admHeading">
                <Typography.Title>Список карт дня</Typography.Title>
            </Col>
            <Col span={4} align="right">
                <Button type="primary" href={'/cards/0'}>Добавить</Button>
            </Col>
            <Col span={24}>
                <Table columns={columns} dataSource={CardController.list} size="small" pagination={false}/>
                {CardController.count > 0 &&
                    <>
                        <br/>
                        <Pagination
                            onChange={e => CardController.setNow(e)}
                            current={CardController.now}
                            total={CardController.count}
                            defaultPageSize={CardController.limit}
                            onShowSizeChange={(current, size) => {
                                CardController.limit = size
                                CardController.getList()
                            }}
                        />
                    </>
                }
            </Col>
        </Row>
    )

    return(
        <>
            <SEO
                title="Список карт дня"
            />
            {CardController.count > -1 ? list : <LoadList />}
        </>
    )
})

export const CardDay = observer((props) => {

    const { cardId } = useParams()

    useEffect(() => {
        if(cardId && cardId > 0){
            CardController.getOne(cardId);
        }
    }, [])

    return(
        <>
            <form onSubmit={e => CardController.sendForm(e, cardId)}>
                <Row className="author administration" justify="space-around" align="middle">
                    <Col span={16} className="admHeading">
                        <Typography.Title>
                            {cardId && cardId > 0 ? "Редактировать карту" : "Добавить карту"}
                        </Typography.Title>
                    </Col>
                    <Col span={8} align="right">
                        <Space>
                            <Button type="dashed" href="/cards">К списку</Button>
                            <Button type="primary" htmlType="submit">Сохранить</Button>
                        </Space>
                    </Col>
                    <Col span={24}>
                        <Row gutter={[15, 30]}>
                            <Col span={6}>
                                <Checkbox
                                    onChange={e => cardController.element.ru = e.target.checked === true ? 1 : 0}
                                    checked={cardController.element.ru > 0}
                                >
                                    Показывать на РУ
                                </Checkbox>
                            </Col>
                            <Col span={6}>
                                <Checkbox
                                    onChange={e => cardController.element.en = e.target.checked === true ? 1 : 0}
                                    checked={cardController.element.en > 0}
                                >
                                    Показывать на EN
                                </Checkbox>
                            </Col>
                            <Col span={24}>
                                <Typography.Title level={5}>Наименование</Typography.Title>
                                <Input
                                    onChange={e => CardController.onChange("name", e.target.value)}
                                    value={CardController.element.name}
                                    required
                                />
                            </Col>
                            <Col span={24}>
                                <Typography.Title level={5}>Описание</Typography.Title>
                                <Input.TextArea
                                    onChange={e => CardController.onChange("description", e.target.value)}
                                    value={CardController.element.description}
                                    required
                                />
                            </Col>
                            <Col span={24}>
                                <Typography.Title level={5}>Изображение</Typography.Title>
                                {CardController.element.image && CardController.element.image.path &&
                                    <div className="forImage">
                                        <Button
                                            className="delete"
                                            danger
                                            type="dashed"
                                            shape="circle"
                                            icon={<DeleteOutlined />}
                                            onClick={() => CardController.deleteImageCard(cardId)}
                                        />
                                        <img src={CardController.element.image.path} width={100 + "%"} alt=""/>
                                    </div>
                                }
                                <Upload
                                    beforeUpload={(info) => CardController.upload(info)}
                                    listType="picture-card"
                                    className="photo-uploader"
                                    maxCount={1}
                                >
                                    <div>
                                        <PlusOutlined />
                                        <div style={{ marginTop: 8 }}>Загрузить</div>
                                    </div>
                                </Upload>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </form>
        </>
    )
})