import { observer } from "mobx-react-lite";
import React, { useEffect } from "react"
import {Row, Col, Input, Button, Typography, Select, TreeSelect, Upload, Space, Checkbox, InputNumber} from "antd";
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import practiceStore from "../../store/practiceStore";
import authorStore from "../../store/authorStore";
import config from "../../config.json";
import uploadStore from "../../store/uploadStore";
import categoryStore from "../../store/categoryStore";
import SEO from "@americanexpress/react-seo";
import LoadCart from "../LoadCart";
import searchStore from "../../store/searchStore";
import VisualEditor from "../VisualEditor";
import {useParams} from 'react-router'
const { Option } = Select;

const Practice = observer((props) => {

    const { practiceID } = useParams()

    useEffect(() => {
        if(practiceID && practiceID > 0){
            practiceStore.getPractice(practiceID);
        }
        authorStore.getListAuthor();
        practiceStore.getAllPractice();
        categoryStore.setType("practice");
        categoryStore.getAllCategory();
    }, []);

    if(practiceStore.practice.practiceid === 0){
        practiceStore.practice.practiceid = '0';
    }

    const propsUpload = {
        name: 'file',
        action: config.apiUrl + '/upload/upload',
        headers: {
            "Type-Device": config.typeDevice
        },
        data: {
            path: uploadStore.file.type + "/practice/" + uploadStore.getPathDate(),
            type: uploadStore.file.type
        },
        onChange(info) {
            let photo = uploadStore.onChangeFile(info);
            if(typeof(photo) === "object") {
                practiceStore.setPhoto(photo);
            }

        },
    };

    const editText = (e) => {
        practiceStore.onChange("text", e)
    }

    const element = (
        <form onSubmit={e => practiceStore.sendForm(e)}>
            <Row className="practices administration" justify="space-around" align="middle">
                <Col span={16} className="admHeading">
                    <Typography.Title>
                        {practiceID && practiceID > 0 ? "Редактировать практику" : "Добавить практику"}
                    </Typography.Title>
                </Col>
                <Col span={8} align="right">
                    <Space>
                        <Button type="dashed" href="/practices">К списку</Button>
                        <Button htmlType="submit" type="primary">Сохранить</Button>
                    </Space>
                </Col>
                <Col span={24}>

                    <Row gutter={[15, 30]}>
                        <Col xs={24}>
                            <Typography.Title level={5}>Название</Typography.Title>
                            <Input
                                onChange={e => practiceStore.onChange("name", e.target.value)}
                                value={practiceStore.practice.name}
                                required
                            />
                        </Col>
                        <Col span={6}>
                            <Checkbox
                                onChange={e => practiceStore.onChange("viewmain", e.target.checked === true ? 1 : 0)}
                                checked={practiceStore.practice.viewmain}
                            >
                                Показывать на главной
                            </Checkbox>
                        </Col>
                        <Col span={6}>
                            <Checkbox
                                onChange={e => practiceStore.onChange("ru", e.target.checked === true ? 1 : 0)}
                                checked={practiceStore.practice.ru}
                            >
                                Показывать на РУ
                            </Checkbox>
                        </Col>
                        <Col span={6}>
                            <Checkbox
                                onChange={e => practiceStore.onChange("en", e.target.checked === true ? 1 : 0)}
                                checked={practiceStore.practice.en}
                            >
                                Показывать на EN
                            </Checkbox>
                        </Col>
                        <Col span={6}>
                            <Typography.Title level={5}>Тип оплаты</Typography.Title>
                            <Select
                                style={{ width: `100%` }}
                                value={practiceStore.practice.typepay}
                                onChange={e => practiceStore.onChange("typepay", e)}
                            >
                                <Select.Option value={0}>Бесплатно</Select.Option>
                                <Select.Option value={1}>Платно</Select.Option>
                                <Select.Option value={2}>Подписка</Select.Option>
                            </Select>
                        </Col>
                        <Col xs={12}>
                            <Typography.Title level={5}>Уровень</Typography.Title>
                            <Input
                                onChange={e => practiceStore.onChange("level", e.target.value)}
                                value={practiceStore.practice.level}
                                required
                            />
                        </Col>
                        <Col xs={24} lg={8}>
                            <Typography.Title level={5}>Автор</Typography.Title>
                            <Select
                                name="author"
                                showSearch
                                style={{ width: 250 }}
                                placeholder="Искать"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                                onChange={
                                    e => practiceStore.onChange("author", e)
                                }
                                onSearch={e => searchStore.getAuthor(e)}
                                value={practiceStore.practice.author}
                            >
                                {searchStore.list.map((author) => (
                                    <Option value={author.id}>{author.name}</Option>
                                ))}
                            </Select>
                        </Col>
                        {/*<Col xs={24} lg={8}>
                                    <Typography.Title level={5}>Родительская практика</Typography.Title>
                                    <Select
                                        name="practiceid"
                                        showSearch
                                        style={{ width: 250 }}
                                        placeholder="Искать"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA, optionB) =>
                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                        }
                                        onChange={
                                            e => practiceStore.onChange("practiceid", e)
                                        }
                                        value={practiceStore.practice.practiceid}
                                    >
                                        <Option value="0">Нет родительской практики</Option>
                                        {practiceStore.list.map((practice) => (
                                            <Option value={practice.id}>{practice.name}</Option>
                                        ))}
                                    </Select>
                                </Col>*/}
                        <Col xs={24} lg={8}>
                            <Typography.Title level={5}>Категория</Typography.Title>
                            <Select
                                name="categoryid"
                                showSearch
                                style={{ width: 250 }}
                                placeholder="Искать"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                                onChange={
                                    e => practiceStore.onChange("categoryid", e)
                                }
                                onSearch={e => searchStore.getCategoryPracticle(e)}
                                value={practiceStore.practice.categoryid}
                            >
                                {searchStore.list_two.map((cat) => (
                                    <Option value={cat.id}>{cat.name}</Option>
                                ))}
                            </Select>
                        </Col>
                        <Col xs={24} lg={8}>
                            <Typography.Title level={5}>Цена для apple</Typography.Title>
                            <InputNumber
                                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                style={{ width: `100%` }}
                                onChange={e => practiceStore.onChange("price_apple", e)}
                                value={practiceStore.practice.price_apple}
                                required
                            />
                        </Col>
                        <Col xs={24} lg={8}>
                            <Typography.Title level={5}>Цена для google</Typography.Title>
                            <InputNumber
                                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                style={{ width: `100%` }}
                                onChange={e => practiceStore.onChange("price_google", e)}
                                value={practiceStore.practice.price_google}
                                required
                            />
                        </Col>
                        <Col xs={24}>
                            <Typography.Title level={5}>Фото</Typography.Title>
                            {practiceStore.practice.photo.length > 0 && practiceStore.practice.photo.map(item =>
                                <div className="forImage">
                                    <Button
                                        className="delete"
                                        danger
                                        type="dashed"
                                        shape="circle"
                                        icon={<DeleteOutlined />}
                                        onClick={() => practiceStore.deleteImage(item.url)}
                                    />
                                    <img src={item.url} width={100 + "%"} alt=""/>
                                </div>
                            )}
                            <Upload
                                {...propsUpload}
                                listType="picture-card"
                                className="photo-uploader"
                                maxCount={1}
                            >
                                <div>
                                    <PlusOutlined />
                                    <div style={{ marginTop: 8 }}>Загрузить</div>
                                </div>
                            </Upload>
                        </Col>
                        <Col xs={24} lg={24}>
                            <Typography.Title level={5}>Описание</Typography.Title>
                            <Input.TextArea
                                onChange={e => practiceStore.onChange("description", e.target.value)}
                                value={practiceStore.practice.description}
                                autoSize={{minRows: 3, maxRows: 20}}
                                required
                            />
                        </Col>
                        <Col xs={24} lg={24}>
                            <Typography.Title level={5}>Детальный текст</Typography.Title>
                            <VisualEditor
                                value={practiceStore.practice.text}
                                updateData={editText}
                            />
                        </Col>
                        <Col xs={24} lg={8}>
                            <Typography.Title level={5}>Apple</Typography.Title>
                            <Input
                                onChange={e => practiceStore.onChange("apple", e.target.value)}
                                value={practiceStore.practice.apple}
                                //required
                            />
                        </Col><Col xs={24} lg={8}>
                        <Typography.Title level={5}>Android</Typography.Title>
                        <Input
                            onChange={e => practiceStore.onChange("android", e.target.value)}
                            value={practiceStore.practice.android}
                            //required
                        />
                    </Col>
                    </Row>
                </Col>
            </Row>
        </form>
    );

    return(
        <>
            <SEO
                title={practiceID && practiceID > 0 ? "Редактировать практику" : "Добавить практику"}
            />
            {practiceID && practiceID > 0 && practiceStore.practice.id === 0 ? <LoadCart /> : element}
        </>
    )
});

export default Practice;