import {makeAutoObservable} from "mobx";
import axios from "../../store/axios";

class _setting {

    settings = [
        {
            name: "activation_code",
            keytype: "activation_code",
            value: 0
        }
    ]

    constructor() {
        makeAutoObservable(this)
    }

    getSetting() {
        axios.get("/setting/setting")
            .then(response => {
                console.log(response)
                this.settings = response.data.items
            })
    }

    save() {
        let facts = new FormData()
        facts.append("name", this.settings.find(el => el.keytype === "activation_code").name)
        facts.append("value", this.settings.find(el => el.keytype === "activation_code").value)
        facts.append("keytype", this.settings.find(el => el.keytype === "activation_code").keytype)

        let url = "/setting/setting"
        if(this.settings.find(el => el.keytype === "activation_code")) {
            url = `${url}/${this.settings.find(el => el.keytype === "activation_code").id}`
        }

        axios.post(url, facts)
            .then(response => {
                console.log(response)
            })
    }

    onChange(e) {
        console.log(e)
        let index = this.settings.findIndex(el => el.keytype === "activation_code")
        this.settings[index].value = e ? 1 : 0
        this.save()
    }
}

export default new _setting()