import { Result, Button } from 'antd';
import SEO from "@americanexpress/react-seo";

const PageNotFound = () => {
    return(
        <>
            <SEO
                title="404 - Not Found"
            />
            <Result
                status="404"
                title="404"
                subTitle="Запрашиваемая страница не найдена"
                extra={<Button type="primary" href="/">На главную</Button>}
            />
        </>
    )
};

export default PageNotFound;