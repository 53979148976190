import { makeAutoObservable } from "mobx"
import axios from "./axios"
import { message } from "antd"
import config from "../config.json";
import uploadStore from "./uploadStore";
import searchStore from "./searchStore";

class ExerciseStore {

    exercise = {
        id: 0,
        text: [],
        photo: {},
        video: "",
        name: "",
        comment: "",
        audio: ""
    };

    list = [];

    count = -1;
    now = 1;
    limit = config.limit;

    constructor() {
        makeAutoObservable(this);
    }

    setLimit(limit) {
        this.limit = limit
        this.now = 1
        this.getAllExercise()
    }

    onChange(name, value) {
        this.exercise[name] = value;
    }

    getAllExercise() {
        axios.get("/practice/exercise?limit=" + this.limit + "&page=" + this.now)
            .then(success => {
                this.list = success.data.items;
                this.count = Number(success.data.pagination[1]);
                this.now = Number(success.data.pagination[0]);
            })
            .catch(error => console.log(error));
    }

    deleteExercise(exerciseID) {
        axios.delete("/practice/exercise/" + exerciseID)
            .then(success => {
                this.getAllExercise();
            })
            .catch(error => console.log(error));
    }

    sendForm(e) {
        e.preventDefault();

        let facts = new FormData();
        facts.append("text", JSON.stringify(this.exercise.text));
        facts.append("lessonid", this.exercise.lessonid);
        facts.append("name", this.exercise.name);
        facts.append("video", this.exercise.video);
        facts.append("comment", this.exercise.comment);
        facts.append("photo", this.exercise.photo.url ? JSON.stringify(this.exercise.photo) : "");

        if(this.exercise.audio !== "") {
            facts.append("audio", this.exercise.audio);
        } else {
            facts.append("audio", "");
        }

        if(typeof(this.exercise.lessonid) === "undefined") {
            return message.error('Не выбран урок.');
        }

        let url
        if(this.exercise.id > 0) {
            url = "/practice/exercise/" + this.exercise.id;
        } else {
            url = "/practice/exercise"
        }
        axios.post(url, facts)
            .then(success => {
                if(this.exercise.id > 0) {
                    message.success('Успешно');
                } else {
                    document.location.href = "/practices/exercises";
                }
            })
            .catch(error => console.log(error.response.data.message));
    }

    getExercise(exerciseID) {
        axios.get("/practice/exercise/" + exerciseID)
            .then(response => {
                this.exercise = {
                    id: response.data.id,
                    audio: response.data.audio,
                    lessonid: response.data.lessonid,
                    photo: response.data.photo !== "" && response.data.photo !== null ? response.data.photo : {},
                    position: response.data.position,
                    text: Array.isArray(response.data.text) ? response.data.text : [],
                    text2: response.data.text2,
                    name: response.data.name,
                    video: response.data.video,
                    comment: response.data.comment,
                };
                searchStore.getByID("lesson", this.exercise.lessonid, "list");
            })
            .catch(response => console.log(response));
    }

    setPhoto(photo) {
        this.exercise.photo = photo;
    }

    setNow(page) {
        this.now = page;
        this.getAllExercise()
    }

    deleteImage() {
        uploadStore.deleteFile(this.exercise.photo.url)
        this.exercise.photo = {};
    }

    deleteVideo() {
        uploadStore.deleteFile(this.exercise.video)
        this.exercise.video = "";
    }

    setVideo(video) {
        this.exercise.video = video.url;
    }

    setAudio(audio) {
        this.exercise.audio = audio.url;
    }

    deleteMusic() {
        uploadStore.deleteFile(this.exercise.audio)
        this.exercise.audio = "";
    }
}

export default new ExerciseStore();