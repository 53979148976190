import { makeAutoObservable } from "mobx"
import store from 'store'

class langController {

    lang = store.get('lang') ? store.get('lang') : 'ru'

    constructor() {
        makeAutoObservable(this)
    }

    onChangeLang(lang) {
        if(lang === 'ru' || lang === 'en')
            store.set('lang', lang)

        window.location.href = ''
    }

}

export default new langController()